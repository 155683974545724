import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import { Link } from "react-router-dom";

interface LinkRouterProps extends MUILinkProps {
  to: string;
  replace?: boolean;
}

export const LinkRouter = (props: LinkRouterProps) => (
  <MUILink {...props} component={Link as any} sx={{pr: 1}} />
);
