import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import {
  LoadZendeskWidget,
  ZendeskRedirect,
  ZendeskWidget,
} from "./ZendeskWidget";

const MyMdHelpMenu = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "helpMenu" });

  return (
    <LoadZendeskWidget>
      <IconButton
        aria-label="help"
        aria-controls="menu-help"
        color="inherit"
        sx={{
          paddingLeft: 0.5,
          paddingRight: 0.5,
        }}
        {...bindTrigger(popupState)}
        size="large"
      >
        <HelpOutlineOutlinedIcon fontSize="large" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: grey[400],
            borderRadius: 0,
          },
        }}
      >
      <MenuItem >
      <Link
        href={"https://mymd.freshdesk.com/customer/login"}
        target="_blank"
        rel="noopener"
        color="inherit"
        sx={{
          textDecoration: "none",
          fontWeight: 400,
          color: "white",
        }}
        underline="none"
      >
        MyMd Help
      </Link>
    </MenuItem>

        <ZendeskRedirect
          onClick={popupState.close}
          returnTo="https://help.aeronetsoftware.com/hc/en-us/requests"
        >
          My Ticket
        </ZendeskRedirect>
        <ZendeskRedirect
          onClick={popupState.close}
          returnTo="https://help.aeronetsoftware.com/hc/en-us/requests/new"
        >
          Submit a Ticket
        </ZendeskRedirect>
        <ZendeskWidget onClick={popupState.close} />
      </Menu>
    </LoadZendeskWidget>
  );
};

export default MyMdHelpMenu;
