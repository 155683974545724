import { axios } from "../api";

// Singleton example from stackoverflow  https://stackoverflow.com/a/36978360/2167749
class User {
  private static _instance: User;
  // @ts-ignore
  private data: { [key: string]: any }; // store data in here

  private constructor() {
    // this.getConfig();  // cant do this as it runs on import before Axios is set up in api.ts
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async currentUser(): Promise<{ [key: string]: any }> {
    if (!this.data) {
      const resp = await axios.get("/v6/users/me", {
        id: "get-current-user",
        cache: { ttl: 1000 * 60 * 60 * 24 },
      });
      this.data = resp.data;
    }

    return this.data;
  }
}

const instance = User.Instance;
export default instance;
