import React, {  useEffect, useMemo, useState } from 'react'
import { axios } from '../api'
import { AxiosResponse } from 'axios'
import { DataGridPremium, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium'

import CustomFilterPanelPremium from '../components/CustomFilterPanelPremium'
import { format } from 'date-fns'

const PartSearchReport = () => {
  const [partSeached,setPartSearched] = useState<SearchedPart[]>([])
  const [loading,setLoading] = useState(true)
  interface SearchedPart {
   part_number:string | null;
   user_id:number | null;
   searched_datetime:Date | null
   part_id:number
  }

  useEffect(()=>{
    axios({
      method: 'GET',
      url:"/v6/parts/searched/report/",
      id:"get-parts-searched"
    }).then((resp:AxiosResponse<SearchedPart[]>)=>{
      setPartSearched(resp.data)
      setLoading(false)
      axios.storage.remove("get-parts-searched")
  
    })
  },[])

const columns: GridColDef[] = useMemo(
    ()=>[
        {
            headerName: "User Id",
            field: "user_id",
            flex: 0.5,
        },  
        {
          headerName:"Search Date",
          field:"searched_datetime",
          flex:0.5,
          type:"date",
          valueGetter:(params,row)=>{
            return row?.searched_datetime
          },
          valueFormatter:(params,row)=>{
            return format(new Date(params),"dd-MM-yyyy")
          }
        },
        {
            headerName: "Part Number",
            field: "part_number",
            flex: 0.5,
        },
        {
            headerName: "Company",
            field: "company_obj.name",
            flex: 0.5,
            valueGetter:(params,row)=>{
              return row?.company_obj?.name || ""
            }
        },
    ],[]
)

const customToolbar = (props)=>{
    return (
        <>
         <GridToolbarContainer >
      <GridToolbarExport {...props} />
      <GridToolbarFilterButton/>
    </GridToolbarContainer>
        </>
    )
}

  return (
    <>
  
      <DataGridPremium
        autoHeight
        checkboxSelection
        slots={{ toolbar: customToolbar , 
        filterPanel:CustomFilterPanelPremium}}
        slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
        columns={columns}
        rows={partSeached}
        sx={{ flex: 1, margin: "20px" }}
        disableRowSelectionOnClick
        hideFooter
        ignoreValueFormatterDuringExport
        loading= {loading}
            />
    </>
  )
}

export default PartSearchReport