import React, { useEffect, useState } from 'react'
import { AeronetViewProps } from '../types'
import { Route, Routes } from 'react-router-dom'
import { getOriginalUrl } from '../utils/originalUrl'
import DueDateList from './DueDateList'
import DueDateDetail from './DueDateDetail'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useConfig } from '../hooks/useConfig'

const DueDate: React.FC<React.PropsWithChildren<AeronetViewProps>> = (props)  => {
  const { originalUrl: domain, ...otherProps } = props;
  const originalUrl = getOriginalUrl(domain, "function=due_date_control");

  const currentUser = useCurrentUser();
  const whiteboardConfig = useConfig("whiteboard");
  const [hasDueDateCreatePermission, setHasDueDateCreatePermission] = useState<boolean>(false);
  const [hasDueDateEditPermission, setHasDueDateEditPermission] = useState<boolean>(false);
  const [hasDueDateDeletePermission, setHasDueDateDeletePermission] = useState<boolean>(false);
  const [hasDueDatePermission, setHasDueDatePermission] = useState<boolean>(false);

  useEffect(() => {
    if (whiteboardConfig && currentUser) {
      if (
        whiteboardConfig.create &&
        currentUser.permissions?.whiteboard &&
        currentUser.permissions.whiteboard.includes(whiteboardConfig.create)
      ) {
        setHasDueDateCreatePermission(true);
      } else {
        setHasDueDateCreatePermission(true);
      }
      if (
        whiteboardConfig.Edit &&
        currentUser.permissions?.whiteboard &&
        currentUser.permissions.whiteboard.includes(whiteboardConfig.Edit)
      ) {
        setHasDueDateEditPermission(true);
      } else {
        setHasDueDateEditPermission(false);
      }

      if (
        whiteboardConfig.delete &&
        currentUser.permissions?.whiteboard &&
        currentUser.permissions.whiteboard.includes(whiteboardConfig.delete)
      ) {
        setHasDueDateDeletePermission(true);
      } else {
        setHasDueDateDeletePermission(false);
      }
      if (
        whiteboardConfig.can_view_module_log &&
        currentUser.permissions?.whiteboard &&
        currentUser.permissions.whiteboard.includes(whiteboardConfig.can_view_module_log)
      ) {
        setHasDueDatePermission(true);
      } else {
        setHasDueDatePermission(false);
      }
    }
  }, [currentUser, whiteboardConfig]);

  return (
    <Routes>
    {
      hasDueDateCreatePermission ? <Route
      path='create'  element = {<DueDateDetail originalUrl={originalUrl} />}
      />:null
    }
      {
        hasDueDateEditPermission?<Route
        path=':id'  element = {<DueDateDetail originalUrl={originalUrl} />}
        />:null
      }
  {
    hasDueDatePermission?  
     <Route
    path= "*"
    element = {
     <DueDateList
     canCreateDuedate = {hasDueDateCreatePermission}
     canDeleteDuedate = {hasDueDateDeletePermission}
     originalUrl={originalUrl}
   {...otherProps}
     />

    }
    />:null
  }
    </Routes>
  )
}

export default DueDate