import { Auth } from "aws-amplify";
import { SignIn } from "aws-amplify-react";

class _CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() => Auth.federatedSignIn());
  }
}

const CustomSignIn = _CustomSignIn as unknown as React.FC<React.PropsWithChildren<unknown>>;

export default CustomSignIn;
