import { useCallback, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { BaseRow, Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { useCSVDownloader } from "react-papaparse";
import { useNavigate } from "react-router-dom";

import { axios } from "../../api";
import Content from "../../components/Content";
import { BreadcrumbHeader, Title } from "../../components/Header";
import { ImportErrors } from "../../imports/ImportErrors";

import type { ValidationError } from "../../imports/ImportErrors";
import { useCurrentUser } from "../../hooks/useCurrentUser";


const BasicImport: React.FC = () => {
  const navigate = useNavigate();

  const { CSVDownloader, Type } = useCSVDownloader();
  const [errors, setErrors] = useState<Array<ValidationError>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const importComponent = useCallback(
    async (rows: BaseRow[]) => {
      try {
        const listQuotes = rows.map((row) => ({
        status:7,
        quantity:1,
        part:{"number":row.part},
        description:row.description,
        sn:row.serial_no,
        batch_prefix:"",
        item_id:row.item_id,
        limitation:{
          based_on:row.based_on,
          limitation_notes:row.limitation_notes,
          limitation_type:row.limitation_type,
          life:row.life,
          total_life_used:row.total_life_used,
          units_remaining:row.units_remaining,
          life_at_install:row.life_at_install,
          aircraft_install:!['Date (Months)','Date (Days)','Date (Years)'].includes(String(row?.based_on) || "") ?row.aircraft_install:0,
          install_date:['Date (Months)','Date (Days)','Date (Years)'].includes(String(row?.based_on) || "")?row.aircraft_install:"",

        }
        }));
       
        const resp =await axios.post(`/v6/aircraft/component/importer/`,listQuotes)
        // console.log(resp)
 
          enqueueSnackbar(
            `Created ${resp.data.created}`,
            {
              variant: "success",
            }
          );
       
      } catch (error: any) {
        if (error.response?.data?.detail) {
          if (Array.isArray(error.response.data.detail)) {
            setErrors(error.response.data.detail);
          }
        }
        throw error;
      }
    },
    [enqueueSnackbar]
  );

  const csvDownloadData = useMemo(
    () => [
      {
        "Component Id": 0,
        "Section": "Airframe",
        "Part No": "500P2100-105",
        "Description": "M/R Blade Assy ",
        "Serial No.": "616F",
        "Based On": "Airframe Hours",
        "Period": 3530.00,
        "Installed": 3305.02,
        "Used": 2.1,
        "Notes": "Testing"
      },
      {
        "Component Id": 0,
        "Section": "Component",
        "Part No": "369D21210-501",
        "Description": "M/R Retention Straps",
        "Serial No.": "5145-AH13",
        "Based On": "Airframe Hours",
        "Period": 100.00,
        "Installed": 3305.02,
        "Used": 0.0,
        "Notes": "Testing"
      },
      {
        "Component Id": 0,
        "Section": "Airframe",
        "Part No": "369D25501-9",
        "Description": "COUPLING , TAIL ROTOR DRIVESHAFT",
        "Serial No.": "KAM2-865",
        "Based On": "Date (Months)",
        "Period": 3.00,
        "Installed": "28/02/23",
        "Used": 0.0,
        "Notes": "test date"
      }
    ]
    ,
    []
  );

  const csvDataHandler = (rows)=>{
    console.log(rows,"rows here")
    let newRow = rows;
    let localData = localStorage.getItem(`aircraft-component-basic-import-${currentUser?.id}`)
    let csvRowStored = localData && JSON.parse(localData);
    console.log(csvRowStored,"csv row in function")
    newRow = newRow.concat(csvRowStored)
    localStorage.setItem(`aircraft-component-basic-import-${currentUser?.id}`,JSON.stringify(newRow))
  }
  return (
    <>
      <BreadcrumbHeader aria-label="breadcrumb">
        <Title>
        Basic Import
        </Title>
      </BreadcrumbHeader>
      <Content data-testid="aircraft-component-import">
        <Box marginBottom={2}>
          <CSVDownloader
            type={Type.Button}
            filename={"aircraft-component-basic-v1-template"}
            bom={true}
            data={csvDownloadData}
          >
            Download Template
          </CSVDownloader>
        </Box>
        <Importer
          // @ts-ignore
          dataHandler={async (rows) => {
            csvDataHandler(rows)
          }}
          onStart={({ file, preview, fields, columnFields }) => {
            localStorage.setItem(`aircraft-component-basic-import-${currentUser?.id}`,JSON.stringify([]))
            setErrors([]);
            console.log("import starting");
          }}
          onComplete={(info) => {
            let localData = localStorage.getItem(`aircraft-component-basic-import-${currentUser?.id}`)
            let csvRowStored = localData && JSON.parse(localData);
            importComponent(csvRowStored)
            console.log(info);
            if (errors.length > 0) {
              enqueueSnackbar("Error importing component", {
                variant: "error",
              });
            }
          }}
          onClose={() => {
            setErrors([]);
            navigate(-1);
          }}
          chunkSize={100}
          // comments="#"
          defaultNoHeader={false}
          restartable={true}
          skipEmptyLines={true}
        >
          <ImporterField name="item_id" label="Component Id" />
          <ImporterField name="limitation_type" label="Section" />
          <ImporterField name="part" label="Part No" />
          <ImporterField name="description" label="Description" />
          <ImporterField name="serial_no" label="Serial No." />
          <ImporterField name="based_on" label="Based On" />
          <ImporterField name="life" label="Period" />
          <ImporterField name="aircraft_install" label="Installed" />
          <ImporterField name="life_at_install" label="Used" />
          <ImporterField name="limitation_notes" label="Notes" />
         
          
        </Importer>

        {errors.length > 0 ? (
          <ImportErrors errors={errors} onClose={() => setErrors([])} />
        ) : null}
      </Content>
    </>
  );
};

export default BasicImport;
