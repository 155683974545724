import { useCallback } from "react";
import { useGlobalConfig } from "./useGlobalConfig";

export function useGlobalRounding() {
  const globalConfig = useGlobalConfig();

  const globalRounding = useCallback(
    (value) => {
      const decimalPlaces = globalConfig ? globalConfig.quantity_rounding : 2;
      if (value != null) {
        return Number.parseFloat(value as unknown as string).toFixed(
          decimalPlaces
        );
      }
      return value;
    },
    [globalConfig]
  );

  return globalRounding;
}
