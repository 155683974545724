import { ValueOptions } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useQuery } from "../api";
import { getPartsGroups } from "../parts/groups";

export function usePartsGroupOptions() {
  const [groups] = useQuery(getPartsGroups);

  const groupOptions: ValueOptions[] | undefined = useMemo(() => {
    if (groups) {
      const g = [{ value: "", label: "Any" }];
      const go = groups.data.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      }));
      g.push(...go);
      return g;
    }
  }, [groups]);

  return groupOptions;
}
