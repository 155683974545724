import { useEffect, useRef, useState } from "react";

import { Box, Skeleton } from "@mui/material";
import { Storage } from "aws-amplify";
import { UploaderEvents, Vault as VaultBase } from "dhx-vault-package";
import "dhx-vault-package/codebase/vault.css";
import { useSnackbar } from "notistack";


import { AttachmentService } from "../attachments/AttachmentService";
import { DocumentAccess } from "../documents/types";

export function downloadBlob(blob, filename) {
  // from https://docs.amplify.aws/lib/storage/download/q/platform/js/#file-download-option
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

function Vault(props: any) {
  const vaultRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.access) {
      switch (props.access) {
        case DocumentAccess.Public:
          Storage.configure({
            customPrefix: { public: "storage/external/" },
          });
          break;
        case DocumentAccess.Protected:
          Storage.configure({ level: "protected" });
          break;
        case DocumentAccess.Private:
          Storage.configure({ level: "private" });
          break;
      }
    }

    // @ts-ignore
    const vault = new VaultBase(vaultRef.current, {
      data: props.data,
      mode: props.mode,
      uploader: {
        autosend: props.autosend,
        target: props.target,
      },
      toolbar: props.toolbar,
    });

    vault.events.on(UploaderEvents.beforeUploadFile, function (file) {
      console.log(file.id);

      let key = `${props.path}${file.name!.replace(/[^\w\d_\-.]+/gi, "")}`;

      const storageConfig = {
        contentDisposition: `attachment; filename="${file.name}"`,
        metadata: { name: file.name! },
      };

      Storage.put(key, file.file, storageConfig).then((resp) => {
        console.log(resp);
        vault.events.fire(UploaderEvents.uploadFile, [file, resp]);
      });

      vault.data.update(file.id!, { status: "inprogress" });
      return false;
    });

    vault.events.on(UploaderEvents.uploadFile, async function (file, extra) {
      console.log("The file is uploaded");
      const data = {
        title: file.name!,
        filename: file.name!,
        source_id: props.id,
        source_type: props.source,
        access: props.access || DocumentAccess.Internal,
      };

      const attachment = await AttachmentService.create(data);
      vault.data.update(file.id!, {
        key: extra!.key,
        status: "uploaded",
        attachmentId: attachment.id,
      });
    });

    vault.events.on("beforeRemove", async function (file) {
      try {
        await Storage.remove(file.key!);
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    });

    vault.events.on("afterRemove", async function (file) {
      if (file.attachmentId) {
        AttachmentService.delete(file.attachmentId);
      } else {
        const attachments = await AttachmentService.queryResource(
          props.source,
          props.id,
          [JSON.stringify({ field: "filename", op: "==", value: file.name })]
        );
        if (attachments.length > 1) {
          console.error("More than one attachment found for file: ", file.name);
        } else if (attachments.length === 1) {
          AttachmentService.delete(attachments[0].id);
        }
      }
    });

    vault.events.on("removeAll", async function () {
      const objs = await Storage.list(props.path);
      await Promise.allSettled(objs.map((obj) => Storage.remove(obj.key!)));

      await AttachmentService.deleteAll(props.source, props.id);
    });

    Storage.list(props.path)
      .then((results) => {
        console.debug(results);
        // filters out sub folders
        const docs = results.filter((result) => {
          // ignore the folder object
          if (result.size === 0) {
            return false;
          }

          // compare the object prefix to the path prop
          if (result.key) {
            const keyPrefix =
              result.key.split("/").slice(0, -1).join("/") + "/";
            if (keyPrefix === props.path) {
              return true;
            }
          }

          // anything that doesn't exactly match the path will be filtered out
          return false;
        });

        console.debug(docs);

        const dataset = docs.map((obj) => {
          return {
            id: obj.key,
            name: new URL(obj.key!, "https://example.com").pathname
              .split("/")
              .pop(),
            size: obj.size,
            key: obj.key,
          };
        });

        console.debug(dataset);

        setLoading(false);
        // @ts-ignore
        vault.data.parse(dataset);
      })
      .catch((error) => {
        console.error("Error loading documents: ", error);
        enqueueSnackbar("Error loading documents", {
          variant: "error",
        });
      });

    vault.events.on("click", async function (id, e) {
      console.debug(id);
      console.debug(e);
      var file = vault.data.getItem(id);
      const result = await Storage.get(file.key, { download: true });
      console.debug(result);
      downloadBlob(result.Body, file.name);
    });

    
    return () => vault.destructor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <>
      {loading && (
        <Box>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ marginBottom: 0.5 }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Box>
      )}
      <Box
        ref={vaultRef}
        className={`widget-box`}
        sx={{
          display: loading ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '92%',
          height: '450px',
          margin: 'auto',
          backgroundColor:"white",
          paddingTop:"60px",
          '& .dhx_toolbar-button__icon': {
            '&:before': {
              fontSize: 30,
              lineHeight: 30,
            }
          },
          '& .dhx-dropable-area':{
            backgroundColor:"white"
          },
          '& .dhx_vault--toolbar .dhx_toolbar':{
            backgroundColor:"white"
          },
          '& .dhx_widget .dhx_toolbar-button__icon:before ':{
            fontSize:"30px"
          }
        }}
      >
      </Box>
    </>
  );
}

export default Vault;
