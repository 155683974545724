import { useEffect, useMemo, useRef, useState } from "react";

import { MenuItem } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { Field, Form, Formik, FormikValues } from "formik";
import { Select, TextField } from "formik-mui";
import { useSnackbar } from "notistack";
// import queryString from "query-string";
import * as Yup from "yup";

import { axios } from "../../api";
// import { BatchAutocomplete } from "../../batch/FormikBatchAutocomplete";
import { ActionButton, CancelButton } from "../../components/Buttons";
// import CustomSnackbarContent from "../../components/CustomSnackbarContent";
import LoadingSpinner from "../../components/Loading";
import Sheet, {
  SheetActions,
  SheetContent,
  SheetHeader,
} from "../../components/Sheet";
// import { getCurrencies } from "../../currency/currency";
import { useConfig } from "../../hooks/useConfig";
// import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import { useLocationOptions } from "../../hooks/useLocationOptions";
import { usePartsConditionOptions } from "../../hooks/usePartsConditionOptions";
import { usePartsStoreOptions } from "../../hooks/usePartsStoreOptions";
import { PartAutocomplete } from "../../parts/PartAutocomplete";
import { handleAPIErrors } from "../../utils/FormikErrors";
import PartActionHeader from "../../parts/PartActionHeader";
// import { PriceAutocomplete } from "../../parts/PriceAutocomplete";

import type {
  BasePart,
  IPartDetail,
  IPartList,
  ItemDetail,
  PriceOption,
} from "../../parts/types";
import { useExchangeRates } from "../../hooks/useExchangeRates";

export interface CreateStockProps {
  open: boolean;
  onClose: () => void;
}

const isFullPart = (part: BasePart): part is IPartList => {
  // type guard that checks if we have a full definition of a part or just a summary
  return (
    (part as IPartList).list_cost !== undefined &&
    (part as IPartList).list_cost_currency !== undefined &&
    (part as IPartList).list_price !== undefined &&
    (part as IPartList).list_price_currency !== undefined
  );
};

const CreateComponent: React.FC<CreateStockProps> = (props) => {
  const { onClose, open } = props;
  const [currentPart, setCurrentPart] = useState<IPartDetail | IPartList>();
  const apiRef = useGridApiContext();

  const formRef = useRef<FormikValues>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const controller = new AbortController();

    const part = apiRef.current.getSelectedRows().values().next().value;

    if (part) {
      if (isFullPart(part)) {
        setCurrentPart(part);
      } else {
        axios
          .get<IPartDetail>(`/v6/parts/${part.id}`, {
            id: `get-part-${part.id}`,
            signal: controller.signal,
          })
          .then((resp) => {
            setCurrentPart(resp.data);
          })
          .catch((error) => {
            enqueueSnackbar(`Error fetching part ${part.id}`, {
              variant: "error",
            });
          });
      }
    }

    return () => {
      controller.abort();
    };
  }, [apiRef, enqueueSnackbar]);

  const handleCreateStock = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Sheet open={open} onClose={onClose}>
      <SheetHeader onClose={onClose} title="Create Component">
        <PartActionHeader part={currentPart} />
      </SheetHeader>
      <SheetContent>
        <CreateComponentForm
          part={currentPart}
          formRef={formRef}
          onClose={onClose}
        />
      </SheetContent>
      <SheetActions>
        <CancelButton
          onClick={() => {
            if (formRef.current) {
              formRef.current.resetForm();
            }
            onClose();
          }}
        />
        <ActionButton children={"Submit"} onClick={handleCreateStock} />
      </SheetActions>
    </Sheet>
  );
};
interface CreateComponentFormProps {
  part?: IPartList | IPartDetail;
  formRef: any;
  onClose: () => void;
}

export const CreateComponentForm = (props: CreateComponentFormProps) => {
  const { formRef, part, onClose } = props;
  // const [currency] = useQuery(getCurrencies);
  const { enqueueSnackbar } = useSnackbar();
  // const globalConfig = useGlobalConfig();
  // const invoiceBatchConfig = useConfig("invoice_batch");
  const locationOptions = useLocationOptions();
  const partsConfig = useConfig("parts");
  const conditionOptions = usePartsConditionOptions(partsConfig);
  const storeOptions = usePartsStoreOptions(partsConfig);
  const exchangeRates = useExchangeRates();

  // @ts-ignore
  interface ItemData extends Partial<ItemDetail> {
    sell: PriceOption | "";
    buy: PriceOption | "";
  }

  // @ts-ignore
  const initialStock: Partial<ItemData> = useMemo(() => {
    const data = {
      part: part || "",
      // @ts-ignore
      batch_prefix: "",
      // @ts-ignore
      batch: "",
      // supplier_release: "",
      condition: "NE",
      location: "",
      store: "",
      serial_number: "",
      quantity: "",
      // buy: part
      //   ? {
      //       name: `List Cost ${Number(part?.list_cost).toFixed(
      //         globalConfig?.cost_rounding ?? 2
      //       )} ${part?.list_cost_currency ?? ""}`,
      //       value: part?.list_cost.toString() || "",
      //     }
      //   : "",
      // currency_code:
      //   part?.list_cost_currency || partsConfig?.default_currency || "",
      // exchange:
      //   (part && exchangeRates && exchangeRates[part?.list_cost_currency]) ||
      //   (partsConfig &&
      //     exchangeRates &&
      //     exchangeRates[partsConfig?.default_currency]) ||
      //   "",
      // sell: part
      //   ? {
      //       name: `List Price ${Number(part?.list_price).toFixed(
      //         globalConfig?.value_rounding ?? 2
      //       )} ${part?.list_price_currency ?? ""}`,
      //       value: part?.list_price.toString() || "",
      //     }
      //   : "",
      description: "",
    };
    console.dir(data);
    return data;
  }, [part]);

  const CreateStockSchema = useMemo(() => {
    let schema = Yup.object().shape(
      {
        part: Yup.object().required("Required"),
        // supplier_release: Yup.string(),
        condition: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        store: Yup.string().required("Required"),
        // quantity: Yup.number().positive().required("Required"),
        // buy: Yup.object().required("Required"),
        // currency_code: Yup.string().required("Required"),
        // exchange: Yup.number().positive().required("Required"),
        // sell: Yup.object().required("Required"),
        description: Yup.string(),
        // batch_prefix: Yup.string().when("batch", {
        //   is: (batch) => !batch || batch.length === 0,
        //   then: (schema) => schema.required("Batch or batch prefix required"),
        // }),
        // batch: Yup.object().when("batch_prefix", {
        //   is: "",
        //   then: (schema) => schema.required("Batch or batch prefix required"),
        //   otherwise: (schema) => schema.nullable(),
        // }),
      },
      [
        ["batch", "batch_prefix"],
        ["batch_prefix", "batch"],
      ]
    );

    if (part?.serial_number_required) {
      schema = schema.shape({
        serial_number: Yup.string().required("Required"),
      });
    }

    return schema;
  }, [part]);

  return (
    <>
      {exchangeRates ? (
        <Formik
          enableReinitialize={true}
          initialValues={initialStock}
          innerRef={formRef}
          validationSchema={CreateStockSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            //  split out fields and reorganize as order and lines
            const { batch, buy, sell, ...data } = values;
            data['quantity'] = 1
            data['status'] = 16
            // data["batch_id"] = batch!.id ?? null;
            // // @ts-ignore
            // data["buy"] = buy!.value;
            // // @ts-ignore
            // data["sell"] = sell!.value;

            axios({
              method: "post",
              url: "/v6/items",
              data,
            })
              .then((resp) => {
                setSubmitting(false);
                // const url =
                //   "/inventory/stock?" +
                //   queryString.stringify({
                //     filter: JSON.stringify({
                //       field: "id",
                //       operator: "startsWith",
                //       value: resp.data.id,
                //     }),
                //   });
                enqueueSnackbar(
                  // <CustomSnackbarContent
                  //   message={`Item #${resp.data.id} created`}
                  //   // uri={url}
                  // />,
                  `Item #${resp.data.id} created`,
                  {
                    variant: "success",
                  }
                );
                onClose();
              })
              .catch((error) => {
                values.batch = batch; // Put the batch back so the form will render correctly
                handleAPIErrors(error, setErrors);
                setSubmitting(false);
                enqueueSnackbar(`Error creating stock`, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                });
              });
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleBlur }) => (
            <Form>
              {part ? (
                <></>
              ) : (
                <Field
                  required
                  id="part"
                  name="part"
                  component={PartAutocomplete}
                />
              )}
              {/* <Field
                fullWidth
                component={TextField}
                name="supplier_release"
                label="Release Number"
              /> */}
              <Field
                required
                fullWidth
                component={Select}
                name="condition"
                label="Condition"
              >
                {conditionOptions?.map((option) => (
                  // @ts-ignore
                  <MenuItem key={option.value} value={option.value}>
                    {/* @ts-ignore */}
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                required
                fullWidth
                component={Select}
                name="location"
                label="Location"
              >
                {locationOptions?.map((option) => (
                  // @ts-ignore
                  <MenuItem key={option.value} value={option.value}>
                    {/* @ts-ignore */}
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                required
                fullWidth
                component={Select}
                name="store"
                label="Store"
              >
                {storeOptions?.map((option) => (
                  // @ts-ignore
                  <MenuItem key={option.value} value={option.value}>
                    {/* @ts-ignore */}
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                required={part?.serial_number_required ?? false}
                fullWidth
                component={TextField}
                name="serial_number"
                label="Serial Number"
                // disabled={!part?.serial_number_required}
              />
            
              <Field
                fullWidth
                component={TextField}
                name="description"
                label="Description"
                type="text"
                multiline
                rows={3}
              />
             
              {isSubmitting && <LoadingSpinner />}
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export default CreateComponent;
