import React, {  MutableRefObject, useCallback, useMemo } from 'react'
import { axios } from '../api'
import { AxiosResponse } from 'axios'
import { QuoteLineList } from '../quotes/types'
import { useConfig } from '../hooks/useConfig'
import { GridApi, GridColDef, GridFilterModel } from '@mui/x-data-grid-pro'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import { AeronetV6ItemsResponse } from '../types'
import queryString from 'query-string'
import AeronetDataGrid, { QueryResults } from '../components/datagrid/DataGrid'
import { useSnackbar } from 'notistack'
import { SystemResponseToolbar } from './SystemResponseReportToolbar'


const SystemResponseReport = () => {

  const quotesConfig = useConfig("quotes");
  const {enqueueSnackbar} = useSnackbar();


const columns: GridColDef[] = useMemo(
    ()=>[
        {
            headerName: "Id",
            field: "id",
            flex: 0.5,
          }, 
          {
            headerName: "Quote Id",
            field: "quote.id",
            flex: 0.5,
            valueGetter:(params)=>{
              return params.row?.quote?.id
            },
          },
          {
            headerName: "Part Number",
            field: "part.number",
            flex: 0.5,
            valueGetter:(params)=>{
              return params.row?.part?.number
            },
          },
          {
            headerName: "Approved",
            field:"approved",
            flex:0.5,
            editable: true,
            valueFormatter:(row)=>{
                
                return quotesConfig?.quotes_checked_list[row?.value?.approved || 0]
            }
        }
          
    ],[quotesConfig]
)


const getData = useCallback(
  async (params: any, axiosConfig?: CacheRequestConfig) => {
    try {
      const resp: AxiosResponse<AeronetV6ItemsResponse<QuoteLineList>> =
        await axios.get("/v6/quotes/lines", {
          params: params,
          paramsSerializer: (params) => queryString.stringify(params),
          ...axiosConfig,
          id:'get-quoteslines-list'
        });
        axios.storage.remove('get-quoteslines-list')
       
      return {
        total: resp.data.total,
        rows: resp.data.items,
      } as QueryResults<QuoteLineList>;
    } catch (e) {
      // @ts-ignore
      if (!axios.isCancel(e)) {
        enqueueSnackbar("Error fetching quotes", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
      throw e;
    }
  },
  [enqueueSnackbar]
);


const quickSearchItems = useMemo(
  () => [

    {
      field: "id",
      operator: "startsWith",
      label: "QuoteLine ID",
    },
    {
      field:"quote.id",
      operator: "startsWith",
      label:"Quote Id"
    },
    {
      field:"part.number",
      operator: "startsWith",
      label:"Part Number"
    }
  ],
  []
);


const advancedSearchDefaultItems = useMemo(
  () => [
    {
      id: 1,
      field: "id",
      operator: "is",
      value: "",
    },{
      id: 2,
      field: "quote.id",
      operator: "is",
      value: "",
    },{
      id: 3,
      field: "part.number",
      operator: "is",
      value: "",
    },{
      id: 4,
      field: "approved",
      operator: "is",
      value: "",
    },
    
  ],
  []
);


const onSearch = useCallback(
  (filterModel: GridFilterModel, apiRef: MutableRefObject<GridApi>) => {
    console.log("FilterModel....", filterModel);
    apiRef.current.setFilterModel(filterModel, "upsertFilterItems");
  },
  []
);

  return (
    <>
    <AeronetDataGrid
    advancedSearchProps={{
      items: advancedSearchDefaultItems,
      onSearch: onSearch,
    }}
    columns={columns}
    data={getData}
    name='system-response-datagrid'
    currentUserId={1}
    quickSearchItems={quickSearchItems}
    // disableColumnFilter= {false}
    // enableFilter={true}
    enableCreate={false}
    enableAdvancedSearch= {false}
    components={{
      toolbar:SystemResponseToolbar
    }}
    />
  
    </>
  )
}

export default SystemResponseReport