import React, { useEffect, useRef, useState, useTransition } from 'react'
import Header from '../components/Header';
import { SaveFab } from '../components/Buttons';
import { TabPanel } from '../components/Tabs';
import { AppBar, Box, Button, Grid, GridSpacing, MenuItem, Snackbar, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, FormikValues } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import { axios } from '../api';
import { useSnackbar } from 'notistack';
import { LoadingView } from '../components/Loading';
import CustomSnackbarContent from '../components/CustomSnackbarContent';
import { handleAPIErrors } from '../utils/FormikErrors';
import * as Yup from "yup";
import "../utils/global.css"
import AlertDialog from '../components/AlertDialog';
import { CompanyAutocomplete } from '../companies/FormikCompanyAutocomplete';
import Vault from '../components/Vault';
import { AxiosResponse, Method } from 'axios';
import { DueDateDetailView } from './type';
import { useConfig } from '../hooks/useConfig';
import { LoadingComponent } from '../stories/Loaders/ViewLoading.stories';
import { UserAutocomplete } from '../users/user-management/UserAutocomplete';
import { TypeAutocomplete } from '../types/TypeAutoComplete';
import { formtListObject } from './helpers';
import { DueDateAutocomplete } from './DueDateAutocomplete';
import { useCurrentUser } from '../hooks/useCurrentUser';
import LinkedRecords from './LinkedRecords';
import { grey } from '@mui/material/colors';
// import { AdvancedSearchDueDateAutoComplete } from './DueDateAutocomplete';

interface DueDateDetailProps {
    originalUrl: any
}


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const DueDateDetail: React.FC<DueDateDetailProps> = (props) => {
    const { originalUrl } = props
    let { id } = useParams();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [title, setTitle] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const formRef = useRef<FormikValues>();
    const [dueDate,setDueDate] = useState<DueDateDetailView>();
    let isRendered = useRef(false);
    const [tabDialogOpen, setTabDialogOpen] = useState(false);
    const [, startTransition] = useTransition();
    const currentUser = useCurrentUser();

    useEffect(() => {
        isRendered.current = true;
        if (id) {
          axios
            .get(`/v6/duedate/${id}`, { id: `get-duedate-${id}` })
            .then((resp: AxiosResponse<DueDateDetailView>) => {
              if (isRendered.current) {
                const duedate = {};
                for (const value in resp.data) {
                  if(value === 'parent_id'){
                    duedate['parent_obj'] = resp?.data[value]?{id:resp?.data[value] }:null
                  }
                  duedate[value] = resp.data[value] === null ? "" : resp.data[value];
                }
               
                setDueDate(duedate as DueDateDetailView);
                if((resp.data?.parent_id || 0) >0){
                  setTitle(`Due Date Control / ${resp.data?.id} ${currentUser?.name} / ${resp.data?.parent_id} Child record`);
                }
                else {
                  setTitle(`Due Date Control / ${resp.data?.id} `);
                }
              }
              axios.storage.remove(`get-duedate-${id}`)
              return null;
            })
            .catch((error) => {
              enqueueSnackbar(`Error fetching DueDate`, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
              console.error(error);
            });
        }
        else{
            const new_duedate:DueDateDetailView = {
              
                title: "",
                period: 0,
                assigned_to: [],
                status: 1,
                created_by: 0,
                comment: "",
                endorsments:false,
                last_done:"",
                active:true,
                parent_obj:null
              }
            startTransition(()=>{
                setDueDate(new_duedate)
                setTitle("New DueDate")
            })
        }
    },[id,enqueueSnackbar,currentUser])




    const submitForm = () => {
      if (formRef.current) {
        formRef.current.submitForm().then(() => {
          if (
            formRef.current &&
            !formRef.current.isValidating &&
            formRef.current.isValid
          ) {
            navigate("..");
          }
        });
      }
    else{
        navigate(-1)
    }
    };
    
    const saveForm = () => {
      if (formRef.current) {
        formRef.current.submitForm()
      }

    };
    
    const handleClose = () => {
        if (formRef.current) {
            formRef.current.submitForm().then(() => {
                if (
                  formRef.current &&
                  !formRef.current.isValidating &&
                  formRef.current.isValid
                ) {

                  setTabValue(1);
                }
              });
        }
      };
    
    const handleTabChange = (
        _event: React.SyntheticEvent<{}> | null,
        newValue: unknown) => {
            // setTabValue(newValue as number)
          
            if (
                formRef.current &&
                ((formRef.current.dirty && !formRef.current.isSubmitting) ||
                  !formRef.current.initialValues.id)
              ) {
                
                setTabDialogOpen(true);
               
              }
              else{
                setTabValue(newValue as number);
              }
              return;
    }
    return (
        <>
            <Header actions={[<SaveFab onClick2={saveForm} onClick={submitForm} key={1} />]}>
                {title}
            </Header>
            <AppBar
            position="static"
            sx={{
              paddingLeft: { xs: 2, sm: 6 },
              paddingRight: { xs: 2, sm: 6 },
              paddingTop: 3,
              backgroundColor: grey[100],
            }}
            elevation={0}
            data-testid="tab-bar"
            >
              <Tabs
                value={tabValue} 
                onChange={handleTabChange}
                aria-label="tabs"
                indicatorColor="primary"
                textColor="primary"
                sx={{ "& .MuiTabs-indicator": { height: 5 } }}
              >
                <Tab disableRipple label="General" {...a11yProps(0)} />
                {  (dueDate?.parent_id || 0) ===  0 && <Tab disableRipple label="Linked Records" {...a11yProps(1)} />}
                <Tab disableRipple label="Document" {...a11yProps((dueDate?.parent_id || 0) ===  0 ?2:1)} />
              </Tabs>
            </AppBar>

            <Box
              textAlign="left"
              sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                pl: { xs: 2, sm: 6 },
                pr: { xs: 2, sm: 6 },
                pb: { xs: 2, sm: 6 },
              }}
              data-testid="flightplan-form"
              >


                <TabPanel value={tabValue} index={0} data-testid="duedate-form">
                    {
                        dueDate ?<General formRef={formRef} originalUrl={originalUrl} dueDate={dueDate} setDueDate = {setDueDate} />:<LoadingView/>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={1} data-testid="duedate-linked-records">
                    {
                        (dueDate?.parent_id || 0) ===  0 && (dueDate  ?<LinkedRecords duedate={dueDate} />:<LoadingView/>)
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={(dueDate?.parent_id || 0) ===  0 ? 2:1} data-testid="duedate-doc">
                    {
                        dueDate ?
                        <Box sx={{
                          backgroundColor:"white",
                          height:"510px"
                        }}>
                        <Vault 
                        toolbar
                        autosend
                        path={`duedate/${dueDate}/`}
                        source={'sor'}
                        id={dueDate}/></Box>
                        :<LoadingView/>
                    }
                </TabPanel>

            </Box>
            <AlertDialog
            title="Are you sure ?"
            description="Due Date is not saved"
            open={tabDialogOpen}
            handleClose={() => {
              setTabDialogOpen(false);
            }}
          >
            <Button
              onClick={() => {
                setTabDialogOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (formRef.current) {
                  formRef.current.resetForm();
                  setTabDialogOpen(false);
                }
              }}
              color="primary"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                setTabDialogOpen(false);
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </AlertDialog>
        </>
    )
}


export interface GeneralTabProps {
    originalUrl: string;
    formRef?: any;
    dueDate:DueDateDetailView;
    setDueDate:any;
}

const General = (props: GeneralTabProps) => {
    const { formRef ,dueDate , setDueDate} = props
    const dueDateConfig = useConfig("whiteboard")
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    let gridSpacing: GridSpacing = isSmall ? 2 : 6;

    const dueDateSchema = Yup.object().shape({
        title: Yup.string().required("Required"),
      });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={dueDate}
                innerRef={formRef}
                validationSchema={dueDateSchema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                  console.log(values,"valuesss")
                  const data = {...values}
                    let method: Method = "post";
                    let url:string = `/v6/duedate`;
                    let msg: string = "created";

                    if (Object.prototype.hasOwnProperty.call(dueDate, "id") && dueDate.id) {
                        method = "put";
                        url = `/v6/duedate/${dueDate.id}`;
                        msg = "updated";
                    }

                    const dueDateData = Object.entries(values).reduce((acc, [key, value]) => {
                      if (key === "user_obj"){
                        console.log("here",data['user_obj'])
                        acc["created_by"] = data['user_obj']?.id || 0
                      }
                      else if (key === "section")
                      {
                        acc['section_id'] = data['section']?.id || 0
                      }
                      else if (key === "department_obj"){
                        acc['department'] = data['department_obj']?.id || 0
                      }
                      else if (key === 'type_obj'){
                        acc['type_id'] = data['type_obj']?.id || 0
                      }
                      else if (key === 'supervisor_obj'){
                        acc['supervisor'] = data['supervisor_obj']?.id || 0
                      } 
                      else if (key === 'assigned_obj'){
                        acc['assigned_to'] = data['assigned_obj']?.id || 0
                      }
                     else  if (key === 'assigned_to'){
                        acc['assigned_to'] = formtListObject(value)
                      }
                      else if(key === 'parent_obj'){

                        acc['parent_id'] = data['parent_obj']?.id || 0
                      }
                      else{
                        acc[key] = value
                      }
                      return acc;
                    }, {} as DueDateDetailView);

                    axios({
                        url: url,
                        method: method,
                        data: dueDateData
                    }).then((resp: AxiosResponse<DueDateDetailView>)=>{
                        axios.storage.remove(`get-duedate-${dueDate.id}`)
                        resp.data.assigned_to = []
                        resp.data['parent_obj'] = {id:resp.data?.parent_id || 0}
                        setDueDate(resp.data)
                        let uri = location.pathname;
                        uri = uri.replace("create",`${resp.data.id}`)
                        enqueueSnackbar(
                            <CustomSnackbarContent
                              message={`Successfully ${msg} DueDate`}
                              uri={uri}
                            />,
                            {
                              variant: "success",
                            }
                          );
                        resetForm();
                        // navigate(-1)
                    }).catch((error)=>{
                        console.debug(error,"error")
                        handleAPIErrors(error, setErrors);
                        setSubmitting(false);
                        enqueueSnackbar(`Error saving DueDate ${error['response']['data']['detail'][0]['loc'][1]}`, {
                            variant: "error",
                        });
                    })
                }}
            >
                {({ isSubmitting, setFieldValue, values, setValues }) => (
                    <Form
                    className='mui-form'
                    >
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Title"
                                        name="title"
                                       
                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={DatePicker}
                                        label="Last Done"
                                        name="last_done"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Period"
                                        name="period"

                                    />
                                     <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={DatePicker}
                                        label="Due Date"
                                        name="due_date"

                                    />
                                    <br />
                                    <Field
                                    component={CheckboxWithLabel}
                                    name="endorsements"
                                    type="checkbox"
                                    color="primary"
                                    Label={{
                                    label: "Endorsements",
                                    sx: { px: 1, pb: 0.5 },
                                    }}
                                  
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} lg={4}>
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={DatePicker}
                                        label="Schedule Date"
                                        name="schedule_date"

                                    />                             
                                    { dueDateConfig?.sections_id ? 
                                      <Field
                                        required
                                        name="section"
                                        label="Section"
                                        component={TypeAutocomplete}
                                        fullWidth
                                        parent_id = {dueDateConfig?.sections_id}
                                      /> : <Snackbar/> }
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={CompanyAutocomplete}
                                        label="Company"
                                        name="company_obj"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={UserAutocomplete}
                                        label="Created By"
                                        name="user_obj"

                                    />
                                    { dueDateConfig?.department_parent_id ? 
                                      <Field
                                        required
                                        label="Department"
                                        name="department_obj"
                                        component={TypeAutocomplete}
                                        fullWidth
                                        parent_id = {dueDateConfig?.department_parent_id}
                                      /> : <Snackbar/> }
                                </Grid>

                                <Grid item xs={12} md={4} lg={4}>
                                    {
                                     dueDateConfig?.assign_multi_user !=="yes" ?<Field
                                      autoFocus
                                      required
                                      fullWidth
                                      component={UserAutocomplete}
                                      label="Assigned To"
                                      name="assigned_obj"
                                      
                                  /> : <Field
                                  component={UserAutocomplete}
                                  label = "Assigned To"
                                  name="assigned_to"
                                  multiple

                                />
                                    }
                                      <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={UserAutocomplete}
                                        label="Supervisor"
                                        name="supervisor_obj"

                                    />
                                  {
                                    dueDateConfig?<Field
                                    required
                                    fullWidth
                                    component={Select}
                                    label="Status"
                                    name="status"
                                 
                                >
                                        {
                                        Object.entries(dueDateConfig?.status).map((entry:[string,unknown]) => {
                                            return (<MenuItem key={entry[0]} value={entry[0]}>{dueDateConfig?.status[entry[0]]}</MenuItem>)
                                        })
                                        }
                        </Field>:<LoadingComponent/>
                                  }
                                    { dueDateConfig?.type ? 
                                      <Field
                                        required
                                        label="Type"
                                        name="type_obj"
                                        component={TypeAutocomplete}
                                        fullWidth
                                        parent_id = {dueDateConfig?.type}
                                      /> : <Snackbar/> }
                                    <Field
                                    autoFocus
                                    required
                                    fullWidth
                                    component={DueDateAutocomplete}
                                    label="DueDate"
                                    name="parent_obj"
                                    duedate_id = {dueDate?.id || 0}
                                    />
                                </Grid>

                            </Grid>
                           <Grid item  xs={12} md={12} lg = {12} ></Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg = {12} >
                                    <Field
                                    autoFocus
                                    required
                                    fullWidth
                                    multiline
                                    rows="5"
                                    component={TextField}
                                    label="Comment"
                                    name="comment"
                                    />
                                </Grid>
                            </Grid>

                    </Form>
                )
                }

            </Formik>
        </>
    )
}

export default DueDateDetail
