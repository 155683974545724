import { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Box,
  
  Divider,
  
  IconButton,
  ListSubheader,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import {
  
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  
  GridToolbarContainer,
  
  GridToolbarFilterButton,
  gridFilterModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import {  CreateFab, SearchButton, SearchFab } from "../components/Buttons";
import { ActionMenu } from "../components/datagrid/ActionMenu";
import {
  AdvancedSearchToolbarProps,
  // GridToolbarExport,
} from "../components/datagrid/AdvancedSearchToolbar";
import { Actions } from "../components/datagrid/DataGrid";
import { DisplayPreferences } from "../components/datagrid/DisplayPreferences";
import { SearchField } from "../components/datagrid/SearchField";
import { GridToolbarExportContainer } from "../components/datagrid/GridToolbarExport";


interface DueDateToolbarProps extends AdvancedSearchToolbarProps {
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

const GridToolbarExport = ({
  csvOptions,
  printOptions,
  setAction,
  
  ...other
}) => {
  return <>
   <GridToolbarExportContainer {...other}>
      
      <ListSubheader style={{color:"black" ,fontSize:"1rem",backgroundColor:grey[100]}}><b>Export</b></ListSubheader>
      <GridCsvExportMenuItem  options={csvOptions} />
    <GridPrintExportMenuItem options={printOptions} />
    <Divider />
    <ListSubheader style={{color:"black" ,fontSize:"1rem",backgroundColor:grey[100]}} ><b>Settings</b></ListSubheader>
      <MenuItem
    onClick={() => {
      setAction(Actions.Settings);
    }}
    >
        Display
      </MenuItem>

    </GridToolbarExportContainer>
  </>
};



export default function DueDateToolbar(props: DueDateToolbarProps) {
  const {
    action,
    actions,
    clearSearch,
    csvOptions,
    defaultItems,
    enableAdvancedSearch,
    enableCreate,
    enableFilter,
    exportState,
    onChange,
    onDelete,
    printOptions,
    quickSearchItems,
    selectionModel,
    setAction,
    setSelected,
    setSelectionModel,
    setFilterButtonEl,
    value,
    ...other
  } = props;
  let navigate = useNavigate();
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const filterModel = gridFilterModelSelector(apiRef);
  const [filtersActive, setFiltersActive] = useState(false);
  const [filterClicked,setFilterClicked] = useState(false);

  useEffect(() => {
    if (JSON.stringify(defaultItems) === JSON.stringify(filterModel.items)) {
      setFiltersActive(false);
    } else {
      setFiltersActive(true);
    }
  }, [defaultItems, filterModel]);

  return (
    <>
      <GridToolbarContainer
        sx={{
          padding: (theme) => ({
            xs: theme.spacing(0.5, 1, 1),
            md: enableCreate
              ? theme.spacing(1.5, 6, 2, 6)
              : theme.spacing(1.5, 6, 2),
          }),
        }}
        {...other}
      >
        <>
          {selectionModel && selectionModel.length > 0 && onDelete ? (
            <IconButton
              aria-label="delete"
              onClick={() => {
                onDelete(selectionModel);
                setSelectionModel([]);
                for (const rowId of selectionModel) {
                  apiRef.current.updateRows([{ id: rowId, _action: "delete" }]);
                }
              }}
              sx={{
                position: "absolute",
                top: theme.spacing(2.5),
                left: theme.spacing(6.5),
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <>
              {enableCreate && (
               <CreateFab
               onClick={() => {
                   navigate("create");
               }}
               size="small"
             ></CreateFab>
              )}
            </>
          )}
          <ActionMenu
            actions={actions}
            setAction={setAction}
            setSelected={setSelected}
            setSelectionModel={setSelectionModel}
          />
          <SearchField
            value={value}
            onChange={onChange}
            clearSearch={clearSearch}
            items={quickSearchItems}
          /> 
            {enableFilter ? (
             <Box onClick={()=>{setFilterClicked(!filterClicked)}} >
               <GridToolbarFilterButton ref={setFilterButtonEl}
               aria-controls="options-menu"
               aria-haspopup="true"
              sx={{
                color: { sm: "white" },
                mx: { xs: 1, sm: 1.5 },
                mt: 0.5,
                fontSize: '1rem', 
                py: 0.5, 
                px: 2.5, 
                backgroundColor: { sm: !filterClicked ? grey[500]:"#008e92" },
                "&:hover": { backgroundColor: { sm:!filterClicked ? grey[700]:"#008e92" } },
                '& .MuiBadge-badge': {
                  display: 'none',
                },
              }} 
              />
             </Box>
                ) : (
                  <></>
                )}
        <>
        
        </>
          <Box sx={{ flex: 1 }} />

          <>
            {isSmall ? (
              <>
                {enableAdvancedSearch ? (
                  <SearchFab
                    onClick={() => {
                      props.setAction(Actions.AdvancedSearch);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <>
                {enableAdvancedSearch ? (
                  <>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={!filtersActive}
                    >
                      
                      {/* <Button color="primary" variant="contained"  onClick={() => {
                          props.setAction(Actions.AdvancedSearch);
                        }} >
                        <GridSearchIcon/>  Search
                      </Button> */}
                      <SearchButton
                        onClick={() => {
                          props.setAction(Actions.AdvancedSearch);
                        }}
                      />
                    </Badge>
                  </>
                ) : (
                  <></>
                )}
              
                <GridToolbarExport
                  csvOptions={csvOptions}
                  printOptions={printOptions}
                  setAction={setAction}
                 
                  {...other}
                />
              </>
            )}
          </>
          {action === Actions.Settings ? (
            <DisplayPreferences
              onSubmit={exportState}
              open={true}
              onClose={() => {
                setAction(Actions.None);
              }}
            />
          ) : (
            <></>
          )}
        
        </>
      </GridToolbarContainer>
    </>
  );
}
