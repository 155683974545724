import { useCallback } from "react";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Button,
  Fab,
  IconButton,
  Link,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

import type { ButtonProps as MUIButtonProps } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid-pro";


interface CustomFabProps {
  children: any; // TODO this was React.ReactNode but stopped working after upgrade to material-ui 4.11?
  onClick: (event: any) => any;
  title: string;
  "aria-label": string;
  size?: "small" | "medium" | "large";
}

export const CustomFab: React.FC<React.PropsWithChildren<CustomFabProps>> = (
  props
) => {
  const { onClick, size } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Tooltip title={props.title}>
      <Fab
        color="primary"
        aria-label={props["aria-label"]}
        sx={{
          color: "white",
          "z-index": "1000",
          marginLeft: { xs: 0.5, sm: 1.5 },
          marginTop: theme.spacing(0.5),
        }}
        onClick={onClick}
        size={size ? size : isSmall ? "small" : "medium"}
        tabIndex={32766}
      >
        {props.children}
      </Fab>
    </Tooltip>
  );
};


export const CustomFab2: React.FC<React.PropsWithChildren<CustomFabProps>> = (
  props
) => {
  const { onClick, size } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Tooltip title={props.title}>
      <Fab
        color="primary"
        aria-label={props["aria-label"]}
        sx={{
          color: "white",
          "z-index": "1000",
          marginTop: theme.spacing(0.5),
        }}
        onClick={onClick}
        size={size ? size : isSmall ? "small" : "medium"}
        tabIndex={32766}
      >
        {props.children}
      </Fab>
    </Tooltip>
  );
};
export interface FabProps {
  children?: React.ReactNode;
  onClick: (event: any) => any;
  size?: "small" | "medium" | "large";
  title?: string;
}

export const CreateFab: React.FC<FabProps> = (props) => {
  const { title = "New" } = props;
  return (
    <CustomFab
      title={title}
      aria-label="add"
      onClick={props.onClick}
      size={props.size}
    >
      <AddIcon />
    </CustomFab>
  );
};
export const CreateFab2: React.FC<FabProps> = (props) => {
  const { title = "New" } = props;
  return (
    <CustomFab2
      title={title}
      aria-label="add"
      onClick={props.onClick}
      size={props.size}
    >
      <AddIcon />
    </CustomFab2>
  );
};
export interface SaveFabProps {
  children?: React.ReactNode;
  onClick: (event: any) => any;
  onClick2?: (event: any) => any;
  size?: "small" | "medium" | "large";
  title?: string;
}

export const SaveFab: React.FC<SaveFabProps> = (props) => {
 
  return (
      <>
      <Button
      variant="contained"
      onClick={props.onClick2}
      aria-label="save"
      sx={{
        alignSelf: "flex-start",
        color: { sm: "white" },
        marginRight: { xs: 1, sm: 1.5 },
        backgroundColor: { sm: grey[700] },
        "&:hover": { backgroundColor: { sm: grey[900] } },
        
      }}
  
      >
        SAVE 
      </Button>
      <Button 
      variant="contained" 
      onClick={props.onClick} 
      sx={{ marginRight: { xs: 1, sm: 1.5 },}}
      >
        SAVE & CLOSE
      </Button>
      </>
   
  );
};

export const SearchFab: React.FC<FabProps> = (props) => {
  return (
    <CustomFab
      title="Search"
      aria-label="search"
      onClick={props.onClick}
      size={props.size}
    >
      <ManageSearchIcon />
    </CustomFab>
  );
};

export interface ButtonProps extends MUIButtonProps {
  children?: React.ReactNode;
  onClick: (event: any) => any;
}

export const CancelButton: React.FC<React.PropsWithChildren<ButtonProps>> = (
  props
) => {
  const { children = "Cancel", onClick } = props;
  return <Button children={children} onClick={onClick} />;
};

export const ActionButton: React.FC<React.PropsWithChildren<ButtonProps>> = (
  props
) => {
  const { children, onClick } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      children={children}
      onClick={onClick}
    />
  );
};

interface MenuButtonProps extends MUIButtonProps {
  onClick: (event: any) => any;
}

export const MenuButton: React.FC<MenuButtonProps> = (
  props: MenuButtonProps
) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <IconButton
      aria-controls="options-menu"
      aria-haspopup="true"
      sx={{
        color: { sm: "white" },
        marginLeft: { xs: 1, sm: 1.5 },
        backgroundColor: { sm: grey[300] },
        "&:hover": { backgroundColor: { sm: grey[400] } },
      }}
      size={isSmall ? "small" : "large"}
      {...props}
    >
      <MoreVertIcon sx={{ color: { sm: "white" } }} />
    </IconButton>
  );
};

interface ClearButtonProps {
  onClick: () => void;
  value: string;
}

export const ClearButton: React.FC<ClearButtonProps> = (
  props: ClearButtonProps
) => {
  return (
    <IconButton
      title="Clear"
      aria-label="Clear"
      size="small"
      sx={{ visibility: props.value ? "visible" : "hidden", color: "white" }}
      onClick={props.onClick}
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  );
};

interface PDFButtonProps {
  href: string;
}

export const PDFButton: React.FC<PDFButtonProps> = (props: PDFButtonProps) => {
  const { href } = props;
  return (
    <IconButton
      component={Link}
      href={href}
      rel="noopener noreferrer"
      onClick={(event: React.MouseEvent<HTMLElement>) =>
        event.stopPropagation()
      }
      size="small"
      sx={{ color: grey[400] }}
    >
      <PictureAsPdfIcon />
    </IconButton>
  );
};


export interface CloseButtonProps {
  onClose?: (event: any) => any;
  closeUrl?: string;
}

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { onClose, closeUrl } = props;
  const navigate = useNavigate();
 

  const onClick = useCallback(
    (event) => {
      if (onClose) {
        onClose(event);
      } else if (closeUrl) {
        navigate(closeUrl);
      } else {
        navigate(-1);
      }
    },
    [closeUrl, navigate, onClose]
  );

  return (
      <Button
        color="inherit"
        onClick={onClick}
        aria-label="close"
        sx={{
          alignSelf: "flex-start",
          color: { sm: "white" },
          marginRight: { xs: 1, sm: 1.5 },
          backgroundColor: { sm: grey[500] },
          "&:hover": { backgroundColor: { sm: grey[700] } },
          
        }}
     
      >
       CLOSE
      </Button>

  );
};

interface SearchButtonProps extends MUIButtonProps {
  onClick: (event: any) => any;
}

export const SearchButton: React.FC<SearchButtonProps> = (props) => (
  <Button color="primary" variant="contained" {...props}>
       <GridSearchIcon/> Search
  </Button>
);
