import { CacheAxiosResponse } from "axios-cache-interceptor";

import { axios } from "../api";

import type { AeronetV6Response } from "../types";
import type { AeronetType } from "../types/types";

/** Returns the parts groups */
export function getPartsGroups(): Promise<
  CacheAxiosResponse<AeronetV6Response<AeronetType>>
> {
  return axios.get<AeronetV6Response<AeronetType>>("/v6/parts/groups", {
    params: {
      sort: "name",
    },
  });
}
