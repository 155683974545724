import { Box, Link } from "@mui/material";

import type { AeronetViewProps } from "../types";

export interface FooterProps extends Partial<AeronetViewProps> {
  id?: number | string;
  idField?: string;
  children?: React.ReactNode;
  originalUrl: string;
  mode?: string;
}

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = (props: FooterProps) => {
  let url = props.originalUrl;
  if (props.id) {
    const idField = props.idField ? props.idField : "id";
    const mode = props.mode ? props.mode : "edit";
    url = `${props.originalUrl}&mode=${mode}&${idField}=${props.id}`;
  }

  return (
    <Box sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
      <Link
        underline="none"
        variant="body2"
        rel="noopener"
        href={url}
        target="_blank"
        tabIndex={-1}
      >
        {props.children}
      </Link>
    </Box>
  );
};

export default Footer;
