import { grey, red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const defaultTheme = createTheme();

let blueTheme = createTheme({
  palette: {
    primary: {
      main: "#00afe9",
      contrastText: "#fff",
    },
    secondary: {
      main: "#767676",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: grey[700],
      secondary: grey[500],
      disabled: grey[400],
    },
  },
  components: {
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTypography: {
      defaultProps: {
        align: "left",
        variantMapping: {
          h6: "h1",
        },
      },
    },
    MuiTextField: {
      defaultProps: { size: "small" },
    },
    MuiFormControl: {
      defaultProps: { fullWidth: true, margin: "dense", size: "small" },
    },
    MuiLink: {
      defaultProps: { underline: "none" },
    },
    MuiTable: {
      defaultProps: { size: "small" },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          [defaultTheme.breakpoints.up("sm")]: {
            paddingLeft: defaultTheme.spacing(6),
            paddingRight: defaultTheme.spacing(6),
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "160px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          [defaultTheme.breakpoints.down("md")]: {
            "&:first-child": {
              paddingLeft: defaultTheme.spacing(1) + "!important;",
            },
            "&:last-child": {
              paddingRight: defaultTheme.spacing(1) + "!important;",
            },
          },
          [defaultTheme.breakpoints.up("sm")]: {
            "&:first-child": {
              paddingLeft: defaultTheme.spacing(6),
            },
            "&:last-child": {
              paddingRight: defaultTheme.spacing(6),
            },
          },
        },
        head: {
          color: grey[600],
        },
      },
    },
  },
  typography: {
    h1: {
      color: "#00afe9",
      fontSize: "2rem",
      fontWeight: 300,
    },
    h2: {
      color: "#00afe9",
      fontSize: "1.67rem",
      fontWeight: 300,
    },
    h3: {
      color: "#00afe9",
      fontSize: "1.5rem",
      fontWeight: 300,
    },
    h4: {
      color: grey[600],
      fontSize: "1rem",
      fontWeight: 500,
    },
    h5: {
      color: grey[600],
      fontSize: "1rem",
      fontWeight: 500,
    },
    h6: {
      color: "#00afe9",
      fontSize: "0.9rem",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 59,
      [defaultTheme.breakpoints.up("sm")]: {
        minHeight: 72,
        marginTop: defaultTheme.spacing(1.5),
      },
    },
  },
});
blueTheme = responsiveFontSizes(blueTheme);

let tealTheme = createTheme({
  palette: {
    primary: {
      main: "#008e92",
      contrastText: "#fff",
    },
    secondary: {
      main: "#767676",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: grey[700],
      secondary: grey[500],
      disabled: grey[400],
    },
  },
  components: {
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTypography: {
      defaultProps: {
        align: "left",
        variantMapping: {
          h6: "h1",
        },
      },
    },
    MuiTextField: {
      defaultProps: { size: "small" },
    },
    MuiFormControl: {
      defaultProps: { fullWidth: true, margin: "dense", size: "small" },
    },
    MuiLink: {
      defaultProps: { underline: "none" },
    },
    MuiTable: {
      defaultProps: { size: "small" },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          [defaultTheme.breakpoints.up("sm")]: {
            paddingLeft: defaultTheme.spacing(6),
            paddingRight: defaultTheme.spacing(6),
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "160px",
          color:"black",
          fontWeight:"bold",
          '&:hover': {
            color: '#008e92',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          [defaultTheme.breakpoints.down("md")]: {
            "&:first-child": {
              paddingLeft: defaultTheme.spacing(1) + "!important;",
            },
            "&:last-child": {
              paddingRight: defaultTheme.spacing(1) + "!important;",
            },
          },
          [defaultTheme.breakpoints.up("sm")]: {
            "&:first-child": {
              paddingLeft: defaultTheme.spacing(6),
            },
            "&:last-child": {
              paddingRight: defaultTheme.spacing(6),
            },
          },
        },
        head: {
          color: grey[600],
        },
      },
    },
  },
  typography: {
    h1: {
      color: "#008e92",
      fontSize: "2rem",
      fontWeight: 300,
    },
    h2: {
      color: "#008e92",
      fontSize: "1.67rem",
      fontWeight: 300,
    },
    h3: {
      color: "#008e92",
      fontSize: "1.5rem",
      fontWeight: 300,
    },
    h4: {
      color: grey[600],
      fontSize: "1rem",
      fontWeight: 500,
    },
    h5: {
      color: grey[600],
      fontSize: "1rem",
      fontWeight: 500,
    },
    h6: {
      color: "#008e92",
      fontSize: "0.9rem",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 59,
      [defaultTheme.breakpoints.up("sm")]: {
        minHeight: 72,
        marginTop: defaultTheme.spacing(1.5),
      },
    },
  },
});
tealTheme = responsiveFontSizes(tealTheme);

export { blueTheme, tealTheme };