import { Loading } from "aws-amplify-react";
import { Component } from "react";

import spinnerStyles from "./AppLoading.module.css";

const loader = document.querySelector(".loading");
const hideLoader = () => {
  if (loader) {
    loader.classList.add("loading--hide");
  }
};

class AppLoading extends Component {
  render() {
    return (
      <div className={spinnerStyles.appLoading} data-testid="loading">
        <img
          src={process.env.PUBLIC_URL + "/" + process.env.REACT_APP_LOGO}
          alt=""
        ></img>
        {JSON.parse(process.env.REACT_APP_NEW_THEME ?? "true") ? (
          <div className={spinnerStyles.spinner_2022}></div>
        ) : (
          <div className={spinnerStyles.spinner}></div>
        )}
      </div>
    );
  }
}

class _CustomLoading extends Loading {
  showComponent(theme: any): JSX.Element;
  showComponent(): JSX.Element {
    return <AppLoading />;
  }
}

const CustomLoading = _CustomLoading as unknown as React.FC<
  React.PropsWithChildren<unknown>
>;

export { AppLoading, hideLoader };
export default CustomLoading;
