import { AxiosResponse } from "axios";

import { axios } from "../api";

import type { AeronetV6Response } from "../types";
import type { AeronetType } from "./types";

class _TypeService {
  /* Queries types api and returns a page of results */
  async getTypePage(
    parent_id: number,
    page = 1
  ): Promise<AxiosResponse<AeronetV6Response<AeronetType>>> {
    const params: { [key: string]: string | number } = {
      page,
      parent_id,
      sort: "name",
    };

    return axios.get("/v6/types", { params: params });
  }

  /* Generator function which recursively calls the types api returning individual types until all pages are consumed */
  async *getTypes(parent_id: number) {
    let page = 0;
    let resp: AxiosResponse<AeronetV6Response<AeronetType>>;
    do {
      page += 1;
      resp = await this.getTypePage(parent_id, page);
      for (const result of resp.data.data) {
        yield result;
      }
    } while (resp.data.total > page * resp.data.size);
  }

  public async query(parent_id: number): Promise<AeronetType[]> {
    const types: AeronetType[] = [];

    // get the config iterator
    const rawTypes = this.getTypes(parent_id);

    // loop through one config at a time
    for await (const typeObj of rawTypes) {
      // transform a config object into a simple dict of config_variable: config_value
      types.push(typeObj);
    }

    return types;
  }
}

export let TypeService = new _TypeService();
