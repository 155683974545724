import { IconButton, SvgIcon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { SignOut, withAmazon } from "aws-amplify-react";
import cookie from "cookie";

import { axios } from "../api";

function LogoutIcon(props) {
  // https://material.io/resources/icons/?icon=logout&style=outline
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
      </g>
    </SvgIcon>
  );
}

class CustomSignOut extends SignOut {
  signOut() {
    const options = {
      expires: new Date(1970, 1, 1, 0, 0, 1),
      maxAge: -1,
      path: "/",
      domain: window.location.hostname,
    };
    document.cookie = cookie.serialize("WebHavenUserID", "", options);
    document.cookie = cookie.serialize("ModuleSessionID", "", options);
    document.cookie = cookie.serialize("RoxenHttpsWizardId", "", options);
    document.cookie = cookie.serialize("RoxenWizardId", "", options);

    // Delete the api call cached in src/users/User.ts
    axios.storage.remove("get-current-user");
    Auth.currentAuthenticatedUser().then((user) => {
      axios.storage.remove(
        `get-nav-${user.attributes["custom:aeronet_user_id"]}`
      );
    }, (error) => {
      console.debug(error);
    });

    super.signOut();
    Sentry.configureScope((scope) => scope.setUser(null));
    if (this.state.stateFromStorage) {
      this.changeState("signedOut");
    }
  }

  render() {
    return (
      <IconButton
        aria-label="sign out"
        color="inherit"
        onClick={this.signOut}
        edge="end"
        sx={{
          paddingLeft: 0.5,
          paddingRight: 0.5,
        }}
        size="large"
      >
        <LogoutIcon fontSize="large" />
      </IconButton>
    );
  }
}

export default withAmazon(CustomSignOut);
