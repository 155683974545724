import { AxiosResponse } from "axios";

import { axios } from "../api";

import { AeronetV6ItemsResponse } from "../types";
import type { ITask } from "./types";

class JobTaskService {
  public async query(jobId: number | string): Promise<ITask[]> {
    // @ts-ignore
    return axios
      .get(`/v6/jobs/${jobId}/tasks`)
      .then((resp: AxiosResponse<AeronetV6ItemsResponse<ITask>>) => {
        return resp.data.items;
      });
  }
}

export let JobTask = new JobTaskService();
