import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ConfigService } from "../config/ConfigService";

/**
 *
 * @param app Roxen module name
 * @param variable Config variable name without the app prefix (optional)
 * @returns
 */
export function useConfig(app: string, variable: string = "") {
  const { enqueueSnackbar } = useSnackbar();
  const [config, setConfig] = useState<{ [key: string]: any }>();

  useEffect(() => {
    const controller = new AbortController();

    async function getConfig() {
      try {
        const config = await ConfigService.query(app, variable, {
          signal: controller.signal,
        });
        setConfig(config);
      } catch (e) {
        console.error(e);
        enqueueSnackbar(`Error loading ${app} config`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    }

    getConfig();

    return () => {
      controller.abort();
    };
  }, [app, variable, enqueueSnackbar]);

  return config;
}
