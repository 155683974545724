import React, {  useEffect, useMemo, useState } from 'react'
import { axios } from '../api'
import { AxiosResponse } from 'axios'
import { DataGridPremium, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium'
import CustomFilterPanelPremium from '../components/CustomFilterPanelPremium'
import { format } from 'date-fns'


interface DownloadaPrice {
    user_name:string | null;
    count: number;
    id:number
   }
const PriceDownloadReport = () => {
  const [partSeached,setPartSearched] = useState<DownloadaPrice[]>([])
  const [loading,setLoading] = useState(true)


  useEffect(()=>{
    axios({
      method: 'GET',
      url:"/v6/parts/download_history/",
      id:"get-parts-download_history"
    }).then((resp:AxiosResponse<DownloadaPrice[]>)=>{
      setPartSearched(resp.data)
      setLoading(false)
      axios.storage.remove("get-price-download_history")
  
    })
  },[])

const columns: GridColDef[] = useMemo(
    ()=>[
        {
            headerName: "Count",
            field: "count",
            flex: 0.5,
        },  
        {
            headerName: "User",
            field: "user_name",
            flex: 0.5,
        },
        {
          headerName:"Date",
          field:"download_datetime",
          flex:0.5,
          type:"date",
          valueGetter:(params,row)=>{
            return row?.download_datetime
          },
          valueFormatter:(params,row)=>{
            return format(new Date(params),"dd-MM-yyyy")
          }
        }
    ],[]
)

const customToolbar = (props)=>{
    return (
        <>
         <GridToolbarContainer >
      <GridToolbarExport {...props} />
      <GridToolbarFilterButton/>
    </GridToolbarContainer>
        </>
    )
}

  return (
    <>

      <DataGridPremium
        autoHeight
        checkboxSelection
        slots={{ toolbar: customToolbar , 
        filterPanel:CustomFilterPanelPremium}}
        slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
        columns={columns}
        rows={partSeached}
        sx={{ flex: 1, margin: "20px" }}
        disableRowSelectionOnClick
        hideFooter
        ignoreValueFormatterDuringExport
        loading= {loading}
            />
    </>
  )
}

export default PriceDownloadReport