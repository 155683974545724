import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { IconButton, Menu } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import {
  LoadZendeskWidget,
  ZendeskRedirect,
  ZendeskWidget,
} from "./ZendeskWidget";

const HelpMenu = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "helpMenu" });

  return (
    <LoadZendeskWidget>
      <IconButton
        aria-label="help"
        aria-controls="menu-help"
        color="inherit"
        sx={{
          paddingLeft: 0.5,
          paddingRight: 0.5,
        }}
        {...bindTrigger(popupState)}
        size="large"
      >
        <HelpOutlineOutlinedIcon fontSize="large" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: grey[400],
            borderRadius: 0,
          },
        }}
      >
        <ZendeskRedirect
          onClick={popupState.close}
          returnTo="https://help.aeronetsoftware.com/hc/en-us/articles/900001352466-3-7-Release-Notes"
        >
          What's New
        </ZendeskRedirect>
        <ZendeskRedirect onClick={popupState.close}>
          Aeronet Help
        </ZendeskRedirect>
        <ZendeskRedirect
          onClick={popupState.close}
          returnTo="https://help.aeronetsoftware.com/hc/en-us/requests"
        >
          My Requests
        </ZendeskRedirect>
        <ZendeskRedirect
          onClick={popupState.close}
          returnTo="https://help.aeronetsoftware.com/hc/en-us/requests/new"
        >
          Submit a Request
        </ZendeskRedirect>
        <ZendeskWidget onClick={popupState.close} />
      </Menu>
    </LoadZendeskWidget>
  );
};

export default HelpMenu;
