import React, { useEffect, useRef, useState, useTransition } from 'react'
import Header from '../components/Header';
import { SaveFab } from '../components/Buttons';
import { TabBar, TabPanel } from '../components/Tabs';
import { Button, Grid, Tab } from '@mui/material';
import Content from '../components/Content';
import { Field, Form, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { axios } from '../api';
import { useSnackbar } from 'notistack';
import { AxiosResponse, Method } from 'axios';
import {  InspectionDetailView } from './types';
import { LoadingView } from '../components/Loading';
import CustomSnackbarContent from '../components/CustomSnackbarContent';
import { handleAPIErrors } from '../utils/FormikErrors';
import * as Yup from "yup";
import InspectionComponent from './InspectionComponent';
import AlertDialog from '../components/AlertDialog';

interface InspectionDetailProps {
    originalUrl: any
}


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const InspectionDetail: React.FC<InspectionDetailProps> = (props) => {
    const { originalUrl } = props
    const {id} = useParams()
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar()
    const [title, setTitle] = useState("")
    const [tabValue, setTabValue] = useState(0)
    const formRef = useRef<FormikValues>()
    const [inspection,setInspection] = useState<InspectionDetailView>()
    let isRendered = useRef(false);
    const [tabDialogOpen, setTabDialogOpen] = useState(false);
    const [, startTransition] = useTransition();
    useEffect(() => {
        isRendered.current = true;
        if (id) {
          axios
            .get(`/v6/inspection/${id}`, { id: `get-inspection-${id}` })
            .then((resp: AxiosResponse<InspectionDetailView>) => {
              if (isRendered.current) {
                const inspection = {};
                for (const value in resp.data) {
                    inspection[value] = resp.data[value] === null ? "" : resp.data[value];
                }
               
                setInspection(inspection as InspectionDetailView);
              }
    
              return null;
            })
            .catch((error) => {
              enqueueSnackbar(`Error fetching Inspection`, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
              console.error(error);
            });
        }
        else{
            const new_inspection:InspectionDetailView = {
                title: "",
                reference: "",
                version_date: "",
                version_url: "",
                creep_type: 0,
                tolerance: 0,
                version_no: "",
                created: "",
                notes: "",
                inspection_type_id:0
              }
            startTransition(()=>{
                setInspection(new_inspection as InspectionDetailView)
                setTitle("New Inspection")
            })
        }
    },[id,enqueueSnackbar])

    useEffect(() => {
        if (id) {
          if (inspection && "id" in inspection) {
            setTitle(`Inspection- ${inspection.id}`);
          }
        }
      }, [id, inspection]);


    const submitForm = () => {
      if (formRef.current) {
        formRef.current.submitForm().then(() => {
          if (
            formRef.current &&
            !formRef.current.isValidating &&
            formRef.current.isValid
          ) {
            navigate("..");
          }
        });
      }
    else{
        navigate(-1)
    }
    };
    
    const saveForm = () => {
      if (formRef.current) {
        formRef.current.submitForm()
      }

    };
    
    const handleClose = () => {
        if (formRef.current) {
            formRef.current.submitForm().then(() => {
                if (
                  formRef.current &&
                  !formRef.current.isValidating &&
                  formRef.current.isValid
                ) {

                  setTabValue(1);
                }
              });
        }
      };
    
    const handleTabChange = (
        _event: React.SyntheticEvent<{}> | null,
        newValue: unknown) => {
            // setTabValue(newValue as number)
          
            if (
                formRef.current &&
                ((formRef.current.dirty && !formRef.current.isSubmitting) ||
                  !formRef.current.initialValues.id)
              ) {
                
                setTabDialogOpen(true);
               
              }
              else{
                setTabValue(newValue as number);
              }
              return;
    }
    return (
        <>
            <Header actions={[<SaveFab onClick2={saveForm} onClick={submitForm} key={1} />]}>
                {title}
            </Header>
            <TabBar value={tabValue} onChange={handleTabChange}>
                <Tab disableRipple label="General" {...a11yProps(0)} />
                <Tab disableRipple label="Limitations" {...a11yProps(1)} />
            </TabBar>
            <Content>


                <TabPanel value={tabValue} index={0} data-testid="inspection-form">
                    {
                        inspection ?<General formRef={formRef} originalUrl={originalUrl} inspection={inspection} setInspection = {setInspection} />:<LoadingView/>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {
                        inspection ?<InspectionComponent formRef={formRef} originalUrl={originalUrl} inspection={inspection} />:<LoadingView/>
                    }
                </TabPanel>

            </Content>
            <AlertDialog
            title="Are you sure ?"
            description="Inspection is not saved"
            open={tabDialogOpen}
            handleClose={() => {
              setTabDialogOpen(false);
            }}
          >
            <Button
              onClick={() => {
                setTabDialogOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (formRef.current) {
                  formRef.current.resetForm();
                  setTabDialogOpen(false);
                }
              }}
              color="primary"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                setTabDialogOpen(false);
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </AlertDialog>
        </>
    )
}


export interface GeneralTabProps {
    originalUrl: string;
    formRef?: any,
    inspection:InspectionDetailView
    setInspection:any;
}

const General = (props: GeneralTabProps) => {
    const { formRef ,inspection,setInspection} = props
  
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();


    const inspectionSchema = Yup.object().shape({
        title: Yup.string().required("Required"),
      });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={inspection}
                innerRef={formRef}
                validationSchema={inspectionSchema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    let method: Method = "post";
                    let url:string = `/v6/inspection`;
                    let msg: string = "created";

                    if (Object.prototype.hasOwnProperty.call(inspection, "id") && inspection.id) {
                        method = "put";
                        url = `/v6/inspection/${inspection.id}`;
                        msg = "updated";
                    }

                    const inspection_data = Object.entries(values).reduce((acc,[key,value])=>{
                        acc[key] = value

                        return acc;
                    },{} as InspectionDetailView)

                    axios({
                        url: url,
                        method: method,
                        data: inspection_data
                    }).then((resp: AxiosResponse<InspectionDetailView>)=>{
                        axios.storage.remove(`get-inspection-${inspection.id}`)
                        setInspection(resp.data)
                        let uri = location.pathname;
                        uri = uri.replace("create",`${resp.data.id}`)
                        enqueueSnackbar(
                            <CustomSnackbarContent
                              message={`Successfully ${msg} Inspection`}
                              uri={uri}
                            />,
                            {
                              variant: "success",
                            }
                          );
                        resetForm();
                        // navigate(-1)
                    }).catch((error)=>{
                        console.debug(error,"error")
                        handleAPIErrors(error, setErrors);
                        setSubmitting(false);
                        enqueueSnackbar(`Error saving Inspection ${error['response']['data']['detail'][0]['loc'][1]}`, {
                            variant: "error",
                        });
                    })
                }}
            >
                {({ isSubmitting, setFieldValue, values, setValues }) => (
                    <Form className='mui-form'>
                        <Grid container spacing={2}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Inspection Type"
                                        name="inspection_type_id"
                                        value = {inspection?.type_obj?.name}
                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Version No."
                                        name="version_no"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Tolerance"
                                        name="tolerance"

                                    />
                                </Grid>

                                <Grid item xs={12} md={4} lg={4}>
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Subject"
                                        name="title"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Url"
                                        name="version_url"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Creep Type"
                                        name="creep_type"

                                    />
                                </Grid>

                                <Grid item xs={12} md={4} lg={4}>
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={TextField}
                                        label="Reference Number"
                                        name="reference"

                                    />
                                    <Field
                                        autoFocus
                                        required
                                        fullWidth
                                        component={DatePicker}
                                        label="Date Created"
                                        name="created"

                                    />
                                </Grid>

                            </Grid>
                           <Grid item  xs={12} md={12} lg = {12} ></Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg = {12} >
                                    <Field
                                    autoFocus
                                    required
                                    fullWidth
                                    multiline
                                    rows="5"
                                    component={TextField}
                                    label="Instruction"
                                    name="notes"
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Form>
                )
                }

            </Formik>
        </>
    )
}

export default InspectionDetail
