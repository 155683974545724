import { AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";

import { axios } from "../api";

import type { AeronetV6Response } from "../types";
import type { IConfig } from "./types";

export class _ConfigService {
  /* Queries config api and returns a page of results */
  async getConfigPage(
    config_function: string = "global",
    config_variable: string = "",
    page = 1,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AeronetV6Response<IConfig>>> {
    let params: { [key: string]: any } = {
      filter: [
        JSON.stringify({
          field: "config_function",
          value: config_function,
        }),
      ],
      page: page,
      size: 100,
    };
    if (options) {
      params = { ...options, ...params };
    }
    if (config_variable) {
      params.filter.push(
        JSON.stringify({
          field: "config_variable",
          value: `${config_function}_${config_variable}`,
        })
      );
    }

    return axios.get("/v6/config", {
      params: params,
      paramsSerializer: (params) =>
        queryString.stringify(params),
      id: `get-config-${config_function}-${config_variable}-${page}`,
      cache: {ttl: 1000 * 60 * 60 * 24 } // 1 day
    });
  }

  /* Generator function which recursively calls the config api returning individual configs until all pages are consumed */
  async *getConfig(
    config_function: string,
    config_variable?: string,
    options?: AxiosRequestConfig
  ) {
    let page = 0;
    let resp: AxiosResponse<AeronetV6Response<IConfig>>;
    do {
      page += 1;
      resp = await this.getConfigPage(
        config_function,
        config_variable,
        page,
        options
      );
      for (const config of resp.data.data) {
        yield config;
      }
    } while (resp.data.total > page * resp.data.size);
  }

  public async query(
    app: string = "",
    variable: string = "",
    options?: AxiosRequestConfig
  ): Promise<{ [key: string]: any }> {
    // get the config iterator
    const rawConfigs = this.getConfig(app, variable, options);

    const config: { [key: string]: string } = {};
    // loop through one config at a time
    for await (const configObj of rawConfigs) {
      // transform a config object into a simple dict of config_variable: config_value
      config[configObj.config_variable] = configObj.config_value;
    }
    return config;
  }
}

export let ConfigService = new _ConfigService();
