import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AircraftComponents } from '../aircraft/types'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { axios } from '../api'
import { Box, Button, Grid, ListSubheader, Menu, MenuItem} from '@mui/material'
import { DataGridPremium, GridColDef,  GridCsvExportMenuItem,  GridToolbarColumnsButton,  GridToolbarContainer,  GridToolbarDensitySelector,  GridToolbarExportContainer,  GridToolbarFilterButton, ValueOptions } from '@mui/x-data-grid-premium'
import LoadingSpinner from '../components/Loading'
import { useConfig } from '../hooks/useConfig'
import CustomFilterPanelPremium from '../components/CustomFilterPanelPremium'
import { DueDateDetailView } from './type'
import {  CreateFab2 } from '../components/Buttons'
import DuedateParentSliderForm from './DuedateParentSliderForm'
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentBox from '../components/DatagridProContnet'
import { ActionMenuItem } from '../components/datagrid/ActionMenu'
import { bindTrigger } from 'material-ui-popup-state'
import { bindMenu, usePopupState } from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface LinkedRecordsProps {
    duedate:DueDateDetailView;
}


interface ActionMenuButtonProps {
  onClick?: (event: any) => any;
  actions?: ActionMenuItem[];
  disabled?: boolean;
}

const ActionMenu: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { actions, disabled } = props;
  const popupState = usePopupState({
      variant: "popover",
      popupId: "action-menu",
  });

  return (
      <>
          <ActionMenuButton disabled={disabled} {...bindTrigger(popupState)} />
          <Menu {...bindMenu(popupState)}>
              {actions?.map((action, index) => {
                  if (action.to) {
                      return (
                          <MenuItem
                              onClick={() => popupState.close()}
                              component={Link}
                              to={action.to}
                              key={index}
                          >
                              {action.name}
                          </MenuItem>
                      );
                  }
                  return (
                      <MenuItem
                          key={index}
                          onClick={() => {
                              popupState.close();
                              if (action.onClick) {
                                  action.onClick();
                              }
                          }}
                      >
                          {action.name}
                      </MenuItem>
                  );
              })}
          </Menu>
      </>
  );
};

const ActionMenuButton: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { onClick, disabled } = props;

  return (
      <Button
          disabled={disabled}
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={onClick}
          sx={{
              color: { sm: "white" },
              mx: { xs: 1, sm: 1.5 },
              mt: 0.5,
              backgroundColor: { sm: grey[500] },
              "&:hover": { backgroundColor: { sm: grey[700] } },
          }}
          variant="contained"
          endIcon={<KeyboardArrowDownIcon />}
      >
          ACTIONS
      </Button>
  );
};



const LinkedRecords: React.FC<LinkedRecordsProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar()
    const {duedate} = props
    const [linkedRecords,setLinkedRecords] = useState<AircraftComponents[]>()
    const [open,setOpen] = useState(false);
    const dueDateConfig =useConfig("whiteboard")
    const statusOptions: ValueOptions[] | undefined = useMemo(() => {
        if (dueDateConfig && dueDateConfig.status_list) {
          const statuses: ValueOptions[] = [{ value: "", label: "Any" }];
          Object.keys(dueDateConfig?.status).forEach((key) => {
            statuses.push({
              value: key,
              label: dueDateConfig?.status[key],
            });
          });
          const sortedStatuses = statuses.sort((a, b) =>
            // @ts-ignore
            a.label!.localeCompare(b.label!, "en", {
              sensitivity: "base",
            })
          );
    
          return sortedStatuses;
        }
      }, [dueDateConfig]);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                headerName:"ID",
                field:"id",
                flex:0.5
            },
            {
                headerName:"Status",
                field:"status",
                flex:0.5,
                type: "singleSelect",
                filterable:true,
                valueOptions: statusOptions,
                valueFormatter: (params,row) => {
                  try {
                    if (dueDateConfig) {
                      return dueDateConfig.status[
                        Number(row.value as number)
                      ].toUpperCase();
                    }
                  } catch {
                    return row.value;
                  }
                },
                valueGetter: (params,row) => row?.status,
            },
            {
                headerName:"Last Done",
                field:"last_done",
                flex:0.5,
                filterable:false
            },
            {
                headerName:"Code",
                field:"title",
                flex:0.5,
                filterable:false
            },
            {
                headerName:"Section",
                field:"section",
                flex:0.5,
                filterable:false,
                valueGetter:(params,row)=> row?.section?.name
            },
            {
                headerName:"Type",
                field:"type_id",
                flex:0.5,
                filterable:false,
                valueGetter:(params,row)=> row?.type_obj?.name
            },
            {
                headerName:"Comment",
                field:"comment",
                flex:0.5,
                filterable:false
            },
            {
                headerName:"User",
                field:"user",
                flex:0.5,
                filterable:false,
                valueGetter:(params,row)=> row?.user_obj?.gecos
            },
            {
                headerName:"Due Date",
                field:"due_date",
                flex:0.5,
                filterable:false
            },
            {
                headerName:"Remaining",
                field:"remaining",
                flex:0.5,
                filterable:false
            },
    
         
        ],
        [dueDateConfig,statusOptions]
      );

    const getData = useCallback(
        async () => {
            try {
              const resp: AxiosResponse =
                await axios.get(`/v6/duedate/linked_records/${duedate?.id}`, {
                    id:`get-linked-records-${1}`
                });
               axios.storage.remove(`get-linked-records-${1}`)
               setLinkedRecords(resp.data)
              
            } catch (e) {
              // @ts-ignore
              if (!axios.isCancel(e)) {
                enqueueSnackbar("Error fetching Linked Records", {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                });
              }
              throw e;
            }
          },[enqueueSnackbar,duedate?.id])

          useEffect(()=>{
            getData()
          },[getData])


const GridToolbarExport = ({
  ...other
}) => (
  <GridToolbarExportContainer {...other}>
        <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Export</b></ListSubheader>
        <GridCsvExportMenuItem  options={{
          fileName:"Linked-Records"
        }} />

  </GridToolbarExportContainer>
);
   
function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{
      display:"flex",
      justifyContent:"space-between"
    }}>
      <Box>
      <GridToolbarFilterButton slotProps={{
        button:{
          sx:{
            color: "#616161 !important",
            fontSize:"1rem !important",
            marginRight:"30px !important"
          }
        }
      }}/>
      <GridToolbarColumnsButton  slotProps={{
        button:{
          sx:{
            color: "#616161 !important",
            fontSize:"1rem !important",
            marginRight:"30px !important"
          }
        }
      }}/>
      <GridToolbarDensitySelector  slotProps={{
        button:{
          sx:{
            color: "#616161 !important",
            fontSize:"1rem !important",
            marginRight:"30px !important"
          }
        }
      }}/>
      </Box>
      <Box>
      <GridToolbarExport slotProps={{
        button:{
          sx:{
            color: "#616161 !important",
            fontSize:"1rem !important",
            marginRight:"30px !important"
          }
        }
      }}  {...props} />
      <Button sx={{
          color:"#616161",
          fontSize:"1rem"
      }}>
      <PictureAsPdfIcon /> <span style={{marginLeft:"10px"}}>PRINT</span>
      </Button></Box>
    </GridToolbarContainer>
  );
}

const actions = useMemo<ActionMenuItem[]>(() => {
  return [

  ];
}, []);   


  return (
    <>
  
      <ContentBox>
      <Grid container rowSpacing={3} sx={{paddingTop:"30px"}}>
                <Grid item xs={12} md={6} lg={6}>
                    <CreateFab2
                    onClick={()=>{
                      setOpen(true)
                    }} 
                    size='small' 
                    key={1} />      
                    <ActionMenu key={2} actions={actions} />             
                </Grid>

                
            </Grid>
            {
      linkedRecords ?
      <DataGridPremium
        columns={columns}
        rows={linkedRecords}
        checkboxSelection
        autoHeight
        slots={{ toolbar: CustomToolbar ,
          filterPanel:CustomFilterPanelPremium
          }}
        initialState={{
          density: 'compact',
        }}
        sx={{ flex: 1, marginTop: "30px",
          borderStyle:"none",
          borderTop:"2px solid rgba(224, 224, 224, 1)",
          borderRadius:"0",
          "& .MuiDataGrid-toolbarContainer":{
              borderBottom:"2px solid rgba(224, 224, 224, 1)",
              paddingTop:"10px",
              paddingBottom:"10px"
          },
          // "& .css-lgu1v7-MuiButtonBase-root-MuiButton-root":{
          //     color: "#616161 !important",
          //     fontSize:"1rem !important",
          //     marginRight:"30px !important"
          // }
        }}
     
      />:<LoadingSpinner/>
    }
        </ContentBox>
        <DuedateParentSliderForm  open={open} onClose={()=>{
          setOpen(false)
          getData()
        }} duedate_id={duedate?.id||0}/>
    </>
  )
}

export default LinkedRecords