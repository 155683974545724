import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import PartSearchReport from "./PartSearchedReport";
import PriceDownloadReport from "./PriceDownloadReport";
import CustomerSignInReport from "./CustomerSignInReport";
import ReportLayout from "./ReportLayout";
import SystemResponseReport from "./SystemResponseReport";



const Reports = (props) => {
    return(
        <Routes>
           <Route
            element={
              <ReportLayout/>
            }
          >
          <Route index element={<Navigate to="parts" />} />
          <Route
          path="parts"
          element={
          <PartSearchReport/>
          }
        />
          <Route
            path="Login"
            element={
            <CustomerSignInReport/>
            }
          />
        <Route
          path="pricedownload/"
          element={
          <PriceDownloadReport/>
          }
        />
        <Route
          path="system_response/"
          element={
          <SystemResponseReport/>
          }
        />
          </Route>
      </Routes>
    );
}


export default Reports;