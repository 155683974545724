import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { TypeService } from "../types/TypeService";
import type { AeronetType } from "../types/types";

export function useTypes(parentId?: number) {
  const { enqueueSnackbar } = useSnackbar();
  const [types, setTypes] = useState<AeronetType[]>();

  useEffect(() => {
    let active = true;

    async function getTypes() {
      try {
        const types = await TypeService.query(parentId!);

        if (active) {
          setTypes(types);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(`Error loading types`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    }

    if (parentId) {
      getTypes();
    }

    return () => {
      active = false;
    };
  }, [enqueueSnackbar, parentId]);

  return types;
}
