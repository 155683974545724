import { Alert, AlertTitle, Typography } from "@mui/material";

export type ValidationError = {
  loc: Array<string | number>;
  msg: string;
  type: string;
};

interface ImportErrorsProps {
  errors: ValidationError[];
  onClose?: (event) => void;
}

export const ImportErrors: React.FC<ImportErrorsProps> = (props) => (
  <Alert severity="error" onClose={props.onClose} sx={{ mt: 2 }}>
    <AlertTitle>Errors</AlertTitle>
    {props.errors.map((error) => {
      const [, lineIndex, field] = error.loc;
      return (
        <div key={lineIndex}>
          <Typography variant="h5">
            Line {(lineIndex as number) + 1}:
          </Typography>
          <Typography paragraph>
            {field === "__root__" ? "" : <strong>{field}</strong>} - {error.msg}{" "}
          </Typography>
        </div>
      );
    })}
  </Alert>
);
