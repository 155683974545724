import { ValueOptions } from "@mui/x-data-grid-pro";
import { useMemo } from "react";

export function usePartsStoreOptions(config?: { [key: string]: any }): ValueOptions[] | undefined {
  const storeOptions: ValueOptions[] | undefined = useMemo(() => {
    if (config && config.items_shelf_location) {
      const stores: ValueOptions[] = [];
      Object.keys(config?.items_shelf_location).forEach((key) => {
        stores.push({
          value: key,
          label: config?.items_shelf_location[key],
        });
      });
      const sortedStores = stores.sort((a, b) =>
        // @ts-ignore
        a.label!.localeCompare(b.label!, "en", {
          sensitivity: "base",
        })
      );
      sortedStores.splice(0, 0, { value: "", label: "Any" });
      return sortedStores;
    }
  }, [config]);
  return storeOptions;
}
