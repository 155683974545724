import { ValueOptions } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useConfig } from "./useConfig";
import { useTypes } from "./useTypes";

export function useLocationOptions() {
  const config = useConfig("parts");
  const locations = useTypes(config?.location_parent);

  const locationOptions: ValueOptions[] | undefined = useMemo(() => {
    if (locations) {
      const l = [{ value: "", label: "Any" }];
      const lo = locations?.map(({ id, name }) => ({
        value: name,
        label: name,
      }));
      l.push(...lo);
      return l;
    }
  }, [locations]);

  return locationOptions;
}
