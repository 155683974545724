import {
    ChangeEvent,
    forwardRef,
    JSXElementConstructor,
    MutableRefObject,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  
  import {
    
    Box,
    Button,
    MenuItem,
    Skeleton,
    styled,
    TextField,
  } from "@mui/material";
  import { unstable_useId as useId } from "@mui/material/utils";
  import {
    GridApi,
    GridFilterApi,
    GridFilterFormProps,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterModel,
    gridFilterModelSelector,
    ValueOptions,
  } from "@mui/x-data-grid-pro";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import { format } from "date-fns-tz";
  import { NavigateFunction, useNavigate } from "react-router-dom";
  
  import { useGlobalConfig } from "../../hooks/useGlobalConfig";
  import { ClearButton } from "../Buttons";
  import Sheet, { SheetTitle } from "../Sheet";
import { grey } from "@mui/material/colors";
  
  export const advancedSearchAutocompleteStyles = ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary,
      },
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiInput-input": {
      color: "white",
    },
    "& .Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "white",
      "&.Mui-focused": {
        color: "white",
      },
    },
    "& .MuiButtonBase-root": {
      color: "white",
    },
  });
  
  
  
  export const AdvancedSearchDatePicker = styled(DatePicker)(
    advancedSearchAutocompleteStyles
  );
  
  export interface AdvancedSearchFormProps {
    onClose: () => void;
    apiRef: MutableRefObject<GridApi>;
    navigate: NavigateFunction;
    items: GridFilterItem[];
    applyFilter: (item: GridFilterItem) => void;
  }
  
  export interface AdvancedSearchProps {
    apiRef: MutableRefObject<GridApi>;
    form: JSXElementConstructor<AdvancedSearchFormProps>;
    onClose: () => void;
    onClear: () => void;
    setAdvanceSearch:any;
    onSearch?: (
      model: GridFilterModel,
      apiRef: MutableRefObject<GridApi>
    ) => void;
    open: boolean;
    defaultItems:any
    [x: string | number | symbol]: unknown;
  }
  
  export const AdvancedSearch2: React.FC<AdvancedSearchProps> = (
    props: AdvancedSearchProps
  ) => {
    const { apiRef, form, onClose, onClear, onSearch, open, setAdvanceSearch,defaultItems,...formProps } =
      props;
    const navigate = useNavigate();
    let Form = form;
  
    const filterModel = gridFilterModelSelector(apiRef);
    const [filterItems, setFilterItems] = useState<GridFilterItem[]>([]);
  
    /**
     * Stores a local copy of filterModel.items
     */
    useEffect(() => {
      console.log("Advanced search: set filter items");
      if(filterModel.items.length>0)
      {
        setFilterItems(filterModel.items.map((obj) => ({ ...obj })));
      }
      else{
        setFilterItems(defaultItems.map((obj) => ({ ...obj })));
      }
    }, [filterModel.items,defaultItems]);
  
    /* Implementation of this seems to be buggy in the api so redefining here */
    const upsertFilterItems = useCallback<GridFilterApi["upsertFilterItems"]>(
      (items) => {
        const filterModel = gridFilterModelSelector(apiRef);
        const existingItems = [...filterModel.items];
        items.forEach((item) => {
          const itemIndex = items.findIndex(
            (filterItem) => filterItem.id === item.id
          );
          if (itemIndex === -1) {
            existingItems.push(item);
          } else {
            existingItems[itemIndex] = item;
          }
        });
  
        setFilterItems(existingItems);
      },
      [apiRef]
    );
  
    const applyFilter = useCallback(
      (item: GridFilterItem) => {
        upsertFilterItems([...filterItems, item]);
      },
      [filterItems, upsertFilterItems]
    );
  
    const handleSearch = useCallback(() => {
      if (onSearch) {
        onSearch({ ...filterModel, ...{ items: filterItems } }, apiRef);
        onClose();
      } else {
        apiRef.current.setFilterModel(
          { ...filterModel, ...{ items: filterItems } },
          "upsertFilterItems"
        );
        onClose();
      }
      setAdvanceSearch(true)
    }, [apiRef, filterModel, filterItems,setAdvanceSearch, onClose, onSearch]);
  
    return (
      <Sheet
        open={open}
        onClose={onClose}
       
      >
        <SheetTitle onClose={onClose}  title="Search" />
        <Box
      id="sheet-content"
      overflow="auto"
      pt={2}
      px={4}
    >
          {filterItems.length > 0 ? (
            <Form
              onClose={onClose}
              apiRef={apiRef}
              navigate={navigate}
              items={filterItems}
              applyFilter={applyFilter}
              {...formProps}
            />
          ) : (
            <>
              {Array(6)
                .fill(1)
                .map((card, index) => (
                  <Skeleton
                    key={index}
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                ))}
            </>
          )}
        </Box>
      <Box
      flex="0 0 auto"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={4}
    >
    <Button
      aria-controls="options-menu"
      aria-haspopup="true"
      onClick={() => {
        onClear();
        onClose();
      }}
      sx={{
        color: { sm: "white" },
        backgroundColor: { sm: grey[500] },
        "&:hover": { backgroundColor: { sm: grey[700] } },
      }}
      variant="contained"
    >
      Clear
    </Button>
        <Button variant="contained" onClick={handleSearch}>
          Submit
        </Button>

    </Box>
      </Sheet>
    );
  };
  
  interface AdvancedSearchInputBooleanProps extends GridFilterInputValueProps {
    currentColumn?: any;
    label?: string;
  }
  
  export function AdvancedSearchInputBoolean(
    props: AdvancedSearchInputBooleanProps
  ) {
    const {
      item,
      applyValue,
      apiRef,
      focusElementRef,
      label,
      currentColumn,
      ...others
    } = props;
    const [filterValueState, setFilterValueState] = useState(item.value || "");
  
    const onFilterChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterValueState(value);
        applyValue({ ...item, value });
      },
      [applyValue, item]
    );
  
    useEffect(() => {
      setFilterValueState(item.value || "");
    }, [item.value]);
  
    return (
      <TextField
        label={label}
        value={filterValueState}
        onChange={onFilterChange}
        select
        SelectProps={{
          displayEmpty: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={focusElementRef}
        {...others}
      >
        <MenuItem value="">Any</MenuItem>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    );
  }
  
  function getValueFromOption(option: ValueOptions | undefined) {
    if (typeof option === "object" && option !== null) {
      return option.value;
    }
    return option;
  }
  
  function getValueFromValueOptions(
    value: string,
    valueOptions?: ValueOptions[]
  ) {
    if (valueOptions === undefined) {
      return undefined;
    }
    const result = valueOptions.find((option) => {
      const optionValue = getValueFromOption(option);
      return String(optionValue) === String(value);
    });
    return getValueFromOption(result);
  }
  
  function AdvancedSearchSelect(props) {
    const {
      item,
      applyValue,
      type,
      apiRef,
      focusElementRef,
      label,
      currentColumn,
      ...others
    } = props;
    const [filterValueState, setFilterValueState] = useState(item.value ?? "");
    const id = useId();
  
    const currentValueOptions = useMemo(() => {
      if (currentColumn === null) {
        return undefined;
      }
      return typeof currentColumn.valueOptions === "function"
        ? currentColumn.valueOptions({ field: currentColumn.field })
        : currentColumn.valueOptions;
    }, [currentColumn]);
  
    const onFilterChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event.target.value;
  
        // NativeSelect casts the value to a string.
        value = getValueFromValueOptions(value, currentValueOptions);
  
        setFilterValueState(String(value));
        applyValue({ ...item, value });
      },
      [applyValue, item, currentValueOptions]
    );
  
    useEffect(() => {
      let itemValue;
  
      if (currentValueOptions !== undefined) {
        // sanitize if valueOptions are provided
        itemValue = getValueFromValueOptions(item.value, currentValueOptions);
        if (itemValue !== item.value) {
          applyValue({ ...item, value: itemValue });
          return;
        }
      } else {
        itemValue = item.value;
      }
  
      itemValue = itemValue ?? "";
  
      setFilterValueState(String(itemValue));
    }, [item, currentValueOptions, applyValue]);
  
    return (
      <TextField
        id={id}
        label={label}
        placeholder={label}
        value={filterValueState}
        onChange={onFilterChange}
        type={type || "text"}
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          displayEmpty: true,
        }}
        inputRef={focusElementRef}
        select
        fullWidth
        {...others}
      >
        {currentColumn
          ? // @ts-ignore
            currentColumn?.valueOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          : null}
      </TextField>
    );
  }
  
  function AdvancedSearchInputDate(props) {
    const {
      item,
      applyValue,
      type,
      apiRef,
      focusElementRef,
      InputProps,
      label,
      currentColumn,
      ...other
    } = props;
    const [filterValueState, setFilterValueState] = useState<any | null>(
      item.value || null
    );
    const globalConfig = useGlobalConfig();
  
    const onFilterChange = useCallback(
      (newValue) => {
        setFilterValueState(newValue);
        let value = newValue;
        try {
          value = format(newValue, "yyyy-MM-dd");
        } catch (e) {}
        applyValue({ ...item, value });
      },
      [applyValue, item]
    );
  
    return (
      <>
        {globalConfig ? (
          <AdvancedSearchDatePicker
            label={label}
            value={filterValueState}
            onChange={onFilterChange}
            inputFormat={globalConfig.date_format}
            InputProps={{
              ...InputProps,
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                sx={{
                  "& label": {
                    color: "white",
                    "&.Mui-focused": {
                      color: "white",
                    },
                  },
                }}
                {...params}
              />
            )}
            {...other}
          />
        ) : (
          <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
        )}
      </>
    );
  }
  
  export interface AdvancedSearchInputValueProps
    extends GridFilterInputValueProps {
    label?: string;
    currentColumn?: any;
  }
  
  function AdvancedSearchInputValue(props: AdvancedSearchInputValueProps) {
    const { item, applyValue, label, currentColumn, ...inputProps } = props;
    const inputRef: Ref<any> = useRef(null);
  
    const handleFilterChange = useCallback(
      (event) => {
        applyValue({ ...item, value: event.target.value });
      },
      [applyValue, item]
    );
  
    return (
      <TextField
        id={item.field}
        name={item.field}
        label={label}
        value={item.value}
        onChange={handleFilterChange}
        fullWidth
        inputRef={inputRef}
        {...inputProps}
        InputProps={{
          endAdornment: (
            <ClearButton
              value={item.value}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                  handleFilterChange({
                    target: { name: item.field, value: "" },
                  });
                }
              }}
            />
          ),
        }}
      />
    );
  }
  
  interface AdvancedSearchFormFieldProps extends GridFilterFormProps {
    apiRef: any;
    label?: string;
  }
  
  export const AdvancedSearchFormField = forwardRef<
    HTMLDivElement,
    AdvancedSearchFormFieldProps
  >(function GridFilterForm(props, ref) {
    const { apiRef, item, applyFilterChanges, valueInputProps = {}, label } = props;
    const { InputComponentProps } = valueInputProps;
    const currentColumn = item.field
      ? apiRef.current.getColumn(item.field)
      : null;
  
    const currentOperator = useMemo(() => {
      if (!item.operator || !currentColumn) {
        return null;
      }
  
      let co = currentColumn.filterOperators?.find(
        (operator) => operator.value === item.operator
      );
      if (co) {
        if (currentColumn.type === "string") {
          co.InputComponent = AdvancedSearchInputValue;
        } else if (currentColumn.type === "boolean") {
          co.InputComponent = AdvancedSearchInputBoolean;
        } else if (currentColumn.type === "singleSelect") {
          co.InputComponent = AdvancedSearchSelect;
        } else if (currentColumn.type === "date") {
          co.InputComponent = AdvancedSearchInputDate;
        }
      }
      return co;
    }, [item, currentColumn]);
  
    return (
      <>
        {currentOperator?.InputComponent ? (
          <currentOperator.InputComponent
            item={item}
            applyValue={applyFilterChanges}
            label={label ? label : currentColumn.headerName}
            currentColumn={currentColumn}
            {...currentOperator.InputComponentProps}
            {...InputComponentProps}
          />
        ) : null}
      </>
    );
  });
  
  export const AdvancedSearchForm2 = (props: AdvancedSearchFormProps) => {
    const { apiRef, applyFilter, items } = props;
    const filterModel = gridFilterModelSelector(apiRef);
    const lastFilterRef = useRef<any>(null);
  
    return <>
      {items.map((item, index) => (
        <AdvancedSearchFormField
          apiRef={apiRef}
          key={item.id == null ? index : item.id}
          item={item}
          applyFilterChanges={applyFilter}
          deleteFilter={() => {}}
          hasMultipleFilters={true}
          showMultiFilterOperators={index > 0}
          multiFilterOperator={filterModel.logicOperator}
          disableMultiFilterOperator={index !== 1}
          applyMultiFilterOperatorChanges={() => {}}
          focusElementRef={index === items.length - 1 ? lastFilterRef : null}
        />
      ))}
    </>;
  };
  