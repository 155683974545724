import { Box, Button } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";

import { axios } from "../api";
import { CancelButton } from "../components/Buttons";
import { handleAPIErrors } from "../utils/FormikErrors";

import type { AccountType, CompanyV6 } from "./types";

const AccountSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email"),
});

interface FormikAddAccountProps {
  setValue: (value: CompanyV6 | "") => void;
  accountType: AccountType;
}

export const FormikAddAccount: React.FC<FormikAddAccountProps> = ({
  setValue,
  accountType,
}) => {
  return (
    <Formik
      initialValues={{ name: "", email: "", type: accountType }}
      validationSchema={AccountSchema}
      onSubmit={async (values, { setErrors }) => {
        try {
          const resp = await axios.post<CompanyV6>("/v6/companies", values);
          enqueueSnackbar("Successfully created account", {
            variant: "success",
          });
          setValue(resp.data);
        } catch (err) {
          handleAPIErrors(err, setErrors);
          enqueueSnackbar("Error creating account", {
            variant: "error",
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        // this is intended to be nested in an existing form so we use a div here, forms in forms are invalid html
        <div data-testid="add-account-form">
          <Field type="hidden" name="type" />
          <Field
            required
            fullWidth
            component={TextField}
            name="name"
            type="text"
            label="Account Name"
          />
          <Field
            required
            fullWidth
            component={TextField}
            name="email"
            type="text"
            label="Email"
          />
          <Box
            flex="0 0 auto"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            p={1}
          >
            <CancelButton onClick={() => setValue("")} />
            <Button
              disabled={isSubmitting}
              // @ts-ignore
              onClick={(event) => handleSubmit(event)}
            >
              Add
            </Button>
          </Box>
        </div>
      )}
    </Formik>
  );
};
