export function handleFormErrors(error: any, setErrors: (errors) => void) {
  if (Array.isArray(error.response.data.detail)) {
    console.log(error.response.data.detail);
    const errors = {};
    for (let e of error.response.data.detail) {
      const [, field] = e.loc;
      if (field !== "__root__") {
        errors[field] = e.msg;
      }
    }
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
    }
  }
}

export function handleAPIErrors(error: any, setErrors: (errors) => void) {
  if (
    error.response &&
    error.response.data &&
    Object.prototype.hasOwnProperty.call(error.response.data, "detail") &&
    error.response.data.detail
  ) {
    handleFormErrors(error, setErrors);
  }
}
