import { createContext, lazy, useState } from "react";

import type { Feature, IFeatureContext } from "./types";

const Companies = lazy(() => import("../companies/CompanyList"));
const Contacts = lazy(() => import("../contacts/ContactList"));
const Documents = lazy(() => import("../documents/Documents"));
const Flightplan = lazy(() => import("../flightplan/Flightplan"));
const Inventory = lazy(() => import("../inventory/Inventory"));
const Jobs = lazy(() => import("../jobs/JobList"));
const Mitcom = lazy(() => import("../mitcom/Mitcom"));
const Orders = lazy(() => import("../orders/Orders"));
const Parts = lazy(() => import("../parts/Parts"));
const Quotes = lazy(() => import("../quotes/Quotes"));
const Tools = lazy(() => import("../tools/Tools"));
const UserPreferences = lazy(() => import("../users/UserPreferences"));
const UserManagement = lazy(() => import("../users/user-management/UserManagementList"));
const Settings = lazy(() => import("./Settings"));
const Dashboard = lazy(() => import("../dashboard/Dashboard"));

// preProd is all environment except test and prod
// const preProd = (env: string) => env !== "test" && env !== "prod";
// const reactEnv: string = process.env.REACT_APP_ENV as string;
// const isPreProd = preProd(reactEnv);

const initialFeatures: Feature[] = [
  { name: "contacts", component: Contacts, isActive: true },
  { name: "AddressBook", component: Companies, isActive: true },
  { name: "dashboard", component: Dashboard, isActive: true },
  {
    name: "inventory",
    component: Inventory,
    responsiveComponent: Parts,
    isActive: true,
  },
  {
    name: "jobs",
    component: Jobs,
    isActive: true,
  },
  {
    name: "mitcom",
    component: Mitcom,
    isActive: true,
  },
  { name: "user_management", component: UserManagement, isActive: true },
  { name: "users_prefs", component: UserPreferences, isActive: true },
  {
    name: "user_settings",
    component: Settings,
    isActive: true,
  },
  {
    name: "flightplan",
    component: Flightplan,
    isActive: true,
  },
  {
    name: "tools",
    component: Tools,
    isActive: true,
  },
  {
    name: "quotes",
    component: Quotes,
    isActive: true,
  },
  {
    name: "orders",
    component: Orders,
    isActive: true,
  },
  {
    name: "documents",
    component: Documents,
    isActive: true
  },
];

export const FeatureContext = createContext<IFeatureContext>(
  {} as IFeatureContext
);

export const FeatureProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [features] = useState<Feature[]>(initialFeatures);

  const getFeature = (featureName: string): Feature | undefined => {
    return features.find((i) => i.name === featureName);
  };

  const isFeatureActive = (featureName: string): boolean => {
    const feature = getFeature(featureName);
    const isActive = !!feature && feature.isActive;
    console.debug(`${featureName} ${isActive ? "is active" : "is disabled"}`);
    return isActive;
  };

  return (
    <FeatureContext.Provider value={{ features, isFeatureActive, getFeature }}>
      {children}
    </FeatureContext.Provider>
  );
};
