import { CacheAxiosResponse } from "axios-cache-interceptor";

import { axios } from "../api";

import type { AeronetV6Response, Currency } from "../types";

/** Returns the currencies */
export function getCurrencies(): Promise<
  CacheAxiosResponse<AeronetV6Response<Currency>>
> {
  return axios.get<AeronetV6Response<Currency>>("/v6/currency");
}
