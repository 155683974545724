import { useMemo } from "react";

import { ValueOptions } from "@mui/x-data-grid-pro";
import { useGlobalConfig } from "./useGlobalConfig";

import { useTypes } from "./useTypes";

export function useDivisionOptions() {
  const globalConfig = useGlobalConfig();

  const divisions = useTypes(globalConfig?.division_parent_id);
  
  const divisionOptions: ValueOptions[] | undefined = useMemo(() => {
    if (divisions) {
      const l = [{ value: "", label: "Any" }];
      const lo = divisions?.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      }));
      l.push(...lo);
      return l;
    }
  }, [divisions]);

  return divisionOptions;
}
