import { useMemo } from "react";

import { useGlobalConfig } from "./useGlobalConfig";
import { getCurrencies } from "../currency/currency";
import { useQuery } from "../api";

interface ExchangeRates {
    [key: string]: string;
}

export function useExchangeRates(): ExchangeRates | undefined {
  const globalConfig = useGlobalConfig();
  const [currency] = useQuery(getCurrencies);

  const exchangeRates = useMemo(() => {
    if (currency) {
      var exchanges = {};
      for (var i = 0; i < currency.data.length; i++) {
        exchanges[currency.data[i].currency_short] = currency.data[
          i
        ].exchange.toFixed(globalConfig?.exchange_rounding ?? 4);
      }
      return exchanges;
    }
  }, [currency, globalConfig]);

  return exchangeRates;
}
