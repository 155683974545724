import { useEffect, useRef, useState, useMemo } from "react";

import { AxiosResponse } from "axios";

import { MenuItem, Skeleton } from "@mui/material";
import { Select, TextField } from "formik-mui";
import { DatePicker } from "formik-mui-x-date-pickers";
import { Field, Form, Formik, FormikValues } from "formik";

import * as Yup from "yup";
import { parse } from "date-fns";
import { format } from "date-fns-tz";
import queryString from "query-string";
import { useSnackbar } from "notistack";

import { axios } from "../api";
import Sheet, {
  SheetActions,
  SheetContent,
  SheetHeader,
} from "../components/Sheet";
import LoadingSpinner from "../components/Loading";
import { ActionButton, CancelButton } from "../components/Buttons";

import { useConfig } from "../hooks/useConfig";
import PartActionHeader from "./PartActionHeader";
import { handleAPIErrors } from "../utils/FormikErrors";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useGlobalConfig } from "../hooks/useGlobalConfig";

import { Quote } from "../quotes/types";
import type { NestedPart } from "./types";
import { QuoteLineList } from "../quotes/types";
import { AeronetV6ItemsResponse } from "../types";
import { IAircraftSelect } from "../aircraft/types";

import { JobAutocomplete } from "../jobs/FormikJobAutocomplete";
import FormikTaskAutoComplete from "../jobs/FormikTaskAutoComplete";
import { UserAutocomplete } from "../users/user-management/UserAutocomplete";
import { PartAutocomplete } from "./PartAutocomplete";

export interface RaiseDemandProps {
  open: boolean;
  jobId?: string | number;
  part?: NestedPart;
  raiseDemandType?: RaiseDemandTypeEnum;
  quoteLineList?: QuoteLineList;
  quote?: Quote;
  onClose: () => void;
  onSave?: () => void;
  isPartsOrders? : boolean;
  isTask?:boolean;
}

export enum RaiseDemandTypeEnum {
  FROM_QUOTES = "FROM_QUOTES",
  FROM_JOBS = "FROM_JOBS",
  FROM_INVENTORY = "FROM_INVENTORY",
}

const RaiseDemand: React.FC<RaiseDemandProps> = (props) => {
  const {
    open,
    part,
    raiseDemandType,
    quoteLineList,
    quote,
    jobId,
    onClose,
    onSave,
    isPartsOrders,
    isTask=true
  } = props;
  const formRef = useRef<FormikValues>();
  const { enqueueSnackbar } = useSnackbar();
    const handleAddDemand = () => { 
      if(isPartsOrders && !jobId){
        enqueueSnackbar("Please create Parts Order First!", {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
         return;
      }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Sheet open={open} onClose={onClose}>
      <SheetHeader onClose={onClose} title="Raise Demand">
        <PartActionHeader part={part} />
      </SheetHeader>
      <SheetContent>
        <RaiseDemandForm
          partId={part?.id ?? 0}
          jobId={jobId}
          quoteLineList={quoteLineList}
          formRef={formRef}
          raiseDemandType={raiseDemandType}
          quote={quote}
          onClose={onClose}
          onSave={onSave}
          isTask={isTask}
        />
      </SheetContent>
      <SheetActions>
        <CancelButton onClick={onClose} />
        <ActionButton children={"Add Demand"} onClick={handleAddDemand} />
      </SheetActions>
    </Sheet>
  );
};

interface RaiseDemandFormProps {
  partId: number;
  formRef: any;
  quote?: Quote;
  quoteLineList: QuoteLineList | undefined;
  raiseDemandType: RaiseDemandTypeEnum | undefined;
  jobId?: string | number;
  onClose: () => void | boolean;
  onSave?: () => void | boolean;
  isPartsOrders?:void | boolean;
  isTask?: boolean;
}

export const RaiseDemandForm = (props: RaiseDemandFormProps) => {
  const {
    formRef,
    partId,
    raiseDemandType,
    quoteLineList,
    quote,
    jobId,
    onClose,
    onSave,
    isTask
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const config = useConfig("jobs");
  const globalConfig = useGlobalConfig();
  const [aircraft, setAircraft] = useState<ReadonlyArray<IAircraftSelect>>([]);
  const currentUser = useCurrentUser();
  const [uom, setUOM] = useState<string[]>([]);
  const partsConfig = useConfig("parts");

  /** Get a list of aircraft for the aircraft select. */
  useEffect(() => {
    let active = true;

    async function getAircraft() {
      const params = {
        filter: [
          JSON.stringify({ field: "active", value: true }),
          JSON.stringify({ field: "registration", op: "!=", value: null }),
          JSON.stringify({ field: "registration", op: "!=", value: "" }),
        ],
        sort: "registration",
        size: 100,
      };

      try {
        const resp: AxiosResponse<AeronetV6ItemsResponse<IAircraftSelect>> =
          await axios.get("/v6/aircraft", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
          });
        if (active) {
          setAircraft(resp.data.items);
        }
      } catch (e) {
        enqueueSnackbar("Error loading aircraft", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        throw e;
      }
    }

    getAircraft();

    return () => {
      active = false;
    };
  }, [enqueueSnackbar]);

  const RaiseDemandSchema = useMemo(() => {
    return Yup.object().shape({
      part_id: Yup.string().notOneOf(["0"]).required("Required"),
      job_id: Yup.string().required("Required"),
      job: Yup.object().nullable().required("Required"),
      quantity: Yup.number().when([], () => {
        return raiseDemandType !== RaiseDemandTypeEnum.FROM_QUOTES
          ? Yup.number().positive().required("Required")
          : Yup.number().notRequired();
      }),
      date_required: Yup.date().min(new Date()).required("Required"),
      priority: Yup.string().required("Required"),
    });
  }, [raiseDemandType]);

  const initialDemand = useMemo(() => {
    return {
      part_id: partId,
      job: {
        id: 0,
      },
      job_id: jobId,
      quantity:
        quoteLineList && quoteLineList.quantity ? quoteLineList.quantity : "",
      priority:
        raiseDemandType === RaiseDemandTypeEnum.FROM_QUOTES && config
          ? config.default_demand_priority
          : "",
      date_required:
        quoteLineList && quoteLineList.date_required
          ? parse(quoteLineList.date_required, "yyyy-MM-dd", new Date())
          : new Date(),
      status: "",
      raised_by: currentUser ?? "",
      date_raised: new Date(),
      note: "",
      task: "",
      task_id: "",
      cost: quoteLineList && quoteLineList.price ? quoteLineList.price : "",
      lead_time: quoteLineList && quoteLineList.lead_time ? quoteLineList.lead_time : 0,
      lead_time_text: quoteLineList && quoteLineList.lead_time_text ? quoteLineList.lead_time_text : "",
      part: {
        id: 0,
      },
    };
  }, [partId, jobId, quoteLineList, raiseDemandType, config, currentUser]);
  /** Set UOM and status from config. */
  useMemo(() => {
    if (partsConfig) {
      setUOM(partsConfig.units_list);
    }
  }, [partsConfig]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialDemand}
      innerRef={formRef}
      validationSchema={RaiseDemandSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const method = "post";
        const url = `/v6/quotes/raise_demand`;
        const { job, task, ...data } = values;
        // @ts-ignore
        if (raiseDemandType === RaiseDemandTypeEnum.FROM_JOBS) {
          data.part_id = values?.part?.id;
        } else {
          data.job_id = job?.id;
        }
        // @ts-ignore
        data.date_required = format(values.date_required, "yyyy-MM-dd");
        // @ts-ignore
        data.date_raised = format(values.date_raised, "yyyy-MM-dd");
        // @ts-ignore
        data.raised_by = data.raised_by?.id;
        axios({
          method: method,
          url: url,
          data: data,
        })
          .then(() => {
            setSubmitting(false);
            enqueueSnackbar("Demand raised successfully", {
              variant: "success",
            });
            onSave?.();
            onClose();
          })
          .catch((error) => {
            values.job = job; // Put the job back so the form will render correctly
            values.task = task; // Put the job back so the form will render correctly
            handleAPIErrors(error, setErrors);
            setSubmitting(false);
            enqueueSnackbar("Error raising demand", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          });
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleBlur }) => (
        <Form>
          <Field name="item_id" type="hidden" />
          <Field name="job_id" type="hidden" />
          {raiseDemandType === RaiseDemandTypeEnum.FROM_JOBS ? (
            <Field
              required
              id="part"
              name="part"
              component={PartAutocomplete}
            />
          ) : (
            <Field
              id="raise-demand-job-autocomplete"
              name="job"
              component={JobAutocomplete}
              status={[1, 2]}
              quote={quote}
              aircraft={aircraft}
            />
          )}
          {raiseDemandType !== RaiseDemandTypeEnum.FROM_QUOTES ? (
            <>
              {globalConfig ? (
                <div style={{ display: "flex", width: "100%" }}>
                  {/* Quantity Field */}
                  <Field
                    required
                    component={TextField}
                    name="quantity"
                    label="Quantity"
                    inputProps={{ inputMode: "decimal", min: 0 }}
                    onBlur={(event) => {
                      const value = Number(values.quantity);
                      const formatted = value.toFixed(globalConfig.quantity_rounding);
                      setFieldValue("quantity", formatted);
                      handleBlur(event);
                    }}
                    sx={{ flex: 1, marginRight: "1rem" }} // Apply 50% width with spacing
                  />

                  {/* UOM Field */}
                  <div style={{ flex: 1 }}>
                    {uom.length ? (
                      <Field
                        component={Select}
                        name="uom"
                        id="uom"
                        label="UOM"
                        sx={{ width: "100%" }} // Ensures full width within the container
                        formControl={{
                          fullWidth: true,
                        }}
                      >
                        <MenuItem value="" key={0} disabled>
                          UOM
                        </MenuItem>
                        {uom.map((unit: string, index) => (
                          <MenuItem value={unit} key={index + 1}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Field>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
            </>
          ) : (
            <></>
          )}
          {config ? (
            <Field
              required
              component={Select}
              id="priority"
              name="priority"
              label="Priority *"
            >
              {Object.entries(config.demand_priority_list).map(
                ([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value as unknown as string}
                  </MenuItem>
                )
              )}
            </Field>
          ) : (
            <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
          )}
          {[
            RaiseDemandTypeEnum.FROM_QUOTES,
            RaiseDemandTypeEnum.FROM_JOBS,
            RaiseDemandTypeEnum.FROM_INVENTORY,
          ].includes(raiseDemandType!) ? (
            <>
              {config ? (
                <Field
                  required
                  component={Select}
                  id="status"
                  name="status"
                  label="Status"
                >
                  {Object.entries(config.demand_status_list).map(
                    ([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value as unknown as string}
                      </MenuItem>
                    )
                  )}
                </Field>
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
              {isTask && <>
              <Field name="task_id" type="hidden" />
              <Field
                id="task"
                name="task"
                component={FormikTaskAutoComplete}
                label="Task"
              />
              <Field
                component={UserAutocomplete}
                label="Raised By"
                name="raised_by"
                id="raised_by"
                companyId={""}
              />
              </>}
              {globalConfig ? (
                <Field
                  required
                  fullWidth
                  component={DatePicker}
                  disablePast={true}
                  name="date_raised"
                  id="date_raised"
                  inputFormat={globalConfig?.date_format}
                  label="Date Raised"
                />
              ) : (
                <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
              )}
            </>
          ) : null}
          <Field
            fullWidth
            multiline
            rows={3}
            component={TextField}
            name="note"
            id="note"
            type="text"
            label="Notes"
          />
          <br />
          {isSubmitting && <LoadingSpinner />}
        </Form>
      )}
    </Formik>
  );
};

export default RaiseDemand;
