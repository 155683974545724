import {
  createElement,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";

interface SearchFieldProps {
  clearSearch: () => void;
  onChange: (e) => void;
  value: string;
  items?: { [key: string]: unknown }[];
}

export function SearchField(props: SearchFieldProps) {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchField, setSearchField] = useState("q");
  const [operator, setOperatorValue] = useState("==");
  const open = Boolean(anchorEl);
  const [menuItems, setMenuItems] = useState<ReactElement[]>([]);

  const onClear = useCallback(() => {
    setSearchField("q");
    props.clearSearch();
  }, [props]);

  const handleItemClick = useCallback(
    (value: string, op: string) => {
      setAnchorEl(null);
      setSearchField(value);
      setOperatorValue(op);
      props.clearSearch();
    },
    [props]
  );

  useEffect(() => {
    if (props.items) {
      const menus = props.items.map((item, index) => {
        const selected = searchField === item.field;
        const menuItem = createElement(
          MenuItem,
          {
            "aria-selected": selected ? "true" : undefined,
            selected,
            onClick: () =>
              handleItemClick(
                item.field as string,
                item.operator as string
              ),
            // @ts-ignore
            "data-value": item.field,
            "data-value-readable": item.label,
            key: index,
          },
          item.label as string
        );
        return menuItem;
      });
      setMenuItems(menus);
    }
  }, [props.items, searchField, handleItemClick]);

  const onChange = (e) => {
    props.onChange(e);
    apiRef.current.setFilterModel({
      items: [
        {
          field: searchField,
          operator: operator,
          value: e.target.value,
        },
      ],
    });
  };

  const displayName = (value: any) =>
    menuItems.find((item) => item!.props["data-value"] === value)!.props[
      "data-value-readable"
    ];

  return (
    <TextField
      value={props.value}
      onChange={onChange}
      placeholder="Find…"
      sx={menuItems.length > 0 ? { minWidth: 330 } : undefined}
      InputProps={{
        startAdornment: (
          <>
            <IconButton
              id="search-button"
              aria-controls={open ? "search-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              color="secondary"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {searchField === "q" ? (
                <SearchIcon sx={{ m: 0 }} fontSize="small" />
              ) : (
                <Typography sx={{ fontSize: "small" }}>
                  {displayName(searchField)}
                </Typography>
              )}
              {menuItems.length > 0 ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : null}
            </IconButton>
            <Menu
              id="search-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "search-button",
              }}
            >
              {menuItems}
            </Menu>
          </>
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            sx={{
              visibility:
                props.value || searchField !== "q" ? "visible" : "hidden",
            }}
            onClick={onClear}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
}
