import { AxiosRequestConfig } from "axios";
import { _ConfigService } from "./ConfigService";

/**
 * Converts C standard format codes used by Roxen to Unicode Tokens used by date-fns
 *
 * Only the tokens commonly used in Roxen are converted:
 * %b - Month as locale’s abbreviated name. Jan, Feb, …, Dec (en_US)
 * %d - Day of the month as a zero-padded decimal number
 * %m - Month as a zero-padded decimal number
 * %y - Year without century as a zero-padded decimal number
 * %Y - Year with century as a decimal number
 *
 * @see {@link https://docs.python.org/3/library/datetime.html#strftime-and-strptime-format-codes}
 * @see {@link https://date-fns.org/v2.28.0/docs/format}
 *
 * @example
 * // returns "dd/MM/yyyy"
 * convertStrftimeToUnicodeToken("%d/%m/%Y")
 *
 * @param {string} strftimeToken C standard format string
 * @returns {string} Unicode token format string
 */
export const convertStrftimeToUnicodeToken = (
  strftimeToken: string
): string => {
  var mapObj = {
    "%b": "MMM",
    "%d": "dd",
    "%m": "MM",
    "%y": "yy",
    "%Y": "yyyy",
  };

  function replaceStrftime(match, p1, offset, string) {
    if (match in mapObj) {
      return mapObj[match];
    }
    console.error("Unrecognised date format - No match for " + match);
    return match;
  }

  let re = /(%.)/g;
  return strftimeToken.replace(re, replaceStrftime);
};

class _GlobalConfigService extends _ConfigService {
  // @ts-ignore
  public async query(options?: AxiosRequestConfig): Promise<{ [key: string]: any }> {
    const config: { [key: string]: string } = {};

    // get the config iterator
    const rawConfigs = this.getConfig("global", "", options);

    // loop through one config at a time
    for await (const configObj of rawConfigs) {
      // transform a config object into a simple dict of config_variable: config_value

      if (configObj.config_variable === "date_format") {
        config[configObj.config_variable] = convertStrftimeToUnicodeToken(
          configObj.config_value
        );
      } else {
        config[configObj.config_variable] = configObj.config_value;
      }
    }

    return config;
  }
}

// Singleton example from stackoverflow  https://stackoverflow.com/a/36978360/2167749
class GlobalConfig {
  private static _instance: GlobalConfig;
  // @ts-ignore
  private data: { [key: string]: any }; // store data in here

  private constructor() {
    // this.getConfig();  // cant do this as it runs on import before Axios is set up in api.ts
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async get(config?: AxiosRequestConfig): Promise<{ [key: string]: any }> {
    if (!this.data) {
      let GlobalConfigService = new _GlobalConfigService();
      this.data = await GlobalConfigService.query(config);
    }

    return this.data;
  }
}

const instance = GlobalConfig.Instance;
export default instance;
