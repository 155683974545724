import { CacheAxiosResponse } from "axios-cache-interceptor";

import { axios } from "../api";

import type { AircraftType } from "../aircraft/types";
import type { AeronetV6Response } from "../types";

/** Returns the actypes */
export function getAircraftTypes(): Promise<
  CacheAxiosResponse<AeronetV6Response<AircraftType>>
> {
  return axios.get<AeronetV6Response<AircraftType>>("/v6/parts/actypes");
}
