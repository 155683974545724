import { Box, Button, Link as MaterialLink } from "@mui/material";
import { Link } from "react-router-dom";

interface CustomSnackbarContentProps {
  message: string | JSX.Element;
  uri?: string;
  external?: Boolean;
}

const CustomSnackbarContent: React.FC<CustomSnackbarContentProps> = (props) => {
  const { message, uri, external = false } = props;

  return (
    <Box component="span" my={-1}>
      <Box component="span" paddingRight={1}>
        {message}
      </Box>
      {uri ? (
        external ? (
          <Button
            component={MaterialLink}
            rel="noopener"
            href={uri}
            sx={{ color: "white" }}
            target="_blank"
          >
            View
          </Button>
        ) : (
          <Button component={Link} to={uri} sx={{ color: "white" }}>
            View
          </Button>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CustomSnackbarContent;
