import { useCallback, useMemo } from "react";

// import { Link as MUILink } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridSortItem,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { AxiosResponse } from "axios";
import { CacheRequestConfig } from "axios-cache-interceptor";
import { useSnackbar } from "notistack";
import queryString from "query-string";

// import { createSearchParams } from "react-router-dom";
import { axios } from "../../../api";
import AeronetDataGrid, {
  QueryResults,
} from "../../../components/datagrid/DataGrid";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useGlobalRounding } from "../../../hooks/useGlobalRounding";
import { useLocationOptions } from "../../../hooks/useLocationOptions";
import { usePartsConditionOptions } from "../../../hooks/usePartsConditionOptions";
import { usePartsStoreOptions } from "../../../hooks/usePartsStoreOptions";
// import { useRenderLink } from "../../../hooks/useRenderLink";
import { isPart } from "../../../parts/utils";
import { AeronetDetailViewProps, AeronetV6ItemsResponse } from "../../../types";

import type { IPartDetail, IPartList } from "../../../parts/types";
import type { ItemList } from "../../types";

interface ItemsProps   extends AeronetDetailViewProps {
  config: { [key: string]: any };
  part: Partial<IPartDetail>;
}

const Items: React.FC<ItemsProps> = (props: ItemsProps) => {
  const { config, part } = props;

  const { enqueueSnackbar } = useSnackbar();
  const conditionOptions = usePartsConditionOptions(config);
  const currentUser = useCurrentUser();
  const globalRounding = useGlobalRounding();
  const locationOptions = useLocationOptions();
  // const renderCellLink = useRenderLink();
  const storeOptions = usePartsStoreOptions(config);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Component ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Serial Number",
        field: "serial_number",
        flex: 1,
      },
      {
        headerName: "Condition",
        field: "condition",
        flex: 1,
        type: "singleSelect",
        valueOptions: conditionOptions,
        valueFormatter: (params: GridValueFormatterParams) => {
          try {
            return config.items_condition[params.value as string];
          } catch {
            return params.value;
          }
        },
      },
     
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        type: "singleSelect",
        valueFormatter: (params: GridValueFormatterParams) => {
          try {
            return config.status_list[
              Number(params.value as number)
            ].toUpperCase();
          } catch {
            return params.value;
          }
        },
      },
     
      {
        headerName: "Quantity",
        field: "quantity",
        // type: "number",
        flex: 0.75,
        
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
      },
      {
        headerName: "Unit",
        field: "uom",
        type: "string",
        flex: 0.75,
      },
      {
        headerName: "Location",
        field: "location",
        flex: 1,
        type: "singleSelect",
        valueOptions: locationOptions,
      },
      {
        headerName: "Store",
        field: "store",
        flex: 1,
        type: "singleSelect",
        valueOptions: storeOptions,
      },
      {
        headerName: "Shelf",
        field: "shelf",
        flex: 1,
      },
      {
        headerName: "Notes",
        field: "notes",
        flex: 1,
        valueGetter: (params) => params.row?.order_detail?.notes,
      },
    ],
    [
      conditionOptions,
      config,
      globalRounding,
      locationOptions,
      // originalUrl,
      // renderCellLink,
      storeOptions,
    ]
  );

  const getData = useCallback(
    async (params: any, axiosConfig?: CacheRequestConfig) => {
      params = {
        ...params,
        ...{
          filter: [
            JSON.stringify({
              model: "Part",
              field: "id",
              value: part.id,
            }),
            JSON.stringify({
              model: "Item",
              field: "status",
              op: "in",
              value: [
                "7", //flyinh
                "16", //removed
                "17", //rotable
                "18" //retired
              ],
            }),
          ],
        },
      };

      // Call items api
      try {
        const resp: AxiosResponse<AeronetV6ItemsResponse<ItemList>> =
          await axios.get("/v6/items", {
            id: `get-part-${part.id}-items`,
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
            ...axiosConfig,
          });

        return {
          total: resp.data.total,
          rows: resp.data.items,
        } as QueryResults<ItemList>;
      } catch (e) {
        // @ts-ignore
        if (!axios.isCancel(e)) {
          enqueueSnackbar("Error fetching items", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
        throw e;
      }
    },
    [enqueueSnackbar, part.id]
  );

  const initialSort: GridSortItem[] = useMemo(
    () => [{ field: "id", sort: "desc" }],
    []
  );

  if (!isPart(part as IPartList)) {
    return (
      <DataGridPro
        autoHeight
        density="compact"
        columns={columns}
        rows={[]}
        sx={{ flex: 1 }}
        hideFooter
      />
    );
  } else {
    return (
      <AeronetDataGrid
        checkboxSelection={false}
        columns={columns}
        components={{
          toolbar: null,
        }}
        currentUserId={currentUser?.id}
        data={getData}
        enableAdvancedSearch={false}
        initialSort={initialSort}
        initialState={{
          columns: {
            columnVisibilityModel: {},
          },
        }}
        name="part-items-data-grid"
        key={`${part.id}-items-data-grid`}
        exportFileName={`${part.number} - items`}
        enableCreate={false}
      />
    );
  }
};

export default Items;
