import { PropsWithChildren } from "react";
import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

export interface AlertDialogProps {
  open: boolean;
  title: string;
  description?: string;
  handleClose: (event: any) => any;
}

const AlertDialog: React.FC<React.PropsWithChildren<AlertDialogProps>> = (
  props: PropsWithChildren<AlertDialogProps>
) => {
  const { open, title, description, handleClose, children } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3" color="secondary" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </DialogTitle>
      {description ? (
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Typography>{description}</Typography>
          </DialogContentText>
        </DialogContent>
      ) : (
        <></>
      )}
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
