import { ValueOptions } from "@mui/x-data-grid-pro";
import { useMemo } from "react";

export function usePartsConditionOptions(config?: { [key: string]: any }): ValueOptions[] | undefined {
  const conditionOptions: ValueOptions[] | undefined = useMemo(() => {
    if (config && config.items_condition) {
      const conditions: ValueOptions[] = [{ value: "", label: "Any" }];
      Object.keys(config?.items_condition).forEach((key) => {
        conditions.push({
          value: key,
          label: config?.items_condition[key],
        });
      });
      return conditions;
    }
  }, [config]);
  return conditionOptions;
}
