import { Dispatch, SetStateAction, useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListSubheader,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import {
  GridCsvExportMenuItem,
  GridFilterItem,
  GridPrintExportMenuItem,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarFilterButton,
  gridFilterModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";

import { CreateFab, SearchButton, SearchFab } from "../Buttons";
import { ActionMenu, ActionMenuItem } from "./ActionMenu";
import { ActionType, Actions } from "./DataGrid";
import { DisplayPreferences } from "./DisplayPreferences";
import { GridToolbarExportContainer } from "./GridToolbarExport";
import { SearchField } from "./SearchField";

export const GridToolbarExport = ({
  csvOptions,
  printOptions,
  setAction,
  ...other
}) => (
  <GridToolbarExportContainer {...other}>
        <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Export</b></ListSubheader>
        <GridCsvExportMenuItem options={csvOptions} />
       <GridPrintExportMenuItem options={printOptions} />
       <Divider />
       <ListSubheader  style={{color:"black" ,fontSize:"1rem",fontWeight:"bold"}}><b>Settings</b></ListSubheader>
        <MenuItem
      onClick={() => {
        setAction(Actions.Settings);
      }}
    >
          Display
        </MenuItem>
  </GridToolbarExportContainer>
);

export interface AdvancedSearchToolbarProps extends GridToolbarContainerProps {
  action: ActionType;
  actions?: ActionMenuItem[];
  clearSearch: () => void;
  csvOptions: any;
  defaultItems: GridFilterItem[];
  enableAdvancedSearch?: boolean;
  enableCreate?: boolean;
  enableDelete?: boolean;
  enableFilter?: boolean;
  exportState: () => void;
  onChange: () => void;
  onChangeSearchField: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onDelete?: (GridRowSelectionModel) => void; // TODO generic
  printOptions: any;
  quickSearchItems?: { [key: string]: unknown }[];
  selectionModel: GridRowSelectionModel;
  setAction: (action: string) => void;
  setSelected?: (arg0: any) => void; // TODO generic
  setSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  value: string;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

export function AdvancedSearchToolbar(props: AdvancedSearchToolbarProps) {
  const {
    action,
    actions,
    clearSearch,
    csvOptions,
    defaultItems,
    enableAdvancedSearch,
    enableCreate,
    enableDelete,
    enableFilter,
    exportState,
    onChange,
    onDelete,
    printOptions,
    quickSearchItems,
    selectionModel,
    setAction,
    setSelected,
    setSelectionModel,
    setFilterButtonEl,
    value,
    ...other
  } = props;
  let navigate = useNavigate();
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const filterModel = gridFilterModelSelector(apiRef);
  const [filtersActive, setFiltersActive] = useState(false);

  useEffect(() => {
    if (JSON.stringify(defaultItems) === JSON.stringify(filterModel.items)) {
      setFiltersActive(false);
    } else {
      setFiltersActive(true);
    }
  }, [defaultItems, filterModel]);

  return (
    <GridToolbarContainer
      sx={{
        padding: (theme) => ({
          xs: theme.spacing(0.5, 1, 1),
          md: theme.spacing(1.5, 6, 2),
        }),
      }}
      {...other}
    >
      <>
        {selectionModel && selectionModel.length > 0 && onDelete && enableDelete ? (
          <IconButton
            aria-label="delete"
            onClick={() => {
              onDelete(selectionModel);
              setSelectionModel([]);
              for (const rowId of selectionModel) {
                apiRef.current.updateRows([{ id: rowId, _action: "delete" }]);
              }
            }}
            sx={{ml: 1.5}}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <>
            {enableCreate ? (
              <CreateFab onClick={() => navigate("create")} size="small" />
              ) : (
                <></>
            )}
          </>
        )}
        <ActionMenu
          actions={actions}
          setAction={setAction}
          setSelected={setSelected}
          setSelectionModel={setSelectionModel}
        />
        <SearchField
          value={value}
          onChange={onChange}
          clearSearch={clearSearch}
          items={quickSearchItems}
        />
        <Box sx={{ flex: 1 }} />
        <>
          {isSmall ? (
            <>
              {enableAdvancedSearch ? (
                <SearchFab
                  onClick={() => {
                    props.setAction(Actions.AdvancedSearch);
                  }}
                />
              ) : null}
            </>
          ) : (
            <>
              {enableAdvancedSearch ? (
                <>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!filtersActive}
                  >
                    <SearchButton
                      onClick={() => {
                        props.setAction(Actions.AdvancedSearch);
                      }}
                    />
                  </Badge>
                </>
              ) : (
                <></>
              )}
              {enableFilter ? (
                <GridToolbarFilterButton sx={{ color: grey[500] }} ref={setFilterButtonEl} />
              ) : (
                <></>
              )}
              <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions} setAction={setAction} {...other} />
            </>
          )}
        </>
        {action === Actions.Settings ? (
          <DisplayPreferences
            onSubmit={exportState}
            open={true}
            onClose={() => {
              setAction(Actions.None);
            }}
          />
        ) : (
          <></>
        )}
      </>
    </GridToolbarContainer>
  );
}
