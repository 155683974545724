import {  Divider, Grid, Typography } from "@mui/material";

export interface FormDividerProps {
  children?: React.ReactNode;
}

export const FormDivider: React.FC<React.PropsWithChildren<FormDividerProps>> = (
  props: FormDividerProps
) => {
  return (
    <Grid item xs={12} sx={{mt:4, pb: 0}}>
      <Typography sx={{ paddingBottom: 1 ,color:"black" }}>
        {props.children}
      </Typography>
      <Divider
        sx={{ borderColor: "black" }}
      />
    </Grid>
  );
};
