import { useContext, useEffect, useLayoutEffect, useState } from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { Link } from "react-router-dom";

import HelpMenu from "../help/HelpMenu";
import { useGlobalConfig } from "../hooks/useGlobalConfig";
import { FeatureContext } from "./Features";
import MenuPopupState from "./Menu";
import MenuDrawer from "./MenuDrawer";
import CustomSignOut from "./SignOut";

import type { NavProps } from "./types";
import MyMdHelpMenu from "../help/MyMdHelpMenu";

const Nav: React.FC<React.PropsWithChildren<NavProps>> = ({
  children,
  nav,
}) => {
  const globalConfig = useGlobalConfig();
  const { isFeatureActive } = useContext(FeatureContext);
  const [domainName,setDomainName] = useState("")
  const [activeNavItem, setActiveNavItem] = useState(null);
  const settingsPopupState = usePopupState({
    variant: "popover",
    popupId: "menu-settings",
  });
  const [logoUrl, setLogoUrl] = useState<string>();

  useLayoutEffect(() => {
    if (globalConfig) {
      if (globalConfig.site_logo) {
        let env = "";
        if (process.env.REACT_APP_ENV !== "prod") {
          env = "_" + process.env.REACT_APP_ENV;
        }
        setLogoUrl(
          globalConfig.site_domain +
            globalConfig.site_path +
            "data/module_" +
            process.env.REACT_APP_CUSTOMER +
            env +
            "/" +
            globalConfig.site_logo
        );
      } else {
        setLogoUrl(process.env.PUBLIC_URL + "/" + process.env.REACT_APP_LOGO);
      }
    }
  }, [globalConfig]);

  useEffect(() => {
    const url =window.location.href
    const removeHttps = url.split("//");
    const splitUrl = removeHttps[1].split(".")
   
    if (splitUrl.length>0)
    {
      if (splitUrl[0].includes("mymd"))
      {
        setDomainName('mymd')
      }
      else{
        if (splitUrl.length === 3){
          setDomainName(splitUrl[1])
        }
        else if(splitUrl.length === 2){
          setDomainName(splitUrl[0])
        }
       
      }
    }
   
   
  }, []);

  return (
    <>
      <AppBar
        position="relative"
        sx={{ backgroundColor: "black" }}
        elevation={0}
      >
        <Toolbar>
          <>
            <MenuDrawer nav={nav}></MenuDrawer>
            <Box
              display="flex"
              flexGrow="1"
              sx={{
                alignItems: { xs: "center", sm: "flex-end" },
                paddingLeft: { sm: -1.5 },
                maxWidth: "100%",
              }}
            >
              <Box alignSelf="center" sx={{ minWidth: 180 }}>
                {logoUrl ? (
                  <Link to="/">
                    <img
                      src={logoUrl}
                      alt="logo"
                      style={{ maxWidth: "180px" }}
                    ></img>
                  </Link>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ bgcolor: "grey.900", fontSize: "2rem" }}
                    width={180}
                  />
                )}
              </Box>

              <List
                component="nav"
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "row",
                  padding: 0,
                  marginLeft: 10,
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {nav && nav.length > 0
                  ? nav.map((navItem, index) => (
                      <ListItem
                        key={navItem.id}
                        value={navItem.id}
                        sx={{
                          whiteSpace: "nowrap",
                          paddingTop: 0,
                          paddingBottom: 0,
                          width: "auto",
                        }}
                      >
                        <MenuPopupState navItem={navItem} key={index} activeNavItem = {activeNavItem} setActiveNavItem = {setActiveNavItem} />
                      </ListItem>
                    ))
                  : Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: 0,
                            paddingBottom: 0,
                            width: "auto",
                          }}
                        >
                          <Skeleton
                            key={index}
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", bgcolor: "grey.900" }}
                            width={140}
                          />
                        </ListItem>
                      ))}
              </List>
            </Box>
            <Box
              display="flex"
              sx={{
                alignItems: { xs: "center", sm: "flex-end" },
                color: grey[400],
                lineHeight: { sm: "72px" },
              }}
            >
              {
                domainName === "mymd"?<MyMdHelpMenu/>:<HelpMenu />
              }
              {isFeatureActive("user_settings") ? (
                <>
                  <IconButton
                    {...bindTrigger(settingsPopupState)}
                    color="inherit"
                    size="large"
                    sx={{
                      paddingLeft: { xs: 1, sm: 3 },
                      paddingRight: { xs: 1, sm: 3 },
                    }}
                  >
                    <AccountCircleOutlinedIcon fontSize="large" />
                  </IconButton>
                  <Menu
                    {...bindMenu(settingsPopupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "& .MuiMenu-paper": {
                        backgroundColor: grey[400],
                        borderRadius: 0,
                      },
                    }}
                  >
                    <MenuItem
                      onClick={settingsPopupState.close}
                      component={Link}
                      to="settings"
                      sx={{
                        textDecoration: "none",
                        fontWeight: 400,
                        color: "white",
                      }}
                    >
                      <ListItemIcon>
                        <SettingsIcon
                          sx={{ color: "white" }}
                          fontSize="small"
                        />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <></>
              )}
              <CustomSignOut />
            </Box>
          </>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

export default Nav;
