import { useEffect, useState } from "react";
import GlobalConfig from "../config/GlobalConfig";

export function useGlobalConfig() {
  const [globalConfig, setGlobalConfig] = useState<{ [key: string]: any }>();

  useEffect(() => {
    const controller = new AbortController();

    GlobalConfig.get({ signal: controller.signal }).then((config) => {
      setGlobalConfig(config);
    });

    return () => {
      controller.abort();
    };
  }, []);

  return globalConfig;
}
