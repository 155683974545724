import { useRef } from "react";

import { Link, MenuItem } from "@mui/material";
import { Auth } from "aws-amplify";
import { AxiosResponse } from "axios";

import { axios } from "../api";

interface IZendeskURL {
  url: string;
}

function loadScript(src: string, position, id: string, callback): void {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.setAttribute("data-testid", id);
  script.src = src;
  script.onload = callback;
  position.appendChild(script);
}

const zendeskOnload = () => {
  if (window["zE"]) {
    window["zE"]("webWidget", "hide");
    window["zE"]("webWidget:on", "close", function () {
      window["zE"]("webWidget", "hide");
    });
  }
};

export const LoadZendeskWidget = (props) => {
  const { children } = props;
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#ze-snippet")) {
      window["zESettings"] = {
        webWidget: {
          color: { theme: "#00AFE9", launcherText: "#fff" },
        },
      };
      loadScript(
        "https://static.zdassets.com/ekr/snippet.js?key=f57ec036-16cd-414e-a40a-71688e722ebd",
        document.querySelector("body"),
        "ze-snippet",
        zendeskOnload
      );
    }

    loaded.current = true;
  }
  return children;
};

interface ZendeskWidgetProps {
  onClick: (event: any) => any;
}

export const ZendeskWidget: React.FC<ZendeskWidgetProps> = (props) => {
  const { onClick } = props;

  const zendeskSetUserAttributes = (attributes: any) => {
    /**
     * Sets name and email on the zendesk widget
     */
    window["zE"]("webWidget", "prefill", {
      name: {
        value: attributes["given_name"] + " " + attributes["family_name"],
      },
      email: {
        value: attributes["email"],
      },
    });
  };

  const openZendeskWidget = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (window["zE"]) {
      let { attributes } = await Auth.currentAuthenticatedUser();
      zendeskSetUserAttributes(attributes);
      window["zE"]("webWidget", "show");
      window["zE"]("webWidget", "open");
    }
  };

  return (
    <MenuItem onClick={onClick}>
      <Link
        href="#"
        color="inherit"
        onClick={openZendeskWidget}
        sx={{
          textDecoration: "none",
          fontWeight: 400,
          color: "white",
        }}
        underline="none"
      >
        Contact Support
      </Link>
    </MenuItem>
  );
};

interface ZendeskRedirectProps {
  onClick: any;
  returnTo?: string;
}

export const ZendeskRedirect: React.FC<React.PropsWithChildren<ZendeskRedirectProps>> = ({
  onClick,
  returnTo = "",
  children,
}) => {
  let href = "https://help.aeronetsoftware.com";
  if (returnTo) {
    href = returnTo;
  }

  const zendeskRedirect = async (
    page: string | null,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    if (!page) {
      page = "";
    }

    let apiUrl = "/v5/zendesk";
    if (process.env.NODE_ENV === "development") {
      let { attributes } = await Auth.currentAuthenticatedUser();
      // call api with user id when running locally as the api doesn't get the jwt decoded by api gateway
      apiUrl =
        apiUrl +
        "/" +
        attributes["custom:aeronet_user_id"] +
        "?name=" +
        attributes["given_name"] +
        " " +
        attributes["family_name"] +
        "&email=" +
        attributes["email"];
      console.warn("Using alternate get_nav_by_id api");
    }

    const resp: AxiosResponse<IZendeskURL> = await axios.get(apiUrl, {
      cache: false,
    });
    let zendeskUrl = resp.data.url;
    if (page) {
      zendeskUrl = zendeskUrl + "&return_to=" + encodeURIComponent(page);
    }

    window.open(zendeskUrl, "_blank", "noopener=true");
  };

  return (
    <MenuItem onClick={onClick}>
      <Link
        href={href}
        target="_blank"
        rel="noopener"
        color="inherit"
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          zendeskRedirect(returnTo, e)
        }
        sx={{
          textDecoration: "none",
          fontWeight: 400,
          color: "white",
        }}
        underline="none"
      >
        {children}
      </Link>
    </MenuItem>
  );
};
