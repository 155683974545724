import { Backdrop, Box, CircularProgress, Skeleton } from "@mui/material";

import Content from "./Content";
import Header from "./Header";

interface LoadingSpinnerProps {
  open?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const { open = true } = props;

  return (
    <Backdrop open={open} sx={{ color: "primary.main" }}>
      <CircularProgress color="inherit" size={60} />
    </Backdrop>
  );
};

export const LoadingView = () => {
  return (
    <>
      <Box display="flex" sx={{ pt: 2.5, pb: 2, px: 7 }}>
        <Skeleton variant="circular" width={40} height={40} sx={{ mr: 3 }} />
        <Skeleton variant="rectangular" width={330} height={40} />
      </Box>
      <Content>
        {Array(6)
          .fill(1)
          .map((card, index) => (
            <Skeleton key={index} animation="wave" sx={{ fontSize: "2rem" }} />
          ))}
      </Content>
    </>
  );
};

export const RouteLoading = () => {
  return (
    <>
      <Header />
      <LoadingView />
    </>
  );
};

export const ComponentLoading = () => {
  return (
    <Skeleton
      animation="wave"
      variant="text"
      sx={{ minWidth: "160px", maxWidth: "400px", mt: 1.5 }}
      data-testid="component-loading"
    />
  );
};

export default LoadingSpinner;
