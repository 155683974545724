/**
 * Sheets are MUI Drawers customized for Aeronet.
 */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { alpha, SxProps, Theme } from "@mui/material/styles";

import { Title } from "./Header";

export interface SheetProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  open: boolean;
  onClose: () => void;
  sx?: SxProps<Theme>;
}

const Sheet: React.FC<React.PropsWithChildren<SheetProps>> = (props) => {
  const { children, onClose, open, sx = [] } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: isMobile ? true : false, // Better open performance on mobile.
      }}
      sx={[
        {
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: {
              xs: "100%",
              sm: "100%",
              md: "33%",
              lg: "25%",
              xl: "25%",
            },
            overflowX: "hidden",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: alpha(theme.palette.text.primary, 0.15),
            },
            "&:hover fieldset": {
              borderColor: alpha(theme.palette.text.primary, 0.25),
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      aria-labelledby="sheet-title"
      aria-describedby="sheet-content"
      data-testid={props["data-testid"]}
    >
      {children}
    </Drawer>
  );
};

export interface SheetHeaderProps {
  children?: React.ReactNode;
  onClose: () => void;
  title: string;
  sx?: SxProps<Theme>;
}

export const SheetHeader: React.FC<
  React.PropsWithChildren<SheetHeaderProps>
> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <>
      <SheetTitle {...otherProps} />
      <Box pt={2} px={4}>
        {children}
      </Box>
      <Divider
        variant="middle"
        sx={{
          mx: 4,
          py: 1,
          mb: 1,
          borderBottomWidth: 2,
          borderColor: "text.secondary",
        }}
      />
    </>
  );
};

export interface SheetTitleProps {
  onClose: () => void;
  sx?: SxProps<Theme>;
  title: string;
}

export const SheetTitle: React.FC<SheetTitleProps> = (props) => {
  const { onClose, sx = [], title } = props;

  return (
    <Box
      id="sheet-title"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      pl={4}
    >
      <Title
        sx={[
          {
            pt: 6,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {title}
      </Title>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        size="small"
        style={{
          marginRight:"15px",
          marginTop:"35px"
        }}
        sx={{ alignSelf: "flex-start", p: 2 }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export interface SheetContentProps {
  children?: React.ReactNode;
}

export const SheetContent: React.FC<
  React.PropsWithChildren<SheetContentProps>
> = (props) => {
  const { children } = props;

  return (
    <Box
      id="sheet-content"
      flex="1 1 auto"
      overflow="auto"
      height="100%"
      pt={2}
      px={4}
    >
      {children}
    </Box>
  );
};

export interface SheetActionsProps {
  children?: React.ReactNode;
}

export const SheetActions: React.FC<
  React.PropsWithChildren<SheetActionsProps>
> = (props) => {
  const { children } = props;

  return (
    <Box
      flex="0 0 auto"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      p={4}
    >
      {children}
    </Box>
  );
};

export interface SheetHeaderItemProps {
  label: string;
  content: string;
}

export const SheetHeaderItem: React.FC<SheetHeaderItemProps> = (props) => {
  const { label, content } = props;

  return (
    <Typography>
      <Box component="span" fontWeight={500}>
        {label}:
      </Box>{" "}
      {content}
    </Typography>
  );
};

export default Sheet;
