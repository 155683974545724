import React, { useRef, useMemo } from 'react';
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { Field, Form, Formik, FormikValues } from "formik";
import { axios } from "../api";
import { ActionButton, CancelButton } from "../components/Buttons";
import Sheet, { SheetActions, SheetContent, SheetHeader, } from "../components/Sheet";
import { handleAPIErrors } from "../utils/FormikErrors";
import CustomSnackbarContent from "../components/CustomSnackbarContent";
import { DueDateAutocomplete } from './DueDateAutocomplete';

interface ParentSetFormProps{
   formRef: any;
   onClose: () => void;
   duedate_id:number
}

const ParentSetForm = (props:ParentSetFormProps) => {
  const { formRef, onClose, duedate_id } = props
  const { enqueueSnackbar } = useSnackbar();



  const initialDuedate = useMemo(() => {


    return {
      parent : null
    };
  }, []);


  return (
    <>

      {
        <Formik
          initialValues={initialDuedate}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
            let method = "post";
            let url = `/v6/duedate/link/records/${duedate_id}`;
            const data = {...values}
            const duedateData = Object.entries(values).reduce((acc, [key, value]) => {
                if(key === 'parent_id' && value){
               
                  acc['duedate_id'] =  data['parent_id']?.id || 0
                  
                }

  
                return acc;
              }, {});
        
    
            axios({
              method: method,
              url: url,
              data: duedateData,
            }).then((resp: AxiosResponse) => {
              setSubmitting(false);
              resetForm();
              onClose();
              enqueueSnackbar(
                <CustomSnackbarContent
                  message={`Successfully Parent Added`}
                />,
                {
                  variant: "success",
                }
              );
            }).catch((error) => {
              handleAPIErrors(error, setErrors);
              setSubmitting(false);
              enqueueSnackbar(`Error Parent not applied`, {
                variant: "error",
              });
            });
            onClose()
          }}
        >
          {({ isSubmitting, setFieldValue, values, setValues }) => (
            <Form>

              
                <Field
                    autoFocus
                    required
                    fullWidth
                    component={DueDateAutocomplete}
                    label="DueDate"
                    name="parent_id"
                    duedate_id = {duedate_id}
                />
            </Form>
          )}
        </Formik>
      }

    </>
  );
}

interface DuedateParentSliderProps {
  onClose: any;
  open: any;
  duedate_id: number
}

const DuedateParentSliderForm: React.FC<DuedateParentSliderProps> = (props) => {
  const {
    onClose,
    open,
    duedate_id
  } = props;

  const formRef = useRef<FormikValues>();

  return (
    <Sheet open={open} onClose={onClose}>
      <SheetHeader onClose={onClose} title={`Set Parent`}>
      </SheetHeader>
      <SheetContent>
        <ParentSetForm duedate_id={duedate_id} formRef={formRef} onClose={onClose} />
      </SheetContent>
      <SheetActions>
        <CancelButton
          onClick={() => {
            if (formRef.current) {
              formRef.current.resetForm();
            }
            onClose();
          }}
        />
        <ActionButton children={"Submit"} onClick={(e) => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }} />
      </SheetActions>
    </Sheet>
  );
}


export default DuedateParentSliderForm;