import { useMemo } from "react";

import { ValueOptions } from "@mui/x-data-grid-pro";

import { useTypes } from "./useTypes";

export function useTypeOptions(parentId?: number) {
  const types = useTypes(parentId);

  const typeOptions: ValueOptions[] | undefined = useMemo(() => {
    if (types) {
      const t = [{ value: "", label: "Any" }];
      const to = types.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      }));
      t.push(...to);
      return t;
    }
  }, [types]);

  return typeOptions;
}
