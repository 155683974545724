import { Auth } from "aws-amplify";
import Axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { setupCache, buildWebStorage } from "axios-cache-interceptor";
import { createAxiosCacheHooks } from "axios-cache-hooks";
import { enqueueSnackbar } from "notistack";

Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const awsRegex = new RegExp(/^.*\.amazonaws.com\/.*/);
const moduleRegex = new RegExp(/^.*api\.module.nz\/.*/);

export const axios = setupCache(Axios, {
  storage: buildWebStorage(localStorage, "aeronet-api-cache:"),
  cacheTakeover: false,
});
export const { useQuery, useMutation } = createAxiosCacheHooks();

export const axiosRequestInterceptor = async (
  config: InternalAxiosRequestConfig
) => {
  // skip adding authorization if request is to aws directly
  if (config.url && awsRegex.test(config.url)) {
    return config;
  }

  if (config.url && moduleRegex.test(config.url)) {
    return config;
  }

  // This will get the cached session, or refresh the token first if it has expired
  const session = await Auth.currentSession();
  
  return setHeader(config, "Authorization", session.getIdToken().getJwtToken());
};

function setHeader(
  config: InternalAxiosRequestConfig<any>,
  header: string,
  value: string
): InternalAxiosRequestConfig<any> {
  if (!config) {
    config = {
      headers: new AxiosHeaders(),
    };
  }
  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }

  config.headers.set(header, value);
  return config;
}

export const axiosResponseErrorInterceptor = async (error) => {
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */

    // Attempt to display the error message from the api
    if (
      error.response.data &&
      Object.prototype.hasOwnProperty.call(error.response.data, "detail") &&
      error.response.data.detail
    ) {
      if (Array.isArray(error.response.data.detail)) {
        for (let e of error.response.data.detail) {
          console.error(e.msg);
          enqueueSnackbar(e.msg, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      } else {
        console.error(error.response.data.detail);
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }

      return Promise.reject(error);
    }
  } else if (error.request && !Axios.isCancel(error)) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest
     */
    console.error("Request error: ", error.request);
    enqueueSnackbar(error.request, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  } else {
    // Something happened in setting up the request and triggered an Error
    // @ts-ignore
    if (!axios.isCancel(error)) {
      console.error("Other error: ", error.message);
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  }

  console.debug(error.config);
  console.debug(error.toJSON());

  return Promise.reject(error);
};
