import {
  Component,
  createRef,
  lazy,
  useContext,
  useEffect,
  useState,
} from "react";

import "@aws-amplify/ui/dist/style.css";
import { Button, styled, useMediaQuery, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import Amplify, { Auth } from "aws-amplify";
import { Authenticator, Greetings, Loading, SignIn } from "aws-amplify-react";
import { AxiosResponse } from "axios";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";

import { axios } from "../api";
import AppLayout from "../components/AppBackground";
import CustomLoading, { hideLoader } from "../components/AppLoading";
import { FeatureContext } from "./Features";
import CustomSignIn from "./SignIn";

import type { AeronetV6Response } from "../types";
import type { IAeronetNav } from "./types";
import Invoices from "../invoices/Invoices";
import Components from "../component/Component";
import Inspections from "../inspections/Inspections";
import Reports from "../reports/Reports";
import DueDate from "../DueDate/DueDate";

const Dashboard = lazy(() => import("../dashboard/Dashboard"));
const NotFound = lazy(() => import("./NotFound"));
const Settings = lazy(() => import("./Settings"));
const Tools = lazy(() => import("../tools/Tools"));
const Sors = lazy(() => import("../sors/Sors"));
const Aircraft = lazy(() => import("../aircraft/Aircraft"));
const Documents = lazy(() => import("../documents/Documents"));
const PartsOrders = lazy(()=> import("../parts-orders/Orders"))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },

  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_STORAGE_BUCKET,
      region: process.env.REACT_APP_REGION,
      dangerouslyConnectToHttpEndpointForTesting: process.env.REACT_APP_S3_LOCAL
        ? process.env.REACT_APP_S3_LOCAL
        : false,
    },
    customPrefix: {
      public: "storage/public/",
      protected: "storage/protected/",
      private: "storage/private/",
    },
  },
});

interface AppProps {
  authState?: string;
  authData?: any;
  hideLoader?: () => void;
}

const App: React.FC<AppProps> = ({ authData, authState, hideLoader }) => {
  const [nav, setNav] = useState<readonly IAeronetNav[]>([]);
  const { isFeatureActive, getFeature } = useContext(FeatureContext);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (hideLoader) {
    hideLoader();
  }
  useEffect(() => {
    let active = true;
    async function signOut() {
      let cognitoUser = await Auth.currentAuthenticatedUser();
      window.confirm(
        "There was an error with your account, you will be logged out now."
      );
      try {
        await Auth.signOut();
        await axios.storage.remove(
          `get-nav-${cognitoUser.attributes["custom:aeronet_user_id"]}`
        );
      } catch (error) {
        console.log("error signing out: ", error);
      }
      Sentry.configureScope((scope) => scope.setUser(null));
    }

    async function setUpNav() {
      let cognitoUser = await Auth.currentAuthenticatedUser();
      Sentry.setUser({
        id: cognitoUser.attributes["custom:aeronet_user_id"],
        username: cognitoUser.username,
        email: cognitoUser.attributes.email,
        ...cognitoUser.attributes,
      });

      let navUrl = "/v6/nav";
      if (
        process.env.NODE_ENV === "development" &&
        process.env.REACT_APP_API_URL === "http://localhost:9999"
      ) {
        // call api with user id when running locally as the api doesn't get the jwt decoded by api gateway
        navUrl =
          navUrl + "/" + cognitoUser.attributes["custom:aeronet_user_id"];
        console.warn("Using alternate get_nav_by_id api");
      }

      try {
        const resp: AxiosResponse<AeronetV6Response<IAeronetNav>> =
          await axios.get(navUrl, {
            id: `get-nav-${cognitoUser.attributes["custom:aeronet_user_id"]}`,
            cache: { ttl: 1000 * 60 * 60 * 24 }, // 1 day
          });

        // this sets up the paths to React views for features that are active
        // the default is that the React view uses the same path as the function name in Roxen
        // this is the place to override that
        for (const navItem of resp.data.data) {
          for (const menuItem of navItem.children) {
            if (menuItem.function !== "url" && menuItem.function !== "custom") {
              if (isFeatureActive(menuItem.function)) {
                switch (menuItem.function) {
                  case "jobs":
                    if (menuItem.name === "Work Orders Responsive") {
                      menuItem.to = "jobs";
                    }
                    break;
                  case "users_prefs":
                    menuItem.to = "preferences";
                    break;
                  default:
                    menuItem.to = menuItem.function;
                }
              }
            } else {
              if (isFeatureActive(menuItem.remote_menu_key)) {
                switch (menuItem.remote_menu_key) {
                  case "inventory":
                    menuItem.to = menuItem.remote_menu_key;
                    menuItem.subMenus = [
                      { name: "Parts", to: "parts" },
                      { name: "Min / Max", to: "min-max" },
                      { name: "Demand", to: "demand" },
                      { name: "RFQ", to: "rfq" },
                      { name: "Ordered", to: "ordered" },
                      { name: "Stock", to: "stock" },
                      { name: "Issued", to: "issued" },
                      { name: "Sold", to: "sold" },
                    ];
                    break;
                  default:
                    menuItem.to = menuItem.remote_menu_key;
                }
              }
            }
          }
        }
        if (active) {
          setNav(resp.data.data);
        }
      } catch (e) {
        signOut();
        return;
      }
    }

    if (authState === "signedIn") {
      setUpNav();
    }

    return () => {
      active = false;
    };
  }, [authState, isFeatureActive]);

  return (
    <SentryRoutes>
      <Route element={<AppLayout nav={nav} />}>
        <Route path="/" element={<Dashboard />} />

        {isFeatureActive("user_settings") && (
          <Route path="settings" element={<Settings />} />
        )}
        {isFeatureActive("tools") && (
          <Route
            path="tools/*"
            element={<Tools originalUrl="" title="Tools" />}
          />
        )}
        <Route
          path="sors/*"
          element={<Sors originalUrl={''}
          title={`Service & Operations Report`}/>}
        />
        <Route
          path="aircraft/*"
          element={<Aircraft originalUrl={""} title={`Aircraft`} />}
        />
        <Route
          path="invoices/*"
          element={<Invoices originalUrl={""} title={`Invoices`} />}
        />
        <Route
          path="documents/*"
          element={<Documents originalUrl={""} title={`Documents`} />}
        />
        <Route
          path="components/*"
          element={<Components originalUrl={""} title={`Components`} />}
        /> 
        <Route
          path="inspections/*"
          element={<Inspections  originalUrl={""} title={`Inspections`} />}
        />
        <Route
          path="reports/*"
          element={<Reports />}
        />
        <Route
          path="parts-orders/*"
          element={<PartsOrders  originalUrl={""} title={`Parts Orders`} />}
        />
        <Route path="duedate/*" element = {<DueDate originalUrl="" title="Due Date"/>}/>
        {nav && nav.length > 0 && <Route path="*" element={<NotFound />} />}
        {nav.map((navItem, index) =>
          navItem.children.map((menuItem, index) => {
            if (menuItem.to) {
              const featureName = menuItem.remote_menu_key
                ? menuItem.remote_menu_key
                : menuItem.function;
              const feature = getFeature(featureName);
              if (feature) {
                let FeatureComponent = feature.component;
                if (smallScreen && feature.responsiveComponent) {
                  FeatureComponent = feature.responsiveComponent;
                }

                return (
                  <Route
                    path={menuItem.to + "/*"}
                    element={
                      <FeatureComponent
                        originalUrl={menuItem.url}
                        title={menuItem.name}
                      />
                    }
                  />
                );
              }
            }
            return null;
          })
        )}
      </Route>
    </SentryRoutes>
  );
};

export enum UsernameAttributes {
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
  USERNAME = "username",
}

const CustomTheme = {
  container: {
    height: "100%",
  },
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const NotistackProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // add action to all snackbars
  const notistackRef = createRef<any>();

  const onClickDismiss = (key) => () => {
    notistackRef.current!.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      preventDuplicate
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} sx={{ color: "white" }}>
          Dismiss
        </Button>
      )}
      children={children}
      Components={{
        success: StyledMaterialDesignContent,
      }}
    />
  );
};

class AppWithAuth extends Component {
  componentDidMount() {
    hideLoader();
  }

  render() {
    return (
      <NotistackProvider>
        {/* @ts-ignore */}
        <Authenticator
          authState="signIn"
          hide={[SignIn, Loading, Greetings]}
          usernameAttributes={UsernameAttributes.EMAIL}
          theme={CustomTheme}
        >
          <CustomSignIn />
          <CustomLoading />
          <App />
        </Authenticator>
      </NotistackProvider>
    );
  }
}

export { App };
export default AppWithAuth;
