import { useEffect } from "react";
import { createRoot } from "react-dom/client";

import {
  Container,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import queryString from "query-string";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { axiosRequestInterceptor, axiosResponseErrorInterceptor } from "./api";
import App from "./app/App";
import { FeatureProvider } from "./app/Features";
import * as serviceWorker from "./serviceWorker";
import { blueTheme, tealTheme } from "./theme";
import "./version";
import "./utils/global.css"

axios.interceptors.request.use(axiosRequestInterceptor, (e) =>
  Promise.reject(e)
);

axios.interceptors.response.use((response) => response, axiosResponseErrorInterceptor);


let sentryDSN =
  "https://d300df74490b4754a8d8f17a1b80b3f4@o267919.ingest.sentry.io/6477105";
if (process.env.REACT_APP_ENV === "local") {
  sentryDSN = "";
}

Sentry.init({
  dsn: sentryDSN,
  environment: `${process.env.REACT_APP_CUSTOMER}-${process.env.REACT_APP_ENV}`,
  release: `aeronet-frontend@${process.env.REACT_APP_BUILD_VERSION}`,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});



LicenseInfo.setLicenseKey('115488928aef8239ccfb9332d12be8fbTz04ODEwMSxFPTE3NDQyODczMDcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
// MUI X license
// LicenseInfo.setLicenseKey(
//   "5e65333dc1186fd6b573bdd06352f78fT1JERVI6NDY0MDgsRVhQSVJZPTE5NDcxOTg1MTM0MjQsS0VZVkVSU0lPTj0x"
// );

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider
      theme={
        JSON.parse(process.env.REACT_APP_NEW_THEME ?? "true")
          ? tealTheme
          : blueTheme
      }
    >
      <FeatureProvider>
        <CssBaseline />
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{ height: "100%" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter basename="app">
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: queryString.parse,
                  objectToSearchString: queryString.stringify,
                }}
              >
                <App />
              </QueryParamProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </Container>
      </FeatureProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
