import FlightLandIcon from "@mui/icons-material/FlightLand";
import HomeIcon from "@mui/icons-material/Home";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Error = ({ error, componentStack, resetError }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      textAlign="center"
      data-testid="500"
      sx={{ mt: 8 }}
    >
      <FlightLandIcon sx={{ fontSize: 200 }} color="primary" />
      <Typography variant="h1" sx={{ textAlign: "center" }} color="secondary">
        Oops! Something's gone wrong.
      </Typography>
      {process.env.NODE_ENV === "development" && (
        <>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
        </>
      )}
      <Button
        variant="outlined"
        sx={{ mt: 4 }}
        startIcon={<HomeIcon />}
        component={Link}
        to="/"
        onClick={() => {
          resetError();
        }}
      >
        Back to Home
      </Button>
    </Stack>
  );
};

export default Error;
