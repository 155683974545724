import { CacheAxiosResponse } from "axios-cache-interceptor";
import { axios } from "../api";

import type { AeronetV6Response } from "../types";
import type { AircraftConfiguration } from "./types";

/** Returns the aircraft configuration */
export function getAircraftConfiguration(): Promise<
  CacheAxiosResponse<AeronetV6Response<AircraftConfiguration>>
> {
  return axios.get<AeronetV6Response<AircraftConfiguration>>(
    "/v6/aircraft/config",
    {
      params: {
        size: 100
      },
    }
  );
}
