import * as React from "react";

import { Menu } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import { unstable_useId as useId } from "@mui/utils";
import { gridClasses, useGridApiContext } from "@mui/x-data-grid-pro";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { MenuButton } from "../Buttons";
import { grey } from "@mui/material/colors";

export const GridToolbarExportContainer = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function GridToolbarExportContainer(props, ref) {
  const { children, onClick, ...other } = props;

  const apiRef = useGridApiContext();
  const exportButtonId = useId();
  const exportMenuId = useId();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "export-menu",
  });

  if (children == null) {
    return null;
  }

  return (
    <React.Fragment>
      <MenuButton
        aria-label={apiRef.current.getLocaleText("toolbarExportLabel")}
        aria-haspopup="menu"
        id={exportButtonId}
        {...bindTrigger(popupState)}
        {...other}
      />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...bindMenu(popupState)}
      >
        <MenuList
          id={exportMenuId}
          className={gridClasses.menuList}
          aria-labelledby={exportButtonId}
          sx={{pt: 0, pb: 0, 
          backgroundColor:grey[100],
          }}
        >
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return React.cloneElement<any>(child, {
              hideMenu: popupState.close,
            });
          })}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
});
