import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { axios } from '../api'
import LightTooltip from '../components/LightTooltip'
import {  Box, Button, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { AircraftComponents } from '../aircraft/types'
import {  InspectionDetailView } from './types'
import { ActionMenuItem } from '../components/datagrid/ActionMenu'
import AlertDialog from '../components/AlertDialog'
import {DataGridPremium, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid-premium'
import { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Link } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { usePopupState } from 'material-ui-popup-state/hooks'
import LoadingSpinner from '../components/Loading'
interface InspectionLimitationsProps {
  originalUrl: string;
    formRef?: any,
    inspection:InspectionDetailView;
}

interface ActionMenuButtonProps {
  onClick?: (event: any) => any;
  actions?: ActionMenuItem[];
  disabled?: boolean;
}

const ActionMenu: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { actions, disabled } = props;
  const popupState = usePopupState({
      variant: "popover",
      popupId: "action-menu",
  });

  return (
      <>
          <ActionMenuButton disabled={disabled} {...bindTrigger(popupState)} />
          <Menu {...bindMenu(popupState)}>
              {actions?.map((action, index) => {
                  if (action.to) {
                      return (
                          <MenuItem
                              onClick={() => popupState.close()}
                              component={Link}
                              to={action.to}
                              key={index}
                          >
                              {action.name}
                          </MenuItem>
                      );
                  }
                  return (
                      <MenuItem
                          key={index}
                          onClick={() => {
                              popupState.close();
                              if (action.onClick) {
                                  action.onClick();
                              }
                          }}
                      >
                          {action.name}
                      </MenuItem>
                  );
              })}
          </Menu>
      </>
  );
};

const ActionMenuButton: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { onClick, disabled } = props;

  return (
      <Button
          disabled={disabled}
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={onClick}
          sx={{
              color: { sm: "white" },
              mx: { xs: 1, sm: 1.5 },
              mt: 0.5,
              backgroundColor: { sm: grey[500] },
              "&:hover": { backgroundColor: { sm: grey[700] } },
          }}
          variant="contained"
          endIcon={<KeyboardArrowDownIcon />}
      >
          ACTIONS
      </Button>
  );
};

// enum ActionTypeEnum {
//   NEW = "NEW",
//   DEFAULT = "",
//   EDIT_LIMITATION = "EDIT_LIMITATION"
// }


const InspectionComponent: React.FC<InspectionLimitationsProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar()
    const {inspection} = props
    const [inspectionLimitation,setInspectionLimitation] = useState<AircraftComponents[]>()
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [onchangeRow, setOnchangeRow] = useState<AircraftComponents[]>([]);
    const [tabDialogOpen,setTabDialogOpen] = useState(false)
   
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName:"Inspection title",
        field:"title",
        flex:1,
        valueGetter:(params,row)=>{
          return inspection?.title || ""
        }
      }, 
      {
        headerName:"Inspection Type",
        field:"inspection_type",
        flex:1,
        valueGetter:(params,row)=>{
          return inspection?.type_obj?.name || ""
        }
      },
      {
        headerName:"Based On",
        field:"based_on",
        flex:1,
      
      },
      {
        headerName:"Period",
        field:"period",
        flex:1,
        valueGetter:(params,row) =>{
          const period = Number(row?.period || 0.00)
          return (period - Math.floor(period)) === 0 ?Math.floor(period):period
      }
      },
        
      {
        headerName:"Warning",
        field:"warning",
        flex:1,
        valueGetter:(params,row) =>{
          const warning = Number(row?.warning || 0.00)
          return (warning - Math.floor(warning)) === 0 ?`${Math.floor(warning)} `+row?.warning_unit || "":warning+row?.warning_unit || ""
      }
      },
      
       {
        headerName:"Last Inspection",
        field:"last_inspections",
        flex:1,
        valueGetter:(params,row)=>{
          const based_on_date =['Date (Months)','Date (Days)','Date (Years)'];
          return (based_on_date.includes(row?.based_on))?(row?.last_date || ""):(row?.last_units || 0.00) 
        }
      },
      {
        headerName:"Next Inspection",
        field:"next_inspection",
        flex:1,
        valueGetter:(params,row)=>{
          const based_on_date =['Date (Months)','Date (Days)','Date (Years)'];
          const extension = row?.extension || 0;
          const based_on =row?.based_on || "";
          if(extension>0){
            let lastdone;
            if(based_on_date.includes(based_on))
            {
              lastdone = row?.next_date || ""
            }
            else{
              lastdone = row?.next_units || ""
            }
            return lastdone
          }else{
            if(based_on_date.includes(based_on))
            {
             return (row?.next_date) || ""
            }
            else{
              return (row?.next_units )|| ""
            }
          }
        }
      },
      {
        headerName:"Remaining",
        field:"limitations.days_remaining",
        flex:1,
        valueGetter:(params,row)=>{
          const based_on_date =['Date (Months)','Date (Days)','Date (Years)'];
          return (based_on_date.includes(row?.based_on))?(row?.days_remaining || 0):(row?.units_remaining || 0) 
        }
      },
      {
        headerName:"Notes",
        field:"limitaitons.limitation_notes",
        flex:0.5,
        renderCell:(params)=><>
      <LightTooltip title={params.row?.limitation_notes || ""}>
        <IconButton>
          <DescriptionOutlinedIcon />
        </IconButton>
      </LightTooltip>
      </>
      }
     
    ],
    [inspection]
  );


  const getData = useCallback(
    async () => {
        try {
          const resp: AxiosResponse =
            await axios.get(`/v6/inspection/limitations/${inspection?.id}`, {
                id:`get-inspection-limitation-${inspection?.id}`
            });
           axios.storage.remove(`get-inspection-limitation-${inspection?.id}`)
           setInspectionLimitation(resp.data)
        
        } catch (e) {
          // @ts-ignore
          if (!axios.isCancel(e)) {
            enqueueSnackbar("Error fetching inspections", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
          throw e;
        }
      },[enqueueSnackbar,inspection?.id])

  useEffect(()=>{
    getData()
  },[getData])



    const onDeleteInspectionLimitation = useCallback(async () => {
        
      const limitationsIDs = onchangeRow.map((limitation) => limitation.id);

      for (const id of limitationsIDs) {
          await axios
              .delete(`/v6/inspection/limitations/${id}`)
              .then(() => {
                  enqueueSnackbar(`Limitation ${id} deleted`, {
                      variant: "success",
                  });
        
              })
              .catch((_error) => {
                  enqueueSnackbar("Error deleting part", {
                      variant: "error",
                  });
              });
      }
      setOnchangeRow([])
      getData()
  },[onchangeRow,enqueueSnackbar,getData])

    const actions:ActionMenuItem[] = useMemo(()=>{
      const defaultActions : ActionMenuItem[] = [
        {
          name: "Delete",
          namePlural: "Delete",
          action: ["Delete", "DELETE"],
          requiresSelection: true,
          multipleSelection: true,
          onClick: () => {
            setTabDialogOpen(true);
        },
        }
      ]
      return defaultActions
    },[])

    
       
  return (
    <>
    <Box sx={{ height: 520, width: '100%' }}>
  <Grid container rowSpacing={3}>
                <Grid item xs={12} md={6} lg={6}> 
                    <ActionMenu key={2} actions={actions} />
                </Grid>
               
            </Grid>
  {
      inspectionLimitation ?
      <DataGridPremium
        columns={columns}
        rows={inspectionLimitation}
        checkboxSelection = {true}
        autoHeight
        sx={{ flex: 1, marginTop: "10px" }}
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          setRowSelectionModel(newRowSelectionModel);
          console.log(newRowSelectionModel,"new models")
          const selectedRows = inspectionLimitation && inspectionLimitation.filter((row) =>
              newRowSelectionModel.includes(row.id)
          );
          setOnchangeRow(selectedRows as AircraftComponents[]);
      }}
      rowSelectionModel={rowSelectionModel}
      />:<LoadingSpinner/>
    }

   </Box>
   <AlertDialog
        title="Delete this record?"
        open={tabDialogOpen}
        handleClose={() => {
          setTabDialogOpen(false);
        }}
      >
        <Button
          onClick={() => {
            setTabDialogOpen(false);
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setTabDialogOpen(false);
            onDeleteInspectionLimitation();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </AlertDialog>
  </>
  )
}

export default InspectionComponent