import queryString from "query-string";

import { axios } from "../api";

import type { AeronetV6Response } from "../types";
import type { Attachment, AttachmentCreate } from "./types";

class _AttachmentService {
  public async create(data: AttachmentCreate): Promise<Attachment> {
    const resp = await axios.post("/v6/attachments", data);
    return resp.data;
  }

  async _query(url: string, filter: string[]): Promise<readonly Attachment[]> {
    const params = { filter };
    const resp = await axios.get<AeronetV6Response<Attachment>>(url, {
      params,
      paramsSerializer: (params) => queryString.stringify(params),
    });
    return resp.data.data;
  }

  public async query(filter: string[]): Promise<readonly Attachment[]> {
    return this._query("/v6/attachments", filter);
  }

  public async queryResource(
    source: string,
    id: number,
    filter: string[]
  ): Promise<readonly Attachment[]> {
    return this._query(`/v6/attachments/${source}/${id}`, filter);
  }

  public async delete(id: number): Promise<void> {
    return await axios.delete(`/v6/attachments/${id}`);
  }

  public async deleteAll(source: string, id: number): Promise<void> {
    return await axios.delete(`/v6/attachments/${source}/${id}`);
  }
}

export let AttachmentService = new _AttachmentService();
