import { User } from "../users/user-management/types";


export const formtListObject = (data:User[])=>{
    console.log(data,"dataa")
    const formatedData = data.map((obj)=>{
        return {
            "contact_id" : obj.id,
            "contact_type":"user",
        }
    })
    return formatedData
}