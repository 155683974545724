import React from 'react'
import { AeronetViewProps } from '../types';
import  { ListingHeader } from '../components/Header';
import InspcetionDataGrid from './InspcetionDataGrid';

interface InspectionListProps extends AeronetViewProps{

}

const InspectionList:React.FC<InspectionListProps> = (props) => {
    const {title,originalUrl} = props
  return (
    <>
    <ListingHeader>{title}</ListingHeader>
    <InspcetionDataGrid
     originalUrl={originalUrl}
     title={title}
    />
    </>
  )
}

export default InspectionList