import { ItemList } from "../inventory/types";

import type { GridValidRowModel } from "@mui/x-data-grid-pro";
import type { BasePart, IPartList, ISupplier } from "./types";

export const isPart = (
  data: IPartList | IterableIterator<GridValidRowModel>
): data is IPartList => {
  if ("id" in data) {
    return true;
  }
  return false;
};

export const isItem = (
  data: ItemList | IterableIterator<GridValidRowModel>
): data is ItemList => {
  if ("id" in data) {
    return true;
  }
  return false;
};

export const getDefaultSupplier = (parts: BasePart[]): ISupplier | string => {
  let supplier = parts[0].supplier;
  for (let part of parts.slice(1)) {
    if (part.supplier?.id !== supplier?.id) {
      return "";
    }
  }
  return supplier ?? ""; 
};
