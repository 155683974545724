import React, { useCallback, useMemo, useState } from 'react'
import { AeronetViewProps } from '../types';
// import AeronetDataGrid from '../components/datagrid/DataGrid';
import {  GridColDef,  GridSortItem } from '@mui/x-data-grid-pro';
import { useCurrentUser } from '../hooks/useCurrentUser';
import AeronetDataGrid, { QueryResults } from '../components/datagrid/DataGrid';
import { AxiosResponse } from 'axios';
import { axios } from '../api';
import queryString from 'query-string';
import { InspectionDeletePayload, InspectionList } from './types';
import { useSnackbar } from 'notistack';
import { CacheRequestConfig } from 'axios-cache-interceptor';
import { useNavigate } from 'react-router-dom';
import { ActionMenuItem } from '../components/datagrid/ActionMenu';
import AlertDialog from '../components/AlertDialog';
import { Button } from '@mui/material';

interface InspcetionDataGridProps extends AeronetViewProps{
  }

const InspcetionDataGrid: React.FC<InspcetionDataGridProps> = (props) => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser();
  const {enqueueSnackbar} = useSnackbar();
  const [tabDialogOpen, setTabDialogOpen] = useState(false);
  const [deletePayload, setDeletePayload] = useState<InspectionDeletePayload | null>(
    null
  );
 
  const [selectedRows, setSelectedRows] = useState<InspectionList[]>([]);
  const columns: GridColDef[] = useMemo(()=> [
    {
      headerName:"ID",
      field:"id",
      flex:1
    },
    {
      headerName:"Inspection",
      field:"title",
      flex:1
    },
    {
      headerName:"Reference",
      field:"reference",
      flex:1
    },
    {
      headerName:"Version Date",
      field:"version_date",
      flex:1
    },
    {
      headerName:"Insprection Type",
      field:"type",
      flex:1
    },
  
  ],[])


  const getData = useCallback(
    async (params: any, axiosConfig?: CacheRequestConfig) => {
      try {
        const resp: AxiosResponse =
          await axios.get("/v6/inspection", {
            params: params,
            paramsSerializer: (params) => queryString.stringify(params),
            ...axiosConfig,
          });
          
        return {
          total: resp.data.total,
          rows: resp.data.data,
        } as QueryResults<InspectionList>;
      } catch (e) {
        // @ts-ignore
        if (!axios.isCancel(e)) {
          enqueueSnackbar("Error fetching quotes", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
        throw e;
      }
    },
    [enqueueSnackbar])

    const deleteSors = useCallback(() => {
      const inspectionIds = selectedRows.map((row) => row.id);
      if (!inspectionIds || inspectionIds.length === 0) {
        return;
      }
      for (const id of inspectionIds) {
        axios
          .delete(`/v6/inspection/${id}`)
          .then(() => {
            enqueueSnackbar(`Inspection deleted ${id}`, {
              variant: "success",
            });
            if (deletePayload?.apiRef) {
              deletePayload.apiRef.current.updateRows([
                { id: id, _action: "delete" },
              ]);
            }
          })
          .catch((error) => {
            console.error(error);
            enqueueSnackbar(`Error deleting Inspection`, {
              variant: "error",
            });
          });
      }
      deletePayload?.setSelectionModel([]);
      setDeletePayload(null);
      setSelectedRows([]);
    }, [deletePayload, enqueueSnackbar, selectedRows]);

    const actions:ActionMenuItem[] = useMemo(()=>{
      const defaultActions : ActionMenuItem[] = [
        {
          name: "Delete",
          namePlural: "Delete",
          action: ["Delete", "DELETE"],
          requiresSelection: true,
          multipleSelection: true,
          onClick: async (event: InspectionDeletePayload) => {
            const rows = [...event.selectedRows].map((row) => row);
  
            setTabDialogOpen(true);
            setDeletePayload(event);
            setSelectedRows(rows);
          },
        }
      ]
      return defaultActions
    },[])

    const onRowClick = useCallback(
      (params, event) => {
        event.stopPropagation();
  
        let currentElement = event.target;
  
        // Traverse up the DOM tree to check if any parent element has the 'pdf-icon-button' class
        while (currentElement) {
          // Ensure className is a string before calling includes
          if (
            typeof currentElement.className === "string" &&
            currentElement.className.includes("pdf-icon-button")
          ) {
            // If it's part of the PDF icon button, do not navigate
            return;
          }
          currentElement = currentElement.parentElement;
        }
  
        // If the clicked element is not part of the PDF icon, navigate
        navigate(String(params.id));
      },
      [navigate]
    );
  const initialSort: GridSortItem[] = useMemo(
    () => [{ field: "id", sort: "desc" }],
    []
  );

  return (
    <>
     <AeronetDataGrid
     columns={columns}
     actions={actions}
    data={getData}
    initialSort={initialSort}
     name='Inspection-Data-Grid'
     currentUserId={currentUser?.id}
     onRowClick={onRowClick}
     />
      <AlertDialog
        title="Delete this record?"
        open={tabDialogOpen}
        handleClose={() => {
          setTabDialogOpen(false);
        }}
      >
        <Button
          onClick={() => {
            setTabDialogOpen(false);
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => {
            setTabDialogOpen(false);
            deleteSors();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </AlertDialog>
    </>
  )
}

export default InspcetionDataGrid