import { createContext, useCallback, useContext, useMemo } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  ListItemText,
  Link as MaterialLink,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import {
  bindFocus,
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { Link, useLocation } from "react-router-dom";

import type { IAeronetNav } from "./types";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  fontWeight: 400,
  color: "#616161",
  flexGrow: 1,
}));

const CascadingContext = createContext({
  parentPopupState: null,
  rootPopupState: null,
});

function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = useContext(CascadingContext);
  if (!rootPopupState) throw new Error("must be used inside a CascadingMenu");
  const handleClick = useCallback(
    (event) => {
      // @ts-ignore
      rootPopupState.close(event);
      if (onClick) onClick(event);
    },
    [rootPopupState, onClick]
  );

  return <MenuItem sx={{
    "&:hover": {
        backgroundColor: "rgba(212, 224, 225) !important",
    },
  }} 
  {...props} onContextMenu={(event)=>{
    event.stopPropagation()
  }} onClick={handleClick} />;
}

function CascadingSubmenu({ popupId, title, to, ...props }) {
  const { parentPopupState } = useContext(CascadingContext);
  const popupState = usePopupState({
    popupId,
    variant: "popover",
    parentPopupState,
  });
  const theme = useTheme();
  return (
    <>
      <MenuItem sx={{
      "&:hover": {
          backgroundColor: "rgba(212, 224, 225) !important",
      },
    }}
     {...bindMenu(popupState)} {...bindFocus(popupState)}>
        <StyledLink
          to={to}
          // @ts-ignore
          onClick={parentPopupState.close}
          sx={{
            "&:hover": {
                color: theme.palette.primary.main,
            },
          }}
        >
          <Typography  variant="inherit">{title}</Typography>
        </StyledLink>
        <OpenInNewIcon
          fontSize="medium"
          sx={{
            pl: 1,
            color:"#616161",
          }}
        />
      </MenuItem>
      <CascadingMenu
        {...props}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        popupState={popupState}
      />
    </>
  );
}

function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = useContext(CascadingContext);
  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );

  return (
    <CascadingContext.Provider value={context}>
      <Menu
        {...props}
        PaperProps={{
          sx: [
            {
              backgroundColor: "#ECECEC",
              borderRadius: 0,
            },
          ],
        }}
        {...bindMenu(popupState)}
      />
    </CascadingContext.Provider>
  );
}

interface SubMenuLinkProps {
  menuTo?: string;
  subMenuTo: string;
  name: string;
}

const SubMenuLink: React.FC<SubMenuLinkProps> = (props: SubMenuLinkProps) => {
  const { menuTo = "", subMenuTo, name } = props;
  const to = subMenuTo ? menuTo + "/" + subMenuTo : menuTo;
  const theme = useTheme();
  return (
    <ListItemText>
      <StyledLink to={to}  sx={{
            "&:hover": {
                        color: theme.palette.primary.main,
                      },
          }}>
        <Typography variant="inherit">{name}</Typography>
      </StyledLink>
    </ListItemText>
  );
};

export interface MenuPopupStateProps {
  navItem: IAeronetNav;
  setActiveNavItem:any;
  activeNavItem:any;
}

const MenuPopupState: React.FC<MenuPopupStateProps> = (props) => {
  const { navItem ,setActiveNavItem,activeNavItem} = props;
  const popupState = usePopupState({ variant: "popover", popupId: navItem.id });
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      <MaterialLink
        sx={{
          textDecoration: "none",
          "&:hover": {
            color: theme.palette.primary.main,
          },
          "&:focus": {
            color: theme.palette.primary.main,
          },
           ...(activeNavItem === navItem.id ? {
            color:theme.palette.primary.main ,
          }:{
            color: popupState.isOpen  ? theme.palette.primary.main : grey[400],
          }),
          fontWeight: 400,
          fontSize: "0.95rem",
          [theme.breakpoints.up("sm")]: {
            lineHeight: "72px",
          },
          cursor: "pointer",
          // color: popupState.isOpen  ? theme.palette.primary.main : grey[400],
        }}
        underline="none"
        {...bindTrigger(popupState)}
        {...bindFocus(popupState)}
      >
        {navItem.name}
      </MaterialLink>
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {navItem.children.map((menuItem, index) => (
          <div key={index}>
            {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
              <CascadingSubmenu
                popupId="moreChoicesCascadingMenu"
                key={index}
                title={menuItem.name}
                to={menuItem.to}
              >
                {menuItem.subMenus.map((subMenu, index) => (
                  <CascadingMenuItem
                    key={index}
                    value={subMenu.to}
                    onClick={()=>{
                      setActiveNavItem(navItem.id)
                      popupState.close()
                      
                    }}
                  >
                    <SubMenuLink
                      menuTo={menuItem.to}
                      subMenuTo={subMenu.to}
                      name={subMenu.name}
                    />
                  </CascadingMenuItem>
                ))}
              </CascadingSubmenu>
            ) : (
              <CascadingMenuItem
                key={menuItem.id}
                value={menuItem.id}
                onClick={()=>{
                  setActiveNavItem(navItem.id)
                  popupState.close()
                  
                }}
                selected={
                  menuItem.to
                    ? location.pathname === "/app/" + menuItem.to
                    : false
                }
              >
                {menuItem.to ? (
                  <ListItemText>
                    <StyledLink sx={{
                       "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.main,
                      },
                    }} to={menuItem.to}>
                      <Typography variant="inherit">{menuItem.name}</Typography>
                    </StyledLink>
                  </ListItemText>
                ) : (
                  <>
                    <ListItemText>
                      <MaterialLink
                        href={menuItem.url}
                        sx={{
                          textDecoration: "none",
                          fontWeight: 400,
                          color: "#616161",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                          
                        }}
                        underline="none"
                        target="_blank"
                      >
                        <Typography variant="inherit">
                          {menuItem.name}
                        </Typography>
                      </MaterialLink>
                    </ListItemText>
                  </>
                )}
              </CascadingMenuItem>
            )}
          </div>
        ))}
      </CascadingMenu>
    </>
  );
};

export default MenuPopupState;
