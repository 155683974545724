import React, { useCallback, useMemo } from 'react'
import { AeronetV6ItemsResponse, AeronetViewProps } from '../types'
import AeronetDataGrid, { QueryResults } from '../components/datagrid/DataGrid';
import { GridColDef, GridSortItem, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { AxiosResponse } from 'axios';
import { InvoiceList } from './types';
import { axios } from '../api';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useGlobalConfig } from '../hooks/useGlobalConfig';
import { parseISO,format } from 'date-fns';


interface InvoiceDataGridProps extends AeronetViewProps{
  invoiceConfig:any;
  canCreateInvoice:boolean;
  canDeleteInvoice:boolean;
}

const InvoiceDataGrid:React.FC<InvoiceDataGridProps> = (props) => {
  const {invoiceConfig,canCreateInvoice} = props
  const {enqueueSnackbar} = useSnackbar()
  // const [invoice,SetInvoice] = useState<InvoiceList[]>([])
  const currentUser = useCurrentUser()
  const globalConfig = useGlobalConfig();
  const navigate = useNavigate()
  const columns: GridColDef[] = useMemo(()=> [
    {
      headerName:"ID",
      field:"id",
      flex:1
    },
    {
      headerName:"Invoice To",
      field:"client_name",
      flex:1
    },
    {
      headerName:"Created",
      field:"date_created",
      flex:1,
      valueGetter: ({ value }) => value,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value && globalConfig) {
            return format(parseISO(params.value), globalConfig.date_format);
          }
          return params.value;
        },
    },
    {
      headerName:"Status",
      field:"status_id",
      flex:1,
      valueFormatter: (params: GridValueFormatterParams) => {
        try {
          if (invoiceConfig) {
            return invoiceConfig.status[
              Number(params.value as number)
            ].toUpperCase();
          }
        } catch {
          return params.value;
        }
      },
      valueGetter: (params) => params.row?.status_id,
    },
    {
      headerName:"Order Number",
      field:"purchase_order",
      flex:1
    },
    {
      headerName: "Division",
      field: "division",
      flex: 1,
      valueGetter: (params) => params.row?.division_obj?.name,
    },
    {
      headerName:"Created By",
      field:"created_by_user.name",
      flex:1,
      valueGetter: (params) => params.row?.created_by_user?.name
    },
    {
      headerName:"Reference",
      field:"reference",
      flex:1,
    },
    {
      headerName:"Total",
      field:"total",
      flex:1,
    },
    {
      headerName: "Currency",
      field: "currency",
      flex: 0.5,
    },
    {
      headerName: "Print",
      field: "pdf",
      flex:0.5,
      renderCell: (params) => {
        return (
          <Button
            className="pdf-icon-button"
            
          >
            <PictureAsPdfIcon />
          </Button>
        );
      },
    },
  ],[invoiceConfig,globalConfig])


const getData = useCallback(
  async(params:any,axiosConfig:any)=>{
    try {
      const resp: AxiosResponse<AeronetV6ItemsResponse<InvoiceList>> =
        await axios.get("/v6/invoice", {
          params: params,
          paramsSerializer: (params) => queryString.stringify(params),
          ...axiosConfig,
        });
     
      return {
        total: resp.data.total,
        rows: resp.data.items,
      } as QueryResults<InvoiceList>;
    } catch (e) {
      // @ts-ignore
      if (!axios.isCancel(e)) {
        enqueueSnackbar("Error fetching sors", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
      throw e;
    }
},[enqueueSnackbar])

const onRowClick = useCallback(
  (param: any, event: any) => {

    navigate(String(param.row.id));
  },
  [navigate]
);


const initialSort: GridSortItem[] = useMemo(
  () => [{ field: "id", sort: "desc" }],
  []
);

  return (
   <>
   <AeronetDataGrid
   columns={columns}
   data={getData}
   initialSort={initialSort}
   currentUserId={currentUser?.id}
   enableCreate = {canCreateInvoice}
   name="invoice-data-grid"
   initialState={{
    columns: {
      columnVisibilityModel: false,
    },
  }}
  onRowClick={onRowClick}
   />
   </>
  )
}

export default InvoiceDataGrid