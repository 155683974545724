import { Grid, TextField as MUITextField } from "@mui/material";
import MuiAutocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  AutocompleteProps,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { JobTask } from "../tasks/JobTaskService";
import { ITask } from "../tasks/types";

export interface TaskAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label?: string;
}
const FormikTaskAutoComplete = <
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: TaskAutocompleteProps<ITask, Multiple, DisableClearable, FreeSolo>
) => {
  const { form, label = "Task" } = props;
  const [tasks, setTasks] = useState<ITask[]>([]);
  const { name } = fieldToTextField(props as any);

  useEffect(() => {
    let active = true;
    form.setFieldValue("task_id", "");
    form.setFieldValue("task", "");
    
    if (parseInt(form.values.job_id)) {
      JobTask.query(form.values.job_id)
        .then((tasks) => {
          if (active) {
            setTasks(tasks);
          }
        })
        .catch((error) => {
          enqueueSnackbar(`Error fetching tasks`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [form.values.job_id, form.setFieldValue]);
  return (
    <>
      <MuiAutocomplete
        {...fieldToAutocomplete(props)}
        fullWidth
        options={tasks}
        autoComplete
        includeInputInList
        onChange={(_event, newValue) => {
          form.setFieldValue("task", newValue);
          if (typeof newValue === "string") {
            form.setFieldValue("task_id", newValue);
          } else {
            // @ts-ignore
            form.setFieldValue("task_id", newValue && newValue.id ? newValue.id : "");
          }
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.title;
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <MUITextField
            {...params}
            error={form.touched[name!] && !!form.errors[name!]}
            // @ts-ignore
            helperText={form.touched[name!] && form.errors[name!]}
            label={label}
          />
        )}
        renderOption={(props, option, state) => {
          const matches = match(`${option.id}`!, state.inputValue);
          const jobs = parse(`${option.id}`!, matches);

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {jobs.map((job, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: job.highlight ? 700 : 400,
                      }}
                    >
                      {job.text}
                    </span>
                  ))}
                  {option?.title || null}
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
};
export default FormikTaskAutoComplete;
