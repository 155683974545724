import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { ActionType, Actions } from "./DataGrid";

interface ActionMenuButtonProps {
  onClick: (event: any) => any;
}

const ActionMenuButton: React.FC<ActionMenuButtonProps> = (
  props: ActionMenuButtonProps
) => {
  const { onClick } = props;

  return (
    <Button
      aria-controls="options-menu"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        color: { sm: "white" },
        mx: { xs: 1, sm: 1.5 },
        mt: 0.5,
        backgroundColor: { sm: grey[500] },
        "&:hover": { backgroundColor: { sm: grey[700] } },
      }}
      variant="contained"
      endIcon={<KeyboardArrowDownIcon />}
    >
      ACTIONS
    </Button>
  );
};

export interface ActionMenuItem {
  name: string;
  namePlural?: string;
  action?: [string, string];
  to?: string;
  onClick?: (arg0?: any) => void;
  icon?: any;
  requiresSelection?: boolean;
  multipleSelection?: boolean;
  isReturnDataSelection?: boolean;
}

interface ActionMenuProps {
  actions?: ActionMenuItem[];
  setAction: (action: ActionType) => void;
  setSelected?: (arg0: any) => void;
  setSelectionModel?: (arg0: any) => void;
}

export function ActionMenu(props: ActionMenuProps) {
  const { actions } = props;
  const apiRef = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "action-menu",
  });

  if (actions == null || actions.length === 0) {
    return null;
  }

  const onClick = (action: ActionMenuItem) => {
    popupState.close();
    // get the first selected row
    const selected = apiRef.current.getSelectedRows().values().next().value;

    if (action.requiresSelection && !selected) {
      enqueueSnackbar("Select a row to continue", {
        variant: "warning",
      });
      return;
    }

    if (
      !action.multipleSelection &&
      apiRef.current.getSelectedRows().size > 1
    ) {
      enqueueSnackbar("Using first selected row", {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    if (action.action) {
      if (action.multipleSelection) {
        if (props.setSelected) {
          props.setSelected(apiRef.current.getSelectedRows().values());
        }
        props.setAction(Actions[action.action[0]]);
      } else {
        if (props.setSelected) {
          props.setSelected(selected);
        }
        props.setAction(Actions[action.action[0]]);
      }
      if ((action.name === "Delete" || action.isReturnDataSelection) && action.onClick) {
        const payload = {
          selectedRows: apiRef.current.getSelectedRows().values(),
          setSelectionModel: props.setSelectionModel,
          apiRef: apiRef,
        };
        action.onClick(payload);
      }
    }
  };

  return (
    <div>
      <ActionMenuButton {...bindTrigger(popupState)} />
      <Menu  {...bindMenu(popupState)} >
        {actions.map((action, index) => {
          if (action.action) {
            return (
              <MenuItem
              sx={{
                backgroundColor:grey[100],
                "&:hover": {
                  backgroundColor: "rgba(212, 224, 225) !important",
                  color: theme.palette.primary.main,
              },
              }}
                onClick={() => {
                  onClick(action);
                }}
                key={index}
              >
                {action.icon ? (
                  <ListItemIcon>
                    <action.icon fontSize="small" />
                  </ListItemIcon>
                ) : (
                  <></>
                )}
                {action.multipleSelection &&
                apiRef.current.getSelectedRows().size > 1 &&
                action.namePlural
                  ? action.namePlural
                  : action.name}
              </MenuItem>
            );
          } else if (action.to != null) {
            return (
              <MenuItem
              sx={{
                backgroundColor:grey[100],
                "&:hover": {
                  backgroundColor: "rgba(212, 224, 225) !important",
                  color: theme.palette.primary.main,
              },
              }}
                onClick={() => popupState.close()}
                component={Link}
                to={action.to}
                key={index}
              >
                {action.name}
              </MenuItem>
            );
          } else if (action.onClick) {
            return (
              <MenuItem
              sx={{
                backgroundColor:grey[100],
                "&:hover": {
                  backgroundColor: "rgba(212, 224, 225) !important",
                  color: theme.palette.primary.main,
              },
              }}
                onClick={() => {
                  if (action.onClick) {
                    action.onClick();
                  }
                }}
                key={index}
              >
                {action.icon ? (
                  <ListItemIcon>
                    <action.icon fontSize="small" />
                  </ListItemIcon>
                ) : (
                  <></>
                )}
                <ListItemText  primary={action.name} />
              </MenuItem>
            );
          }
          return "";
        })}
      </Menu>
    </div>
  );
}
