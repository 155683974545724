import {GridFilterPanel, GridLogicOperator} from '@mui/x-data-grid-premium'

const CustomFilterPanelPremium = () => {
  
  
    // const theme = createTheme(existingTheme,{
    //   components: {
    //     MuiFormControl: {
    //       styleOverrides: {
    //         root: {
    //           '&.MuiFormControl-marginDense': {
    //             margin: 0,
    //           },
    //           '&.MuiFormControl-fullWidth': {
    //             width: 'auto',
    //           },
    //         },
    //       },
    //     },
    //     MuiTextField: {
    //       styleOverrides: {
    //         root: {
    //           '&.css-997z5c-MuiFormControl-root-MuiTextField-root': {
    //             // remove the styles for this class
    //             all: 'unset',
    //           },
    //         },
    //       },
    //     },
    //     MuiDataGrid: {
    //         styleOverrides: {
    //           panelWrapper: {
    //             backgroundColor: grey[300],
    //             fontSize: '0.875rem', 
    //             '& *': {
    //               fontSize: 'inherit', 
    //             },
    //           },
    //           filterForm: {
    //             '& .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiDataGrid-filterFormColumnInput.css-1lmxnmh-MuiFormControl-root-MuiDataGrid-filterFormColumnInput': {
    //               margin: '0px 10px', 
    //             }, 
    //             '& .css-prnska-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput.MuiFormControl-marginDense': {
    //               margin: '0px 10px', 
    //             },
    //           },
    //         },
    //       },
    //   },
    // });
    
    return (
     <>
      {/* <ThemeProvider theme={theme}>
        <GridFilterPanel filterFormProps={{
          logicOperatorInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          columnInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          operatorInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          valueInputProps: {
            InputComponentProps: {
              variant: 'outlined',
              size: 'small',
            },
          },
        
        }} sx={{
            // Customize inputs using css selectors
            '& .MuiDataGrid-filterForm': { p: "10px 45px" ,
            backgroundColor: "#eeeeee",
           
          },
        "& .MuiDataGrid-filterForm button.MuiButtonBase-root.MuiButton-root" :{
           marginLeft:"45px"
          },
          '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
          '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
          '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
          '& .MuiDataGrid-filterFormValueInput': { width: 200 },
          '& .MuiFormControl-root': {
          
            '&.MuiFormControl-fullWidth': {
              width: 'auto', // Adjust to 'auto' to handle responsive width better
            },
          },
         
          '& .MuiDataGrid-panelFooter':{
            backgroundColor: "#eeeeee",
          },
          "& .MuiButtonBase-root.MuiButton-root":{
            marginLeft:"45px",
            marginRight:"45px",
            marginBottom:"15px"
          }
        
        }}  
        columnsSort="asc"
        logicOperators={[GridLogicOperator.And]}
        />
      </ThemeProvider> */}
       <GridFilterPanel  filterFormProps={{
          logicOperatorInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          columnInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          operatorInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          valueInputProps: {
            InputComponentProps: {
              variant: 'outlined',
              size: 'small',
            },
          },
        
        }} sx={{
            // Customize inputs using css selectors
            '& .MuiDataGrid-filterForm': { p: "10px 45px" ,
            backgroundColor: "#eeeeee",
           
          },
        "& .MuiDataGrid-filterForm button.MuiButtonBase-root.MuiButton-root" :{
           marginLeft:"45px"
          },
          '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
          '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
          '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
          '& .MuiDataGrid-filterFormValueInput': { width: 200 },
          '& .MuiFormControl-root': {
          
            '&.MuiFormControl-fullWidth': {
              width: 'auto', // Adjust to 'auto' to handle responsive width better
            },
          },
         
          '& .MuiDataGrid-panelFooter':{
            backgroundColor: "#eeeeee",
          },
          "& .MuiButtonBase-root.MuiButton-root":{
            marginLeft:"45px",
            marginRight:"45px",
            marginBottom:"15px"
          }
        
        }}  
        columnsSort="asc"
        logicOperators={[GridLogicOperator.And]}
        />
     </>
    );
  };


export default CustomFilterPanelPremium