import React, {  SetStateAction, useEffect, useMemo, useState } from 'react'
import { axios } from '../api'
import { AxiosResponse } from 'axios'
import { DataGridPremium, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium'
import CustomFilterPanelPremium from '../components/CustomFilterPanelPremium'
import qs from 'qs'
import { AeronetV6ItemsResponse } from '../types'
import { format } from 'date-fns'

interface CustomerSign {
    username:string | null;
    count: number;
    id:number
   }
const CustomerSignInReport = () => {
    const [customer,setCustomer] = useState<CustomerSign[]>([])
  const [loading,setLoading] = useState(true)

  useEffect(()=>{
    const params = {
      sort: "-id"
    };
    axios({
      method: 'GET',
      url:"/v6/users/user_logs/",
      id:"get-customer-signin",
      params: { ...params },
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
    }).then((resp:AxiosResponse<AeronetV6ItemsResponse<CustomerSign>>)=>{
      setCustomer(resp.data.items as SetStateAction<CustomerSign[]>)
      setLoading(false)
     axios.storage.remove("get-customer-signin")
    })
  },[])


const columns: GridColDef[] = useMemo(
    ()=>[
        {
            headerName: "Id",
            field: "id",
            flex: 0.5,
        },  
        {
          headerName:"Last Login",
          field:"last_login",
          flex:1,
          valueGetter:(params,row)=>{
            return row?.last_login
          },
          valueFormatter:(params,row)=>{
            return params!== "0000-00-00 00:00:00" ? format(new Date(params),"dd-MMM-yyyy") :""
          }
        },
        {
            headerName: "User Name",
            field: "username",
            flex: 0.5,
        },  
        {
            headerName: "Company",
            field: "company_name",
            flex: 0.5,
        },
    ],[]
)

const customToolbar = (props)=>{
    return (
        <>
         <GridToolbarContainer >
      <GridToolbarExport {...props} />
      <GridToolbarFilterButton/>
    </GridToolbarContainer>
        </>
    )
}

  return (
    <>
      <DataGridPremium
        autoHeight
        checkboxSelection
        slots={{ toolbar: customToolbar , 
        filterPanel:CustomFilterPanelPremium}}
        slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
        columns={columns}
        rows={customer}
        sx={{ flex: 1, margin: "20px" }}
        disableRowSelectionOnClick
        hideFooter
        ignoreValueFormatterDuringExport
        loading= {loading}
            />
    </>
  )
}

export default CustomerSignInReport