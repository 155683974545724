import { useEffect, useState } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { RouteLoading } from "../components/Loading";
import { useConfig } from "../hooks/useConfig";
import { useCurrentUser } from "../hooks/useCurrentUser";

import PartsItemsDataGrid from "./parts/DataGrid";
import PartDetail from "./parts/PartDetail";

import type { AeronetViewProps } from "../types";
import ComponentLayout from "./ComponentLayout";
import FlyingItemsDataGrid from "./flying/DataGrid";
import RemovedItemsDataGrid from "./removed/DataGrid";
import RotableItemsDataGrid from "./rotable/DataGrid";
import RetiredItemsDataGrid from "./retired/DataGrid";
import BasicImport from "./parts/BasicImport";

const Components: React.FC<React.PropsWithChildren<AeronetViewProps>> = (
  props
) => {
  let originalUrl = "";
  if (props.originalUrl) {
    let url = new URL("../index.html", props.originalUrl);
    url.search = "function=parts";
    originalUrl = url.href;
  }

  const currentUser = useCurrentUser();
  const partsConfig = useConfig("parts");
  const jobsEdit = useConfig("jobs", "Edit");
  const ordersEdit = useConfig("orders", "Edit");
  const quotesCreate = useConfig("quotes", "create");
  const quotesEdit = useConfig("quotes", "Edit");
  const [hasOrdersEditPermission, setHasOrdersEditPermission] = useState<
    boolean | undefined
  >();
  const [hasPartsCreatePermission, setHasPartsCreatePermission] = useState<
    boolean | undefined
  >();
  const [hasPartsEditPermission, setHasPartsEditPermission] = useState<
    boolean | undefined
  >();
  const [hasQuotesCreatePermission, setHasQuotesCreatePermission] = useState<
    boolean | undefined
  >();
  const [hasQuotesEditPermission, setHasQuotesEditPermission] = useState<
    boolean | undefined
  >();
  const [hasPartsIssuePermission, setHasPartsIssuePermission] = useState<
    boolean | undefined
  >();
  const [hasJobsEditPermission, setHasJobsEditPermission] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (ordersEdit && currentUser) {
      if (
        ordersEdit.Edit &&
        currentUser.permissions?.orders &&
        currentUser.permissions.orders.includes(ordersEdit.Edit)
      ) {
        console.debug("hasOrdersEditPermission = true");
        setHasOrdersEditPermission(true);
      } else {
        console.debug("hasOrdersEditPermission = false");
        setHasOrdersEditPermission(false);
      }
    }
  }, [currentUser, ordersEdit]);

  useEffect(() => {
    if (partsConfig && currentUser) {
      if (
        partsConfig.create &&
        currentUser.permissions?.parts &&
        currentUser.permissions.parts.includes(partsConfig.create)
      ) {
        console.debug("hasPartsCreatePermission = true");
        setHasPartsCreatePermission(true);
      } else {
        console.debug("hasPartsCreatePermission = false");
        setHasPartsCreatePermission(false);
      }

      if (
        partsConfig.Edit &&
        currentUser.permissions?.parts &&
        currentUser.permissions.parts.includes(partsConfig.Edit)
      ) {
        console.debug("hasPartsEditPermission = true");
        setHasPartsEditPermission(true);
      } else {
        console.debug("hasPartsEditPermission = false");
        setHasPartsEditPermission(false);
      }

      if (
        partsConfig.issue &&
        currentUser.permissions?.parts &&
        currentUser.permissions.parts.includes(partsConfig.issue)
      ) {
        console.debug("hasPartsIssuePermission = true");
        setHasPartsIssuePermission(true);
      } else {
        console.debug("hasPartsIssuePermission = false");
        setHasPartsIssuePermission(false);
      }
    }
  }, [partsConfig, currentUser]);

  useEffect(() => {
    if (quotesCreate && currentUser) {
      if (
        quotesCreate.create &&
        currentUser.permissions?.quotes &&
        currentUser.permissions.quotes.includes(quotesCreate.create)
      ) {
        console.debug("hasQuoteCreatePermission = true");
        setHasQuotesCreatePermission(true);
      } else {
        console.debug("hasQuoteCreatePermission = false");
        setHasQuotesCreatePermission(false);
      }
    }
  }, [quotesCreate, currentUser]);

  useEffect(() => {
    if (quotesEdit && currentUser) {
      if (
        quotesEdit.Edit &&
        currentUser.permissions?.quotes &&
        currentUser.permissions.quotes.includes(quotesEdit.Edit)
      ) {
        console.debug("hasQuotesEditPermission = true");
        setHasQuotesEditPermission(true);
      } else {
        console.debug("hasQuotesEditPermission = false");
        setHasQuotesEditPermission(false);
      }
    }
  }, [quotesEdit, currentUser]);

  useEffect(() => {
    if (jobsEdit && currentUser) {
      if (
        jobsEdit.Edit &&
        currentUser.permissions?.jobs &&
        currentUser.permissions.jobs.includes(jobsEdit.Edit)
      ) {
        console.debug("hasJobsEditPermission = true");
        setHasJobsEditPermission(true);
      } else {
        console.debug("hasJobsEditPermission = false");
        setHasJobsEditPermission(false);
      }
    }
  }, [jobsEdit, currentUser]);

  return (
    <>
      {props.children}
      {partsConfig !== undefined &&
      hasPartsCreatePermission !== undefined &&
      hasPartsEditPermission !== undefined &&
      hasPartsIssuePermission !== undefined &&
      hasJobsEditPermission !== undefined &&
      hasOrdersEditPermission !== undefined &&
      hasQuotesCreatePermission !== undefined &&
      hasQuotesEditPermission !== undefined ? (
        <Routes>
          <Route
            element={
              <ComponentLayout
                originalUrl={originalUrl}
                title={props.title}
               
              />
            }
          >
            <Route index element={<Navigate to="parts" />} />
            <Route
              path="parts"
              element={
                <PartsItemsDataGrid
                  title="Parts"
                  config={partsConfig}
                  originalUrl={originalUrl}
                  canCreatePart={hasPartsCreatePermission}
                  canIssuePart={hasPartsIssuePermission}
                  canOrderPart={hasOrdersEditPermission}
                  canRaiseDemand={hasJobsEditPermission}
                  canRFQ={hasQuotesCreatePermission || hasQuotesEditPermission}
                />
              }
            />
              <Route
              path="flying"
              element={
                <FlyingItemsDataGrid
                title="Flying"
                config={partsConfig}
                originalUrl={originalUrl}
                
                />
              }
            />
              <Route
              path="flying"
              element={
                <FlyingItemsDataGrid
                title="Flying"
                config={partsConfig}
                originalUrl={originalUrl}
                
                />
              }
            />
              <Route
              path="removed"
              element={
                <RemovedItemsDataGrid
                title="Removed"
                config={partsConfig}
                originalUrl={originalUrl}
                
                />
              }
            />
              <Route
              path="rotable"
              element={
                <RotableItemsDataGrid
                title="Rotable"
                config={partsConfig}
                originalUrl={originalUrl}
                
                />
              }
            />  
            <Route
              path="retired"
              element={
                <RetiredItemsDataGrid
                title="Retired"
                config={partsConfig}
                originalUrl={originalUrl}
                
                />
              }
            />
          </Route>
             <Route path="import/basic" element = {<BasicImport/>} />

          {/* Detail View */}
          <Route
            path="parts/:id"
            element={
              <PartDetail
                config={partsConfig}
                canSavePart={hasPartsEditPermission}
                originalUrl={originalUrl}
              />
            }
          />
          {/* Detail View */}
          {/* render base on permission */}
          {hasPartsCreatePermission ? (
            <Route
              path="parts/create"
              element={
                <PartDetail
                  config={partsConfig}
                  originalUrl={originalUrl}
                  canSavePart={true}
                />
              }
            />
          ) : null}
        
        </Routes>
      ) : (
        <RouteLoading />
      )}
    </>
  );
};

export default Components;
