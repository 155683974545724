import {Grid, Skeleton, TextField as MUITextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";

import BusinessIcon from "@mui/icons-material/Business";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {AutocompleteProps, AutocompleteRenderInputParams, fieldToAutocomplete, fieldToTextField,} from "formik-mui";
import {useSnackbar} from "notistack";
import type {AeronetType} from "./types";
import {TypeService} from "./TypeService";

export interface TypeAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  parent_id: number;
  label?: string;
}

export function TypeAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: TypeAutocompleteProps<AeronetType, Multiple, DisableClearable, FreeSolo>
) {
  const {
    parent_id,
    label,
    ...autocompleteProps
  } = props;
  const [types, setTypes] = useState<AeronetType[]>();
  let isRendered = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { name } = fieldToTextField(props as any);

  useEffect(() => {
    isRendered.current = true;
    TypeService.query(parent_id)
      .then((resp) => {
        if (isRendered.current) {
          setTypes(resp);
        }
      })
      .catch(() => {
        enqueueSnackbar("Error fetching types", {
          variant: "error",
        });
      });

    return () => {
      isRendered.current = false;
    };
  }, [enqueueSnackbar, parent_id]);

  return (
    <>
      {types ? (
        <MuiAutocomplete
          {...fieldToAutocomplete(autocompleteProps)}
          fullWidth
          autoComplete
          includeInputInList
          limitTags={3}
          options={types}
          disableCloseOnSelect={!!props.multiple}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            } else {
              if (option.name) {
                return option.name;
              } else {
                const type = types.find((d) => option.id === d.id);
                return type?.name || "";
              }
            }
          }}
          isOptionEqualToValue={(
            option: AeronetType,
            value: AeronetType
          ) => {
            try {
              if (option.id === value.id) {
                if (!Object.prototype.hasOwnProperty.call(value, "name")) {
                  value.name = option.name;
                }
                return true;
              }
              // @ts-ignore
              if (value === "") {
                return true;
              }
              return false;
            } catch (e) {
              return false;
            }
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MUITextField
              {...params}
              label={label ?? "Type"}
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
              error={props.form.touched[name!] && !!props.form.errors[name!]}
              // @ts-ignore
              helperText={props.form.touched[name!] && props.form.errors[name!]}
              variant="outlined"
            />
          )}
          renderOption={(props, option: AeronetType, state) => {
            const matches = match(option.name, state.inputValue);
            const types = parse(option.name, matches);

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <BusinessIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {types.map((type, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: type.highlight ? 700 : 400,
                        }}
                      >
                        {type.text}
                      </span>
                    ))}
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      ) : (
        <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
      )}
    </>
  );
}

TypeAutocomplete.displayName = "FormikMaterialUITypeAutocomplete";
