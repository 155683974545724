import React, { useEffect, useState } from 'react'
import { useConfig } from '../hooks/useConfig'
import { AeronetViewProps } from '../types'
import { getOriginalUrl } from '../utils/originalUrl'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { Route, Routes } from 'react-router-dom'
import InvoiceList from './InvoiceList'
import InvoiceDetail from './InvoiceDetail'

const Invoices: React.FC<React.PropsWithChildren<AeronetViewProps>>  = (props) => {
  const { originalUrl: domain, ...otherProps } = props;
  const originalUrl = getOriginalUrl(domain, "function=invoices");
  const invoiceConfig = useConfig('invoices')
  const [hasInvoiceCreatePermission, sethasInvoiceCreatePermission] = useState<boolean>(false);
  const [hasInvoiceEditPermission, sethasInvoiceEditPermission] = useState<boolean>(false);
  const [hasInvoiceDeletePermission, sethasInvoiceDeletePermission] = useState<boolean>(false);
  const currentUser = useCurrentUser()
  
  useEffect(() => {
    if (invoiceConfig && currentUser) {
      if (
        invoiceConfig.create &&
        currentUser.permissions?.quotes &&
        currentUser.permissions.quotes.includes(invoiceConfig.create)
      ) {
        sethasInvoiceCreatePermission(true);
      } else {
        sethasInvoiceCreatePermission(true);
      }
      if (
        invoiceConfig.Edit &&
        currentUser.permissions?.quotes &&
        currentUser.permissions.quotes.includes(invoiceConfig.Edit)
      ) {
        sethasInvoiceEditPermission(true);
      } else {
        sethasInvoiceEditPermission(true);
      }

      if (
        invoiceConfig.delete &&
        currentUser.permissions?.quotes &&
        currentUser.permissions.quotes.includes(invoiceConfig.delete)
      ) {
        sethasInvoiceDeletePermission(true);
      } else {
        sethasInvoiceDeletePermission(false);
      }
    }
  }, [currentUser, invoiceConfig]);
  return (
    <>

    <Routes>
      {
        hasInvoiceEditPermission ? (
          <Route path=':id' element = {<InvoiceDetail originalUrl={originalUrl} />}/>
        
        ):null
      }
      {
        hasInvoiceCreatePermission ? (
         
          <Route path='/create' element = {<InvoiceDetail originalUrl={originalUrl} />}/>
        ):null
      }
     <Route
     path= "*"
     element = {
      <InvoiceList
      originalUrl={originalUrl}
      invoiceConfig = {invoiceConfig}
      canCreateInvoice={hasInvoiceCreatePermission}
      canDeleteInvoice={hasInvoiceDeletePermission}
      {...otherProps}
      />

     }
     />
    </Routes>

    </>
  )
}

export default Invoices