import { Quote } from "../quotes/types";
import { OrderType } from "../orders/types";
import { IAircraftSelect } from "../aircraft/types";
import { IDivision, IJob } from "./types";

import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import { Field, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { enqueueSnackbar } from "notistack";
import { axios } from "../api";
import { CancelButton } from "../components/Buttons";
import { handleAPIErrors } from "../utils/FormikErrors";
import { CompanyAutocomplete } from "../companies/FormikCompanyAutocomplete";

interface FormikAddJobProps {
  jobConfig: { [key: string]: any } | undefined;
  quote?: Quote & {
    deliver: { id: string; name: string };
  };
  aircraft?: IAircraftSelect[];
  isAutoCreateJob?: boolean;
  isCreateJob?: boolean;
  setValue: (value: IJob | "") => void;
}

export const FormikAddJob: React.FC<FormikAddJobProps> = ({
  jobConfig,
  quote,
  isAutoCreateJob,
  isCreateJob,
  setValue,
}) => {
  const isRendered = useRef<boolean>(false);
  const [divisions, setDivisions] = useState<IDivision[]>([]);
  const formRef = useRef<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initJob: IJob & { deliver: any } = {
    account_status: "",
    active: true,
    address_line1: "",
    address_line2: "",
    address_line3: "",
    aircraft_id: "0",
    authorised_by: "0",
    authorised: "0",
    city: "",
    client_id: quote?.supplier_id?.toString() ?? "",
    deliver_id: quote?.deliver?.id ?? quote?.supplier_id?.toString() ?? "",
    company_id: quote?.company_id ?? 0,
    country: "",
    billing_date: "0000-00-00",
    date_due: new Date(),
    deliver_to: quote?.deliver?.name ?? quote?.supplier_name?.toString() ?? "",
    description: "",
    division: quote?.division_id ?? jobConfig!.default_division,
    exported: "",
    finish_time: "0000-00-00 00:00:00",
    job_condition: "",
    locked_by: "",
    manager_id: 0,
    name: "From Quote ID " + quote?.id?.toString() ?? "",
    parent_id: "0",
    priority: jobConfig!.default_priority,
    purchase_order: "",
    quote_id: quote?.id ?? 0,
    read_only: "",
    reference: quote?.customer_ref ?? "",
    start_time: "0000-00-00 00:00:00",
    status_id: quote?.status_id ?? jobConfig?.default_status ? 1 : 0,
    stock_qty: 0,
    template_id: 0,
    to_stock: 0,
    total: "0.00",
    lines: [],
    type: jobConfig?.default_type,
    warranty_id: 0,
    deliver: {
      id: parseInt(quote?.deliver?.id ?? quote?.supplier_id?.toString() ?? "") ?? 0,
      name: quote?.deliver?.name ?? quote?.supplier_name?.toString() ?? "",
    },
    comment: quote?.comment ?? "",
    note: quote?.description ?? "",
    terms: quote?.terms ?? "",
  };

  const [job] = useState<IJob>(initJob);

  const calculateToStockOptions = (job, jobConfig) => {
    let options = [
      { id: 0, name: "To Invoice" },
      { id: 3, name: "Overhaul" },
      { id: 4, name: "Close Cost" },
    ];

    if (
      job.to_stock === "2" ||
      job.to_stock === 2 ||
      jobConfig.show_to_stock_p148 === "yes"
    ) {
      options.push({ id: 2, name: "To Stock 148" });
    } else {
      const toStockLabel = jobConfig.to_stock_caption
        ? jobConfig.to_stock_caption
        : "To Stock";
      options.push({ id: 1, name: toStockLabel });
    }

    return options.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
  };

  const toStockOptions = useMemo(
    () => calculateToStockOptions(job, jobConfig),
    [job, jobConfig]
  );

  useEffect(() => {
    isRendered.current = true;

    axios
      .get("/v6/jobs/divisions")
      .then((resp) => {
        if (isRendered.current) {
          setDivisions(resp.data.data);
        }
        return null;
      })
      .catch((error) => {
        enqueueSnackbar(`Error fetching divisions `, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });

    return () => {
      isRendered.current = false;
    };
  }, []);

  useEffect(() => {
    if (isCreateJob && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [isCreateJob])

  return (
    <Formik
      innerRef={formRef}
      initialValues={job}
      onSubmit={async (values, { setErrors }) => {
        try {
          values["client_id"] = values["deliver"]?.id?.toString() ?? "";
          values["deliver_to"] = values["deliver"]?.name ?? "";
          const resp = await axios.post<IJob>("/v6/jobs", values);
          !isAutoCreateJob && enqueueSnackbar("Successfully created job", {
            variant: "success",
          });
          setValue(resp.data);
        } catch (err) {
          handleAPIErrors(err, setErrors);
          enqueueSnackbar("Error creating job", {
            variant: "error",
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        // this is intended to be nested in an existing form so we use a div here, forms in forms are invalid html
        <div data-testid="add-job-form">
            <Field type="hidden" name="client_id" />
            <Field
              autoFocus
              required
              fullWidth
              component={CompanyAutocomplete}
              companyType={OrderType.Customer}
              label="Client"
              name="deliver"
            />
            <Field
              fullWidth
              component={TextField}
              type="text"
              label="Job Name"
              name="name"
            />
            {jobConfig?.status ? (
              <>
                <Field
                  component={Select}
                  id="status"
                  name="status_id"
                  label="Status"
                >
                  {Object.entries(jobConfig.status).map(([key, value]) => (
                    <MenuItem value={key} key={key}>
                      {value as unknown as string}
                    </MenuItem>
                  ))}
                </Field>
                <br />
              </>
            ) : (
              <></>
            )}
            <Field
              component={Select}
              id="to_stock"
              name="to_stock"
              label="Closing Type"
            >
              {toStockOptions.map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Field>
            <br />
            <Field
              component={Select}
              id="division"
              name="division"
              label="Division"
            >
              {divisions.map((division) => (
                <MenuItem value={division.id} key={division.id}>
                  {division.name}
                </MenuItem>
              ))}
            </Field>
            <br />
            
            {/* <br />
            <Field
              component={Select}
              id="aircraft_id"
              name="aircraft_id"
              label="Aircraft Registration"
              displayEmpty
            >
              <MenuItem value="0">
                <em></em>
              </MenuItem>
              {aircraft?.length && aircraft.map((a) => (
                <MenuItem value={a.id} key={a.id}>
                  {a.registration}
                </MenuItem>
              ))}
            </Field> */}
          <br />
          { !isAutoCreateJob &&
            <Box
              flex="0 0 auto"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              p={1}
            >
              <CancelButton onClick={() => setValue("")} />
              <Button
                disabled={isSubmitting}
                // @ts-ignore
                onClick={(event) => handleSubmit(event)}
              >
                Add
              </Button>
            </Box>
          }
        </div>
      )}
    </Formik>
  );
};
