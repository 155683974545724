import { Suspense } from "react";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";

import Nav from "../app/Nav";
import Error from "./Error";
import { RouteLoading } from "./Loading";

import type { IAeronetNav } from "../app/types";

interface AppLayoutProps {
  nav: readonly IAeronetNav[];
}

const AppLayout: React.FC<AppLayoutProps> = ({ nav }) => {
  return (
    <Box textAlign="center" sx={{ backgroundColor: "white" }} data-testid="app" height="100%">
      <Nav nav={nav} />
      <Sentry.ErrorBoundary fallback={(props) => <Error {...props} />}>
        <Suspense fallback={<RouteLoading />}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </Box>
  );
};

export default AppLayout;
