import { AppBar,  Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
     {value === index && <>{children}</>}
    </div>
  );
};

export interface TabBarProps {
  value: number;
  onChange: (event: React.SyntheticEvent<{}>, value: unknown) => void;
  children?: React.ReactNode;
}

export const TabBar: React.FC<React.PropsWithChildren<TabBarProps>> = (
  props
) => (
  <AppBar
  position="static"
  sx={{
    paddingLeft: { xs: 2, sm: 6 },
    paddingRight: { xs: 2, sm: 6 },
    paddingTop: 3,
    backgroundColor: grey[100],
  }}
  elevation={0}
  data-testid="tab-bar"
  >
    <Tabs
      value={props.value}
      onChange={props.onChange}
      aria-label="tabs"
      indicatorColor="primary"
      textColor="primary"
      sx={{ "& .MuiTabs-indicator": { height: 5 } }}
    >
      {props.children}
    </Tabs>
  </AppBar>
);
