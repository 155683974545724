import React from 'react'
import { AeronetViewProps } from '../types'
import  { ListingHeader } from '../components/Header';
import InvoiceDataGrid from './InvoiceDataGrid';


interface InvoiceListProps extends AeronetViewProps{
    invoiceConfig:any;
    canCreateInvoice:boolean;
    canDeleteInvoice:boolean;
}

const InvoiceList:React.FC<InvoiceListProps> = (props) => {
    const {invoiceConfig,title,originalUrl,canCreateInvoice,canDeleteInvoice} = props
  return (
    <>
    <ListingHeader>{title}</ListingHeader>
    <InvoiceDataGrid
    invoiceConfig={invoiceConfig}
    originalUrl={originalUrl}
    title={title}
    canCreateInvoice={canCreateInvoice}
    canDeleteInvoice ={canDeleteInvoice}
    />
    
    </>
  )
}

export default InvoiceList