import * as React from "react";

import { Menu} from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import { unstable_useId as useId } from "@mui/utils";
import { gridClasses } from "@mui/x-data-grid-pro";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { CreateFab } from "../Buttons";

export const CreateMenuContainer = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function CreateMenuContainer(props, ref) {
  const { children, onClick, ...other } = props;
  const exportButtonId = useId();
  const exportMenuId = useId();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "export-menu",
  });

  if (children == null) {
    return null;
  }

  return (
    <React.Fragment>
      <CreateFab
        aria-label="Create New"
        aria-haspopup="menu"
        id={exportButtonId}
        {...bindTrigger(popupState)}
        {...other}
        key={1} 
        size="small"
      />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...bindMenu(popupState)}
      >
        <MenuList
          id={exportMenuId}
          className={gridClasses.menuList}
          aria-labelledby={exportButtonId}
          sx={{pt: 0, pb: 0}}
        >
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return React.cloneElement<any>(child, {
              hideMenu: popupState.close,
            });
          })}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
});



export const CreateMenuOption = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function CreateMenu(props, ref) {
  const { children } = props;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const fabRef = React.useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div>
      <CreateFab
      onClick={toggleMenu}
      size="small"
      />
      <CustomMenu
        isOpen={isMenuOpen}
        anchorRef={fabRef.current}
        onClose={closeMenu}
      >
        {children}
      </CustomMenu>
    </div>
  );
});


const CustomMenu   = ({ isOpen, anchorRef, onClose, children }) => {
  return (
    isOpen && (
      <ul
        style={{
          position: "absolute",
          top: anchorRef ? anchorRef.getBoundingClientRect().bottom : "50px",
          left: anchorRef ? anchorRef.getBoundingClientRect().left : "50px",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          listStyle: "none",
          padding: "8px 0",
          margin: 0,
          zIndex: 1000,
        }}
      >
        {React.Children.map(children, (child, index) => {
          return <>
          {child}
          </>
        })}
      </ul>
    )
  );
};

