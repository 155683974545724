import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

export interface ContentProps {
  "data-testid"?: string;
}

const Content: React.FC<React.PropsWithChildren<ContentProps>> = (props) => (
  <Box
  textAlign="left"
  sx={{
    backgroundColor: grey[100],
    minHeight: "100vh",
    pl: { xs: 2, sm: 6 },
    pr: { xs: 2, sm: 6 },
    pb: { xs: 2, sm: 6 },
  }}
    data-testid={props["data-testid"]}
  >
    {props.children}
  </Box>
);

export default Content;
