export enum DocumentAccess {
  Public = 1,
  Internal = 2,
  Protected = 3,
  Private = 4,
}

interface DocumentCompany {
  id: number;
  name: string | null;
}

interface DocumentUser {
  id: number;
  display_name: string | null;
}

interface Category {
  id: number;
  name: string | null;
}

interface DocumentBase {
  access: DocumentAccess | null;
  active: boolean;
  category: Category | null;
  chapter: Category | null;
  company: DocumentCompany | null;
  created_by: number | null;
  date_created: Date | string | null;
  date_modified: Date | string | null;
  date_uploaded: Date | string | null;
  document: string | null;
  expiry_date: Date | string | null;
  external_link: string | null;
  filename: string | null;
  location: string | null;
  locked_by: number | null;
  modified_by: number | null;
  number: string | null;
  parent_id: number | null;
  read_only: Date | string | null;
  responsibility: string | null;
  revision: string | null;
  section: Category | null;
  title: string | null;
  user: DocumentUser | null;
  version: string | null;
}

export interface DocumentList extends DocumentBase {
  id: number;
}

export interface Document extends DocumentBase {
  id: number;
}
