import React from 'react'
import { AeronetViewProps } from '../types';
import  { ListingHeader } from '../components/Header';
import DueDateDataGrid from './DueDateDataGrid';


interface DueDateListProps extends AeronetViewProps{
canCreateDuedate:boolean
canDeleteDuedate:boolean
}

const DueDateList:React.FC<DueDateListProps> = (props) => {
    const {title,originalUrl,canCreateDuedate,canDeleteDuedate} = props
  return (
    <>
    <ListingHeader>{title}</ListingHeader>
    <DueDateDataGrid
    originalUrl={originalUrl}
    title={title}
    canCreateDuedate= {canCreateDuedate}
    canDeleteDuedate = {canDeleteDuedate}
    />
    </>
  )
}

export default DueDateList