import { useEffect, useState } from "react";
import User from "../users/User";

export function useCurrentUser() {
  const [user, setUser] = useState<{ [key: string]: any }>();

  useEffect(() => {
    User.currentUser().then((user) => {
      setUser(user);
    });
  }, []);

  return user;
}
