import { Grid, TextField as MUITextField, Skeleton } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import MuiAutocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  fieldToAutocomplete,
  fieldToTextField,
} from "formik-mui";
import { useSnackbar } from "notistack";
import { axios } from "../api";
import queryString from "query-string";
import { DueDateList } from "./type";

export interface DueDateAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label?: string;
  duedate_id:number
}

export function DueDateAutocomplete<
  // eslint-disable-next-line
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: DueDateAutocompleteProps<DueDateList, Multiple, DisableClearable, FreeSolo>) {
  const { label,duedate_id,  ...autocompleteProps } = props;
  const [duedate, setDuedate] = useState<DueDateList[]>();
  let isRendered = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { name } = fieldToTextField(props as any);

  useEffect(() => {
    isRendered.current = true;
    const params = {
      filter: [JSON.stringify({ field: "active", op: "==", value: "1" })],
      size: 100,
      page: 1,
      sort: "id",
    };
 
    if (duedate_id > 0){
      params.filter.push(JSON.stringify({ field: "id", op: "!=", value: duedate_id}))
    }
   

    axios
      .get("/v6/duedate", {
        params: params,
        paramsSerializer: (params) => queryString.stringify(params),
        id:'get-duedate-list'
      })
      .then((resp) => {
        if (isRendered.current) {
          setDuedate(resp.data.data);
          axios.storage.remove('get-duedate-list')
        }
      })
      .catch(() => {
        enqueueSnackbar("Error fetching duedate", {
          variant: "error",
        });
      });

    return () => {
      isRendered.current = false;
    };
  }, [enqueueSnackbar,duedate_id]);

  return (
    <>
      {duedate ? (
        <MuiAutocomplete
          {...fieldToAutocomplete(autocompleteProps)}
          fullWidth
          autoComplete
          includeInputInList
          limitTags={3}
          options={duedate}
          disableCloseOnSelect={!!props.multiple}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            } else {
              if (option.id) {
                return String(option.id);
              } else {
                const user = duedate.find((d) => option.id === d.id);
                return String(user?.id) || "";
              }
            }
          }}
          isOptionEqualToValue={(option: DueDateList, value: DueDateList) => {
            try {
              if (option.id === value.id) {
                if (!Object.prototype.hasOwnProperty.call(value, "name")) {
                  value.id = option.id;
                }
                return true;
              }
              // @ts-ignore
              if (value === "") {
                return true;
              }
              return false;
            } catch (e) {
              return false;
            }
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MUITextField
              {...params}
              label={label}
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
              error={props.form.touched[name!] && !!props.form.errors[name!]}
              // @ts-ignore
              helperText={props.form.touched[name!] && props.form.errors[name!]}
              variant="outlined"
            />
          )}
          renderOption={(props, option: DueDateList, state) => {
            const matches = match(String(option.id), state.inputValue);
            const duedate = parse(String(option.id), matches);

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <BusinessIcon
                      sx={{
                        color: "text.secondary",
                        marginRight: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {duedate.map((user, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: user.highlight ? 700 : 400,
                        }}
                      >
                        {user.text}
                      </span>
                    ))}
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      ) : (
        <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
      )}
    </>
  );
}

DueDateAutocomplete.displayName = "FormikMaterialUIUserAutocomplete";
