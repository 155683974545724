import { Story, Meta } from "@storybook/react";

import Loading from "../../components/Loading";

export default {
  component: Loading,
} as Meta;

const Template: Story = (args) => <Loading {...args} />;
export const LoadingComponent = Template.bind({});
LoadingComponent.storyName = "View Loading";
LoadingComponent.parameters = {
  controls: { hideNoControlsWarning: true },
};
