import { SheetHeaderItem } from "../components/Sheet";

import type { BasePart } from "./types";

export interface PartActionHeaderProps {
  part?: BasePart;
}

const PartActionHeader: React.FC<PartActionHeaderProps> = (props) => {
  const { part } = props;

  return (
    <>
      {part ? (
        <>
          <SheetHeaderItem label="P/N" content={part.number}></SheetHeaderItem>
          <SheetHeaderItem
            label="Description"
            content={part.description}
          ></SheetHeaderItem>
        </>
      ) : null}
    </>
  );
};

export default PartActionHeader;
