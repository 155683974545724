import { useCallback, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  GridDensity,
  GridDensityOption,
  gridDensityValueSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import './displayPreferences.css';

interface DisplayPreferencesProps {
  onSubmit: () => void;
  open: boolean;
  onClose: () => void;
}
export const DisplayPreferences: React.FC<DisplayPreferencesProps> = (
  props
) => {
  const { onSubmit, open, onClose } = props;
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const densityValue = useGridSelector(apiRef, gridDensityValueSelector);

  const handleSubmitForm: React.FormEventHandler = (e) => {
    onSubmit();
    onClose();
    e.preventDefault();
  };

  const handlePageSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const pageSize = Number(event.target.value);
      apiRef.current.setPageSize(pageSize);
    },
    [apiRef]
  );

  const handleDensityUpdate = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      apiRef.current.setDensity(event.target.value as GridDensity);
    },
    [apiRef]
  );

  const densityOptions: GridDensityOption[] = [
    {
      icon: <rootProps.slots.densityCompactIcon />,
      label: apiRef.current.getLocaleText("toolbarDensityCompact"),
      value: 'compact'
    },
    {
      icon: <rootProps.slots.densityStandardIcon />,
      label: apiRef.current.getLocaleText("toolbarDensityStandard"),
      value: 'standard'
    },
    {
      icon: <rootProps.slots.densityComfortableIcon />,
      label: apiRef.current.getLocaleText("toolbarDensityComfortable"),
      value: 'comfortable'
    },
  ];

  return (
    <>
      <Dialog onClose={() => onClose()} open={open}>
        <form onSubmit={handleSubmitForm}>
          <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: 400 }}>
            Display Preferences
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel id="page-size-radio-buttons" className = 'text-display'>
                    Rows per page
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="page-size-radio-buttons"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    name="radio-buttons-group"
                  >
                    {rootProps.pageSizeOptions.map((pageSizeOption) => (
                      <FormControlLabel
                        key={pageSizeOption}
                        value={pageSizeOption}
                        control={<Radio />}
                        label={pageSizeOption}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel id="page-size-radio-buttons" className = 'text-display'>Density</FormLabel>
                  <RadioGroup
                    aria-labelledby="page-size-radio-buttons"
                    name="radio-buttons-group"
                    value={densityValue}
                    onChange={handleDensityUpdate}
                  >
                    {densityOptions.map<React.ReactElement>((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel id="page-size-radio-buttons" className = 'text-display'>Columns</FormLabel>
                  {/* <GridColumnsPanel sx={{ ml: -1 }} headerCheckboxSelection = {true} /> */}
                  <CustomPopupCheckboxes />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="contained"
      color="primary" type="submit">Save</Button>
           
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};


function CustomPopupCheckboxes(props) {
  const apiRef = useGridApiContext();

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columns, setColumns] = useState(apiRef.current.getAllColumns());
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    //@ts-ignore
    setVisibleColumns(apiRef.current.getVisibleColumns());
    setColumns(apiRef.current.getAllColumns())
  },[apiRef]);
  const handleToggleAllColumns = (showAll: boolean) => {
    columns.forEach((column) => {
      if (column.hideable) {
        apiRef.current.setColumnVisibility(column.field, showAll);
      }
    });
     //@ts-ignore
    setVisibleColumns(apiRef.current.getVisibleColumns());
  };

  const filteredColumns = columns.filter((column) => {
    //@ts-ignore
    const columnName = column.headerName.toLowerCase();
    const searchQueryLower = searchQuery.toLowerCase();
    return columnName.includes(searchQueryLower);
  }); 
  return (
    <>
    <TextField
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          label="Find column"
          placeholder="Column Title"
          variant="standard"
          fullWidth
          style={{ marginBottom: 16 }}
        />
       <div style={{ marginLeft: -5, maxHeight: 500, overflowY: 'auto' }}>
      { filteredColumns.length> 0&& filteredColumns.map((column) => {
        //@ts-ignore
        let isVisible = visibleColumns.filter((x) => x.field === column.field);
        return (
          <div key={column.field} style={{ marginBottom: -7, ...column.hideable ? {} : { display: "none" } }}>
            <Checkbox
              checked={isVisible.length !== 0 ? true : false}
              onChange={(e) => {
                apiRef.current.setColumnVisibility(
                  column.field,
                  e.target.checked
                );
                //@ts-ignore
                setVisibleColumns(apiRef.current.getVisibleColumns());
              }}
            />
            {column.headerName}
          </div>
        );
      })}
    </div>
    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
        <Button
          color="primary"
          onClick={() => handleToggleAllColumns(false)}
        >
          Hide all
        </Button>
        <Button
          color="primary"
          onClick={() => handleToggleAllColumns(true)}
        >
          Show all
        </Button>
      </div>
    </>
 
  );
}



