console.groupCollapsed(
  `                                                                                                                                            .t:
                                                                                                                                            :tt
                                                                                                                                            :tt
%c        ..........                .......                       .....         ........         %c         ..:::.              ..:::.          :tt
%c    .::aaaaaaaaaaaaaa::       .::eeeeeeeeeee::.     .rrrrrrrr..:rrrrrr.    ::oooooooooooo:.    %c  nn .n**n::::n*nn       .eeee::::e*ee.  :***ttt****:
%c   :aaaaaaaaaaaaaaaaaaaa.   .:eeeeeeeeeeeeeeeeee.   .rrrrrrrr:rrrrrrrr.  .ooooooooooooooooo:.  %c  nn:nn.        .nn.   .*ee.        .ee*.    :tt
%c  :aaaaaaaa:....:aaaaaaa:  .eeeeeeee::.::eeeeeeee:  .rrrrrrrrrrrrrrrrr. :ooooooooooooo::ooooo: %c  nnn.           :nn  .ee:            :e*    :tt
%c  .........     :aaaaaaa: .eeeeeeee.      :eeeeeee: .rrrrrrrrrr::...::.:oooooooooooo:.:ooooooo.%c  nn:             nn  ee:              :ee   :tt
%c      ..::::::aaaaaaaaaa: :eeeeeeeeeeeeeeeeeeeeeeee..rrrrrrrrr.       .ooooooooooo:..:oooooooo:%c  nn              nn .e*................e*   :tt
%c   ::aaaaaaaaaaaaaaaaaaa: :eeeeeeeeeeeeeeeeeeeeeeee..rrrrrrrr:        .ooooooooo:.  :ooooooooo:%c  nn              nn .ee*****************e   :tt
%c .aaaaaaaaaa:::.:aaaaaaa: :eeeeeeee:..:...::......:..rrrrrrrr.        .oooooo::.   :oooooooooo:%c  nn              nn .ee                     :tt
%c :aaaaaaaa:     :aaaaaaa: .eeeeeeee.       .......  .rrrrrrrr.         ::::..     .ooooooooooo.%c  nn              nn  ee.              .e:   :tt
%c :aaaaaaaa::...:aaaaaaaa:  .eeeeeeee::::::eeeeeeee. .rrrrrrrr.                    :oooooooooo. %c  nn              nn  .ee.            .ee.   :tt
%c .aaaaaaaaaaaaaaaaaaaaaaa   .:eeeeeeeeeeeeeeeeee:.  .rrrrrrrr.                    ooooooooo:.  %c  nn              nn   .ee:.        .:ee.    .tt.
%c  .:aaaaaaaaaaa:.aaaaaaaa.    ..:eeeeeeeeeeee::.    .rrrrrrrr.                   .oooooo::.    %c  nn              nn     :*e*e::::e*e*:       .*t***:
%c     .........    .......         ..........         ........                     .....        %c  ..              ..        .::::::.             ...`,
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: ",
  "color: #00afe9",
  "color: "
);
console.info(`Version: ${process.env.REACT_APP_VERSION}`);
console.info(`Build: ${process.env.REACT_APP_BUILD_VERSION}`);
console.groupEnd();
