import React from 'react'
import { AeronetViewProps } from '../types'
import { Route, Routes } from 'react-router-dom'
import { getOriginalUrl } from '../utils/originalUrl'
import InspectionList from './InspectionList'
import InspectionDetail from './InspectionDetail'

const Inspections: React.FC<React.PropsWithChildren<AeronetViewProps>> = (props)  => {
  const { originalUrl: domain, ...otherProps } = props;
  const originalUrl = getOriginalUrl(domain, "function=aircraft_inspections");
  return (
    <Routes>
      <Route
      path='/create'  element = {<InspectionDetail originalUrl={originalUrl} />}
      />
      <Route
      path=':id'  element = {<InspectionDetail originalUrl={originalUrl} />}
      />
     <Route
     path= "*"
     element = {
      <InspectionList
      originalUrl={originalUrl}
    {...otherProps}
      />

     }
     />
    </Routes>
  )
}

export default Inspections