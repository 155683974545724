import { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListSubheader,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import {
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridFilterModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { GridToolbarExportContainer } from "../../components/datagrid/GridToolbarExport";
import { useNavigate } from "react-router-dom";

import { SearchButton, SearchFab } from "../../components/Buttons";
import { ActionMenu } from "../../components/datagrid/ActionMenu";
import {
  AdvancedSearchToolbarProps,
  
} from "../../components/datagrid/AdvancedSearchToolbar";
import { Actions } from "../../components/datagrid/DataGrid";
import { DisplayPreferences } from "../../components/datagrid/DisplayPreferences";
import { SearchField } from "../../components/datagrid/SearchField";
import CreateComponent from "./CreateComponent";
import { CreateMenuContainer } from "../../components/datagrid/CreateMenu";



interface PartsToolbarProps extends AdvancedSearchToolbarProps {}

const GridToolbarExport = ({
  csvOptions,
  printOptions,
  setAction,
  
  ...other
}) => (
  <GridToolbarExportContainer {...other}>
  
        <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Export</b></ListSubheader>
        <GridCsvExportMenuItem options={csvOptions} />
        
       <GridPrintExportMenuItem options={printOptions} />
       <Divider />
       <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Settings</b></ListSubheader>
        <MenuItem
      onClick={() => {
        setAction(Actions.Settings);
      }}
     
    >
          Display
        </MenuItem>
  
  </GridToolbarExportContainer>
);



export default function PartsToolbar(props: PartsToolbarProps) {
  const {
    action,
    actions,
    clearSearch,
    csvOptions,
    defaultItems,
    enableAdvancedSearch,
    enableCreate,
    enableFilter,
    exportState,
    onChange,
    onDelete,
    printOptions,
    quickSearchItems,
    selectionModel,
    setAction,
    setSelected,
    setSelectionModel,
    value,
    ...other
  } = props;
  let navigate = useNavigate();
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const filterModel = gridFilterModelSelector(apiRef);
  const [filtersActive, setFiltersActive] = useState(false);

  const [createStockOpen, setCreateStockOpen] = useState(false);

  const CreateMenu = ({
  
    csvOptions,
    printOptions,
    setAction,
    
    ...other
    
  }) => (
    <CreateMenuContainer {...other}>
    
          <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Add New</b></ListSubheader>
          <MenuItem
        onClick={() => {
          navigate("create")
        }}
       
      >
            New Part
          </MenuItem>
          <MenuItem
        onClick={() => {
          setCreateStockOpen(true)
        }}
       
      >
            New Component
          </MenuItem>
          <ListSubheader style={{color:"black" ,fontSize:"1rem"}}><b>Import</b></ListSubheader>
          <MenuItem
        onClick={() => {
          navigate("/components/import/basic")
        }}
       
      >
            Import Basic
          </MenuItem>
          <MenuItem
        onClick={() => {
          // navigate("/components/import/component")
        }}
       
      >
            Import Calm
          </MenuItem>
        
         
    </CreateMenuContainer>
  );

  useEffect(() => {
    if (JSON.stringify(defaultItems) === JSON.stringify(filterModel.items)) {
      setFiltersActive(false);
    } else {
      setFiltersActive(true);
    }
  }, [defaultItems, filterModel]);

  return (
    <>
      <GridToolbarContainer
        sx={{
          padding: (theme) => ({
            xs: theme.spacing(0.5, 1, 1),
            md: enableCreate
              ? theme.spacing(1.5, 6, 2, 6)
              : theme.spacing(1.5, 6, 2),
          }),
        }}
        {...other}
      >
        <>
          {selectionModel && selectionModel.length > 0 && onDelete ? (
            <IconButton
              aria-label="delete"
              onClick={() => {
                onDelete(selectionModel);
                setSelectionModel([]);
                for (const rowId of selectionModel) {
                  apiRef.current.updateRows([{ id: rowId, _action: "delete" }]);
                }
              }}
              sx={{
                position: "absolute",
                top: theme.spacing(2.5),
                left: theme.spacing(6.5),
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <>
              {enableCreate && (
               <CreateMenu
                  csvOptions={csvOptions}
                  printOptions={printOptions}
                  setAction={setAction}
                  {...other}
                />
              )}
            </>
          )}
          <ActionMenu
            actions={actions}
            setAction={setAction}
            setSelected={setSelected}
          />
          <SearchField
            value={value}
            onChange={onChange}
            clearSearch={clearSearch}
            items={quickSearchItems}
          />
          <Box sx={{ flex: 1 }} />
          <>
            {isSmall ? (
              <>
                {enableAdvancedSearch ? (
                  <SearchFab
                    onClick={() => {
                      props.setAction(Actions.AdvancedSearch);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <>
                {enableAdvancedSearch ? (
                  <>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={!filtersActive}
                    >
                      <SearchButton
                        onClick={() => {
                          props.setAction(Actions.AdvancedSearch);
                        }}
                      />
                    </Badge>
                  </>
                ) : (
                  <></>
                )}
                {enableFilter ? (
                  <GridToolbarFilterButton sx={{ color: grey[500] }} />
                ) : (
                  <></>
                )}
               
               <GridToolbarExport
                  csvOptions={csvOptions}
                  printOptions={printOptions}
                  setAction={setAction}
                 
                  {...other}
                />
              </>
            )}
          </>
          {action === Actions.Settings ? (
            <DisplayPreferences
              onSubmit={exportState}
              open={true}
              onClose={() => {
                setAction(Actions.None);
              }}
            />
          ) : (
            <></>
          )}
        </>
      </GridToolbarContainer>
      {createStockOpen && (
        <CreateComponent
          open={createStockOpen}
          onClose={() => setCreateStockOpen(false)}
        />
      )}
    </>
  );
}
