import { MutableRefObject, lazy, useCallback, useMemo, useState } from "react";

import { Link as MUILink } from "@mui/material";
import {
  GridApi,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridSortItem,
  GridValidRowModel,
  GridValueFormatterParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { AxiosResponse } from "axios";
import { CacheRequestConfig } from "axios-cache-interceptor";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { To, createSearchParams, useNavigate } from "react-router-dom";
import { ArrayParam, encodeQueryParams } from "use-query-params";

import { axios } from "../../api";
import AeronetDataGrid from "../../components/datagrid/DataGrid";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useDivisionOptions } from "../../hooks/useDivisionOptions";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import { useGlobalRounding } from "../../hooks/useGlobalRounding";
import { useLocationOptions } from "../../hooks/useLocationOptions";
import { usePartsConditionOptions } from "../../hooks/usePartsConditionOptions";
import { usePartsGroupOptions } from "../../hooks/usePartsGroupOptions";
import { usePartsStoreOptions } from "../../hooks/usePartsStoreOptions";
import { useRenderLink } from "../../hooks/useRenderLink";
import { isPart } from "../../parts/utils";
import PartsToolbar from "./PartsToolbar";

import type { ActionMenuItem } from "../../components/datagrid/ActionMenu";
import type { QueryResults } from "../../components/datagrid/DataGrid";
import type { IPartList } from "../../parts/types";
import type { AeronetV6ItemsResponse, AeronetViewProps } from "../../types";
import type { ItemList } from "../types";
import { RaiseDemandTypeEnum } from "../../parts/RaiseDemand";

const IssuePart = lazy(() => import("../../parts/IssuePart"));
const ItemsAdvancedSearchForm = lazy(() => import("./AdvancedSearchForm"));
const OrderPart = lazy(() => import("../../parts/OrderPart"));
const OrderPartsMultiple = lazy(() => import("../../parts/OrderPartsMultiple"));
const RaiseDemand = lazy(() => import("../../parts/RaiseDemand"));
const RaiseDemandMultiple = lazy(
  () => import("../../parts/RaiseDemandMultiple")
);
const RFQ = lazy(() => import("../../quotes/RFQ"));
const RFQMultiple = lazy(() => import("../../quotes/RFQMultiple"));

const partsHiddenFields = {
  "batch.number": false,
  "order.division": false,
  "order.id": false,
  "order.supplier_name": false,
  "part.group_id": false,
  "part.inspection_life_applicable": false,
  "part.location": false,
  "part.serial_number_required": false,
  "part.shelf_life_applicable": false,
  "part.available": false,
  "part.list_cost": false,
  "part.list_price": false,
  buy: false,
  condition: false,
  id: false,
  invoice_id: false,
  job_id: false,
  location: false,
  margin: false,
  notes: false,
  quantity: false,
  required: false,
  sell: false,
  serial_number: false,
  shelf: false,
  status: false,
  store: false,
  supplier_release: false,
  // uom: false,
  price: false,
};

const itemsHiddenFields = {
  "part.stock": false,
  "part.demand": false,
  "part.available": false,
  "part.on_order": false,
  "part.quarantine": false,
  "order.supplier_name": false,
  required: false,
  uom: false,
  "part.inspection_life_applicable": false,
  "part.shelf_life_applicable": false,
  "part.serial_number_required": false,
  invoice_id: false,
  "order.division": false,
  "part.location": false,
  "part.group_id": false,
  supplier_release: false,
  "part.list_cost": false,
  "part.list_price": false,
};

const partsColumnIndices = [
  "__check__",
  "id",
  "part.number",
  "part.description",
  "part.stock",
  "part.demand",
  "part.available",
  "part.on_order",
  "part.quarantine",
  "part.list_cost",
  "part.list_price",
];

const itemColumnIndices = [
  "__check__",
  "id",
  "part.number",
  "part.description",
  "quantity",
  "job_id",
  "order.id",
  "condition",
  "location",
  "status",
  "serial_number",
  "batch.number",
  "notes",
  "store",
  "shelf",
  "price",
  "buy",
  "margin",
  "sell",
];

interface PartsItemsDataGridProps extends AeronetViewProps {
  canCreatePart: boolean;
  canIssuePart: boolean;
  canOrderPart: boolean;
  canRaiseDemand: boolean;
  canRFQ: boolean;
  config: { [key: string]: any };
}

const PartsItemsDataGrid: React.FC<PartsItemsDataGridProps> = (
  props: PartsItemsDataGridProps
) => {
  const {
    canCreatePart,
    canOrderPart,
    canRaiseDemand,
    canRFQ,
    config,
    originalUrl,
  } = props;

  const [parts, setParts] = useState<IPartList[]>();
  const [part, setPart] = useState<IPartList>();
  const [displayParts, setDisplayParts] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const divisionOptions = useDivisionOptions();
  const globalConfig = useGlobalConfig();
  const globalRounding = useGlobalRounding();
  const groupOptions = usePartsGroupOptions();
  const locationOptions = useLocationOptions();
  const renderCellLink = useRenderLink();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const conditionOptions = usePartsConditionOptions(config);
  const storeOptions = usePartsStoreOptions(config);

  const actions = useMemo(() => {
    const defaultActions: ActionMenuItem[] = [];

    if (canRaiseDemand) {
      defaultActions.push({
        name: "Raise Demand",
        action: ["RaiseDemand", "RAISE_DEMAND"],
        requiresSelection: true,
        multipleSelection: true,
      });
    }

    if (canRFQ) {
      defaultActions.push({
        name: "Create RFQ",
        action: ["RFQ", "RFQ"],
        requiresSelection: true,
        multipleSelection: true,
      });
    }

    if (canOrderPart) {
      defaultActions.push({
        name: "Order Part",
        namePlural: "Order Parts",
        action: ["OrderPart", "ORDER_PART"],
        multipleSelection: true,
      });
    }
    return defaultActions;
  }, [ canOrderPart, canRaiseDemand, canRFQ]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Item ID",
      },
      /*** Part Fields */
      {
        headerName: "Part Number",
        field: "part.number",
        flex: 1,
        valueGetter: (params) => {
          if (Object.prototype.hasOwnProperty.call(params.row, "part")) {
            return params.row?.part?.number;
          } else {
            return params.row?.number;
          }
        },
        cellClassName: "AeronetDataGrid-cell--pointer",
      },
      {
        headerName: "Description",
        field: "part.description",
        flex: 1.25,
        valueGetter: (params) => {
          if (Object.prototype.hasOwnProperty.call(params.row, "part")) {
            return params.row?.part?.description;
          } else {
            return params.row?.description;
          }
        },
        cellClassName: "AeronetDataGrid-cell--pointer",
      },
      {
        headerName: "Flying",
        field: "part.flying",
        type: "number",
        flex: 1,
        valueGetter: (params) => params.row?.flying,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
        renderCell: (params) => {
          return renderCellLink(params, "../flying", [
            {
              field: "part.number",
              operator: "startsWith",
              value: params.row.number,
            },
          ]);
        },
      },
      {
        headerName: "Removed",
        field: "part.removed",
        type: "number",
        flex: 1,
        valueGetter: (params) => params.row?.removed,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
        renderCell: (params) => {
          return renderCellLink(params, "../removed", [
            {
              field: "part.number",
              operator: "startsWith",
              value: params.row.number,
            },
          ]);
        },
      },
      {
        headerName: "Available",
        field: "part.available",
        type: "number",
        flex: 1,
        valueGetter: (params) => params.row?.available,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
      },
      {
        headerName: "Rotable",
        field: "part.rotable",
        type: "number",
        flex: 1,
        valueGetter: (params) => params.row?.rotable,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
        renderCell: (params) => {
          return renderCellLink(params, "../rotable", [
            {
              field: "part.number",
              operator: "startsWith",
              value: params.row.number,
            },
          ]);
        },
      },
      {
        headerName: "Retired",
        field: "part.retired",
        type: "number",
        flex: 1,
        valueGetter: (params) => params.row?.retired,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
        renderCell: (params) => {
          return renderCellLink(params, "../retired", [
            {
              field: "part.number",
              operator: "startsWith",
              value: params.row.number,
            },
            {
              field: "store",
              operator: "startsWith",
              value: "retired",
            },
          ]);
        },
      },
      {
        headerName: "List Cost",
        field: "part.list_cost",
        type: "number",
        flex: 1,
        valueGetter: (params) => {
          let list_cost;
          if (Object.prototype.hasOwnProperty.call(params.row, "part")) {
            list_cost = params.row?.part?.list_cost;
          } else {
            list_cost = params.row?.list_cost;
          }

          let result = Number(list_cost).toFixed(globalConfig?.value_rounding ?? 4);

          if (params.row.list_cost_currency) {
            return `${params.row.list_cost_currency} ${result}`;
          } else {
            return result;
          }
        },
      },
      {
        headerName: "List Price",
        field: "part.list_price",
        type: "number",
        flex: 1,
        valueGetter: (params) => {
          let list_price;
          if (Object.prototype.hasOwnProperty.call(params.row, "part")) {
            list_price = params.row?.part?.list_price;
          } else {
            list_price = params.row?.list_price;
          }

          let result = Number(list_price).toFixed(globalConfig?.value_rounding ?? 4);

          if (params.row.list_price_currency) {
            return `${params.row.list_price_currency} ${result}`;
          } else {
            return result;
          }
        },
      },
      /*** Item Fields */
      {
        headerName: "Order",
        field: "order.id",
        flex: 0.75,
        valueGetter: (params) => params.row?.order_detail?.order?.id,
        renderCell: (params) => {
          if (params.value) {
            let ordersUrl = new URL("../index.html", originalUrl);
            ordersUrl.search = createSearchParams({
              function: "orders",
              nav_quick_search_for: "1",
              nav_quick_search_value: params.value.toString(),
              searchtype: "search",
            }).toString();
            return (
              <MUILink href={ordersUrl.href} target="_blank">
                {params.value}
              </MUILink>
            );
          }
          return params.value;
        },
      },
      {
        headerName: "Supplier",
        field: "order.supplier_name",
        flex: 1,
        valueGetter: (params) => params.row?.order_detail?.order?.supplier_name,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        type: "singleSelect",
        // valueOptions: statusOptions,
        valueFormatter: (params: GridValueFormatterParams) => {
          try {
            return config.status_list[
              Number(params.value as number)
            ].toUpperCase();
          } catch {
            return params.value;
          }
        },
        sortable: false,
      },
      {
        headerName: "Required",
        field: "required",
        type: "date",
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value && globalConfig) {
            return format(parseISO(params.value), globalConfig.date_format);
          }
          return params.value;
        },
      },
      {
        headerName: "Notes",
        field: "notes",
        flex: 1,
        valueGetter: (params) => params.row?.order_detail?.notes,
      },
      {
        headerName: "Quantity",
        field: "quantity",
        type: "number",
        flex: 0.75,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
      },
      {
        headerName: "Unit",
        field: "uom",
        type: "string",
        flex: 0.75,
      },
      {
        headerName: "IL",
        field: "part.inspection_life_applicable",
        type: "boolean",
        flex: 0.5,
        valueGetter: (params) => params.row?.part?.inspection_life_applicable,
      },
      {
        headerName: "SL",
        field: "part.shelf_life_applicable",
        type: "boolean",
        flex: 0.5,
        valueGetter: (params) => params.row?.part?.shelf_life_applicable,
      },
      {
        headerName: "SN",
        field: "part.serial_number_required",
        type: "boolean",
        flex: 0.5,
        valueGetter: (params) => params.row?.part?.serial_number_required,
      },
      {
        headerName: "W/Order",
        field: "job_id",
        flex: 0.5,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value === 0) {
            return "";
          }
          return params.value;
        },
        renderCell: (params) => {
          return renderCellLink(params, `/jobs/${params.row.job_id}`);
        },
      },
      {
        headerName: "Location",
        field: "location",
        flex: 1,
        type: "singleSelect",
        valueOptions: locationOptions,
      },
      {
        headerName: "Store",
        field: "store",
        flex: 1,
        type: "singleSelect",
        valueOptions: storeOptions,
      },
      {
        headerName: "Shelf",
        field: "shelf",
        flex: 1,
      },
      {
        headerName: "Serial Number",
        field: "serial_number",
        flex: 1,
      },
      {
        headerName: "Batch Number",
        field: "batch.number",
        flex: 1,
        valueGetter: (params) => params.row?.batch?.number,
      },
      {
        headerName: "Condition",
        field: "condition",
        flex: 1,
        type: "singleSelect",
        valueOptions: conditionOptions,
        valueFormatter: (params: GridValueFormatterParams) => {
          try {
            return config.items_condition[params.value as string];
          } catch {
            return params.value;
          }
        },
      },
      {
        headerName: "List",
        field: "price",
        type: "number",
        flex: 1,
        align: "left",
        valueGetter: (params) => {
          if (params.row.currency_code) {
            return `${params.row.currency_code} ${globalRounding(
              params.row.price
            )}`;
          } else {
            return globalRounding(params.row.price);
          }
        },
      },
      {
        headerName: "Cost",
        field: "buy",
        type: "number",
        flex: 1,
        align: "left",
        valueGetter: (params) => {
          if (params.row.currency_code) {
            return `${params.row.currency_code} ${globalRounding(
              params.row.buy
            )}`;
          } else {
            return globalRounding(params.row.buy);
          }
        },
      },
      {
        headerName: "Value",
        field: "sell",
        type: "number",
        flex: 1,
        valueGetter: (params) => {
          if (params.row.currency_code) {
            return `${params.row.currency_code} ${globalRounding(
              params.row.sell
            )}`;
          } else {
            return globalRounding(params.row.sell);
          }
        },
      },
      {
        headerName: "Invoice",
        field: "invoice_id",
        type: "number",
        flex: 0.5,
        valueGetter: (params) => params.row?.invoice?.id,
      },
      {
        headerName: "Markup %",
        field: "margin",
        type: "number",
        flex: 0.5,
        valueGetter: (params) => params.row?.margin,
        valueFormatter: (params) => {
          return globalRounding(params.value);
        },
      },
      /*** Hidden Fields */
      {
        field: "order.division",
        type: "singleSelect",
        headerName: "Division",
        valueOptions: divisionOptions,
      },
      {
        field: "part.location",
        type: "singleSelect",
        headerName: "Registration",
        valueOptions: locationOptions,
      },
      {
        field: "part.group_id",
        type: "singleSelect",
        headerName: "Group",
        valueOptions: groupOptions,
      },
      {
        field: "supplier_release",
        headerName: "Release Number",
      },
    ],
    [
      conditionOptions,
      config,
      divisionOptions,
      globalConfig,
      globalRounding,
      groupOptions,
      locationOptions,
      originalUrl,
      renderCellLink,
      storeOptions,
    ]
  );

  const getData = useCallback(
    async (
      params: any,
      axiosConfig?: CacheRequestConfig,
      apiRef?: MutableRefObject<GridApi>
    ) => {
      // Check filters to see if we are filtering any items
      let itemsFilter = false;
      if (
        Object.prototype.hasOwnProperty.call(params, "filter") &&
        params.filter
      ) {
        for (let i = 0; i < params.filter.length; i++) {
          let filter = JSON.parse(params.filter[i]);
          if (filter.model === "Part") {
            continue;
          }
          itemsFilter = true;
          break;
        }
      }

      if (itemsFilter) {
        if (displayParts) {
          setDisplayParts(false);
          if (apiRef) {
            apiRef.current.setColumnVisibilityModel(itemsHiddenFields);

            // Re-order the columns
            for (let i = 0; i < itemColumnIndices.length; i++) {
              apiRef.current.setColumnIndex(itemColumnIndices[i], i);
            }
          }
        }

        // Call items api
        try {
          const resp: AxiosResponse<AeronetV6ItemsResponse<ItemList>> =
            await axios.get("/v6/items", {
              params: params,
              paramsSerializer: (params) => queryString.stringify(params),
              ...axiosConfig,
            });

          return {
            total: resp.data.total,
            rows: resp.data.items,
          } as QueryResults<ItemList>;
        } catch (e) {
          // @ts-ignore
          if (!axios.isCancel(e)) {
            enqueueSnackbar("Error fetching items", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
          throw e;
        }
      } else {
        if (!displayParts) {
          setDisplayParts(true);
          if (apiRef) {
            apiRef.current.setColumnVisibilityModel(partsHiddenFields);
            // Re-order the columns
            for (let i = 0; i < partsColumnIndices.length; i++) {
              apiRef.current.setColumnIndex(partsColumnIndices[i], i);
            }
          }
        }
        // Call parts api
        try {
          const resp: AxiosResponse<AeronetV6ItemsResponse<IPartList>> =
            await axios.get("/v6/parts", {
              params: params,
              paramsSerializer: (params) => queryString.stringify(params),
              cache: false,
            });

          return {
            total: resp.data.total,
            rows: resp.data.items,
          } as QueryResults<IPartList>;
        } catch (e) {
          // @ts-ignore
          if (!axios.isCancel(e)) {
            enqueueSnackbar("Error fetching parts", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
          throw e;
        }
      }
    },
    [enqueueSnackbar, displayParts]
  );

  const advancedSearchDefaultItems = useMemo(
    () => [
      {
        id: 1,
        field: "part.number",
        operator: "startsWith",
        value: "",
      },
      {
        id: 2,
        field: "part.description",
        operator: "contains",
        value: "",
      },
      {
        id: 3,
        field: "status",
        operator: "is",
        value: "",
      },
      {
        id: 4,
        field: "location",
        operator: "is",
        value: "",
      },
      {
        id: 5,
        field: "store",
        operator: "is",
        value: "",
      },
      {
        id: 6,
        field: "shelf",
        operator: "contains",
        value: "",
      },
      {
        id: 7,
        field: "id",
        operator: "is",
        value: "",
      },
      {
        id: 8,
        field: "serial_number",
        operator: "contains",
        value: "",
      },
      // {
      //   id: 9,
      //   field: "batch.number",
      //   operator: "is",
      //   value: "",
      // },
      // {
      //   id: 10,
      //   field: "supplier_release",
      //   operator: "startsWith",
      //   value: "",
      // },
      {
        id: 11,
        field: "condition",
        operator: "is",
        value: "",
      },
      {
        id: 12,
        field: "part.group_id",
        operator: "is",
        value: "",
      },
     
      // {
      //   id: 14,
      //   field: "job_id",
      //   operator: "is",
      //   value: "",
      // },
    ],
    []
  );

  const onSearch = useCallback(
    (filterModel: GridFilterModel, apiRef: MutableRefObject<GridApi>) => {
      const filter: string[] = [];
      let view: string = "";

      // Translating a statuses to a view and a status filter
      filterModel.items.forEach((item) => {
        if (item.field === "view") {
          switch (item.value) {
            case "removed": {
              view = "parts";
              const status = {
                field: "status",
                operator: "is",
                value: 16,
              };
              filter.push(JSON.stringify(status));
              break;
            }
            case "flying": {
              view = "parts";
              const status = {
                field: "status",
                operator: "is",
                value: 7,
              };
              filter.push(JSON.stringify(status));
              break;
            }
            case "retired": {
              view = "parts";
              const status = {
                field: "status",
                operator: "is",
                value: 18,
              };
              filter.push(JSON.stringify(status));
              break;
            }
            case "rotable": {
              view = "parts";
              const status = {
                field: "status",
                operator: "is",
                value: 17,
              };
              filter.push(JSON.stringify(status));
              break;
            }
            case "": {
              break;
            }
            default: {
              view = item.value;
              break;
            }
          }
        }
      });

      if (view) {
        // get the remaining filters
        filterModel.items.forEach((item) => {
          if (item.field !== "view" && item.value) {
            const { id, ...f } = item;
            filter.push(JSON.stringify(f));
          }
        });

        // encode the filters
        const encodedSearch = encodeQueryParams(
          { filter: ArrayParam },
          { filter }
        );

        // set the query params and navigate to the view
        const to: To = { pathname: "../" + view };
        to.search = queryString.stringify(encodedSearch);
        navigate(to);
      } else {
        // Set the filters in the existing view - the getData function will decide what api to call
        apiRef.current.setFilterModel(filterModel, "upsertFilterItems");
      }
    },
    [navigate]
  );

  const quickSearchItems = useMemo(
    () => [
      {
        field: "part.number",
        operator: "startsWith",
        label: "Part Number",
      },
      {
        field: "part.description",
        operator: "contains",
        label: "Part Description",
      },
      {
        field: "id",
        operator: "startsWith",
        label: "Component ID",
      },
      {
        field: "serial_number",
        operator: "startsWith",
        label: "Serial Number",
      },
    ],
    []
  );

  const initialSort: GridSortItem[] = useMemo(
    () => [{ field: "part.number", sort: "asc" }],
    []
  );

  const setSelected = useCallback(
    (
      selected:
        | IPartList
        | ItemList
        | IterableIterator<GridValidRowModel>
        | undefined
    ) => {
      if (displayParts) {
        if (selected && isPart(selected as IPartList)) {
          setParts([selected as IPartList]);
          setPart(selected as IPartList);
        } else {
          if (selected) {
            const selectedParts = Array.from(
              selected as IterableIterator<GridValidRowModel>
            );
            console.debug(selectedParts);
            setParts(selectedParts as unknown as IPartList[]);
            setPart(selectedParts[0] as unknown as IPartList);
          } else {
            setParts(undefined);
            setPart(undefined);
          }
        }
      }
    },
    [displayParts]
  );

  const onRowClick = useCallback(
    (params, event) => {
      navigate(String(params.id));
    },
    [navigate]
  );

  const onCellClick = useCallback(
    (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
      // Block the row click unless the click is on number or description
      if (
        params.colDef.field === "part.number" ||
        params.colDef.field === "part.description"
      ) {
        return;
      }
      event.stopPropagation();
    },
    []
  );

  return (
    <AeronetDataGrid
      actions={actions}
      advancedSearchForm={ItemsAdvancedSearchForm}
      advancedSearchProps={{
        items: advancedSearchDefaultItems,
        onSearch: onSearch,
      }}
      columns={columns}
      currentUserId={currentUser?.id}
      data={getData}
      enableCreate={canCreatePart}
      exportFileName="Component"
      initialSort={initialSort}
      initialState={{
        columns: {
          columnVisibilityModel: partsHiddenFields,
        },
      }}
      name={`component-${displayParts ? "parts" : "items"}-data-grid`}
      quickSearchItems={quickSearchItems}
      setSelected={setSelected}
      onCellClick={displayParts ? onCellClick : () => {}}
      onRowClick={displayParts ? onRowClick : () => {}}
      rowThreshold={0}
      components={{
        toolbar: PartsToolbar,
      }}
    >
      {(props) => {
        return (
          <>
            {props.action === props.Actions.RaiseDemand &&
            part &&
            parts &&
            parts.length <= 1 ? (
              <RaiseDemand
                raiseDemandType={RaiseDemandTypeEnum.FROM_INVENTORY}
                part={part}
                open={props.action === props.Actions.RaiseDemand}
                {...props}
              />
            ) : (
              <></>
            )}
            {props.action === props.Actions.RaiseDemand &&
            parts &&
            parts.length > 1 ? (
              <RaiseDemandMultiple
                parts={parts}
                open={props.action === props.Actions.RaiseDemand}
                {...props}
              />
            ) : (
              <></>
            )}
            {props.action === props.Actions.RFQ &&
            part &&
            parts &&
            parts.length <= 1 ? (
              <RFQ
                part={part}
                open={props.action === props.Actions.RFQ}
                originalUrl={originalUrl!}
                {...props}
              />
            ) : (
              <></>
            )}
            {props.action === props.Actions.RFQ && parts && parts.length > 1 ? (
              <RFQMultiple
                parts={parts}
                open={props.action === props.Actions.RFQ}
                originalUrl={originalUrl!}
                {...props}
              />
            ) : (
              <></>
            )}
            {part && props.action === props.Actions.IssuePart ? (
              <IssuePart
                part={part}
                config={config}
                open={props.action === props.Actions.IssuePart}
                {...props}
              />
            ) : (
              <></>
            )}
            {props.action === props.Actions.OrderPart &&
            (!parts || parts.length <= 1) ? (
              <OrderPart
                part={part}
                open={props.action === props.Actions.OrderPart}
                originalUrl={originalUrl!}
                hasLinkJobPermission={true}
                {...props}
              />
            ) : (
              <></>
            )}
            {props.action === props.Actions.OrderPart &&
            parts &&
            parts.length > 1 ? (
              <OrderPartsMultiple
                parts={parts}
                open={props.action === props.Actions.OrderPart}
                originalUrl={originalUrl!}
                {...props}
              />
            ) : (
              <></>
            )}
          </>
        );
      }}
    </AeronetDataGrid>
  );
};

export default PartsItemsDataGrid;
