import { useMemo } from "react";

import { useConfig } from "./useConfig";
import { useCurrentUser } from "./useCurrentUser";

export function useHasPermission(
  module: string,
  permission: string
): boolean | undefined {
  const currentUser = useCurrentUser();
  const config = useConfig(module);

  const hasPermission: boolean | undefined = useMemo(() => {
    if (config && currentUser) {
      if (
        config[permission] &&
        currentUser.permissions[module] &&
        currentUser.permissions[module].includes(Number(config[permission]))
      ) {
        console.debug(`has ${module} ${permission} permission = true`);
        return true;
      } else {
        console.debug(`has ${module} ${permission} permission = false`);
        return false;
      }
    }
  }, [config, currentUser, module, permission]);

  return hasPermission;
}
