import { GridFilterItem } from "@mui/x-data-grid-pro";
import queryString from "query-string";
import { useCallback } from "react";
import { Link, To } from "react-router-dom";
import { ArrayParam, encodeQueryParams } from "use-query-params";

export function useRenderLink() {
  const renderCellLink = useCallback(
    (params, view: string, search?: GridFilterItem[]) => {
      let value = params.value;
      const style = params.style

      const to: To = { pathname: view };
      if (search && search.length > 0) {
        const filter: string[] = [];
        for (const s of search) {
          filter.push(JSON.stringify(s));
        }

        const encodedSearch = encodeQueryParams(
          { filter: ArrayParam },
          { filter }
        );
        to.search = queryString.stringify(encodedSearch);
      }

      // if the column has a valueFormatter defined use that
      if (params.colDef.valueFormatter) {
        value = params.colDef.valueFormatter(params);
      }

      // Don't render a link for empty or zero values
      if (!value || value === "0.00") {
        return value;
      }
      return (
        <Link
          onClick={(event) => {
            event.stopPropagation();
          }}
          to={to}
          style={style}
        >
          {value}
        </Link>
      );
    },
    []
  );

  return renderCellLink;
}
