import {
  JSXElementConstructor,
  MutableRefObject,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Box, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  DataGridPro,
  GridApi,
  GridCallbackDetails,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
  UncapitalizedGridProSlotsComponent,
  gridDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

import LoadingSpinner from "../Loading";
import { AdvancedSearchToolbar } from "./AdvancedSearchToolbar";
import "./datagrid.css";

import type { ActionMenuItem } from "./ActionMenu";
import type { AdvancedSearchFormProps } from "./AdvancedSearch";
import { AdvancedSearch2, AdvancedSearchForm2 } from "./AdvanceSearchUpdate";


// Javascript compatible object as const alternative to enum
// https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export let Actions = {
  AdvancedSearch: "ADVANCED_SEARCH",
  Email: "EMAIL",
  Settings: "SETTINGS",
  None: "",
} as const;
export type ActionType = (typeof Actions)[keyof typeof Actions];

export interface QueryResults<T> {
  total: number;
  rows: T[];
}

export interface AdvancedSearchProps {
  onSearch?: (
    model: GridFilterModel,
    apiRef: MutableRefObject<GridApi>
  ) => void;
  items?: GridFilterItem[];
  [x: string | number | symbol]: unknown;
}

export interface AeronetDataGridProps<T extends GridValidRowModel> {
  actions?: ActionMenuItem[];
  advancedSearchForm?: JSXElementConstructor<AdvancedSearchFormProps>;
  advancedSearchProps?: AdvancedSearchProps;
  checkboxSelection?: boolean;
  children?: (props: {
    onClose: () => void;
    action: ActionType;
    Actions: { [key: string]: ActionType };
  }) => React.ReactNode;
  columns: GridColDef<T>[];
  components?: Partial<UncapitalizedGridProSlotsComponent>;
  currentUserId: number;
  data: (
    params: { [key: string]: any },
    config?: { [key: string]: any },
    apiRef?: MutableRefObject<GridApi>
  ) => Promise<QueryResults<T>>;
  disableColumnFilter?: boolean;
  enableAdvancedSearch?: boolean;
  enableCreate?: boolean;
  enableDelete?: boolean;
  enableFilter?: boolean;
  exportFileName?: string;
  initialSort?: GridSortModel;
  name: string;
  onCellClick?: GridEventListener<"cellClick">;
  onDelete?: (GridRowSelectionModel) => void;
  onRowClick?: GridEventListener<"rowClick">;
  setSelected?: (selected?: T) => void;
  sx?: SxProps<Theme>;
  quickSearchItems?: GridFilterItem[];
  [x: string | number | symbol]: unknown;
  isHeaderBorder?: boolean,
}

function AeronetDataGrid<T extends GridValidRowModel>(
  props: AeronetDataGridProps<T>
) {
  const {
    actions,
    advancedSearchForm = AdvancedSearchForm2,
    checkboxSelection = true,
    children,
    components = { toolbar: AdvancedSearchToolbar },
    currentUserId,
    data,
    disableColumnFilter = true,
    enableAdvancedSearch = true,
    enableCreate = true,
    enableDelete = true,
    enableFilter = false,
    exportFileName = "Aeronet",
    initialSort,
    name,
    onDelete,
    quickSearchItems,
    setSelected,
    sx = [],
    isHeaderBorder = false,
    ...dataGridProps
  } = props;

  const { items: defaultItems, ...advancedSearchProps } =
    props.advancedSearchProps ?? {};

  const [action, setAction] = useState<ActionType>(Actions.None);
  const [filter, setFilter] = useState<GridFilterModel | undefined>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(-1);
  const [searchText, setSearchText] = useState("");
  const [advanceSearch,setAdvanceSearch] = useState<Boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filterButtonEl, setFilterButtonEl] =useState<HTMLButtonElement | null>(null);
  const [buttonText, setButtonText] = useState("");
  // load the query params
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    filter: ArrayParam,
    page: NumberParam,
    pageSize: NumberParam,
    sort: ArrayParam,
  });
  // destructure and rename
  const {
    q: quickSearchFilter,
    filter: searchFilters,
    page: pageQueryParam,
    pageSize: pageSizeQueryParam,
    sort: sortQueryParams,
  } = query;
  const apiRef = useGridApiRef();

  window.addEventListener('beforeunload',()=>{
  
    const localStorageKey = getLocalStorageKey(currentUserId);
    localStorage.removeItem(localStorageKey)
  
  
  })
 

  /**
   * Combines the actions prop with default Actions
   */
  useMemo(() => {
    console.debug("Actions memo");
    if (actions && actions.length > 0) {
      let additionalActions = {};
      for (let action of actions) {
        if (action.action) {
          additionalActions = {
            ...additionalActions,
            ...Object.fromEntries([action.action]),
          };
        }
      }
      Actions = { ...Actions, ...additionalActions };
    }
  }, [actions]);

  const getLocalStorageKey = useCallback(
    (userId) => {
      return `aeronet-${name}-state-${userId}`;
    },
    [name]
  );

  const exportState = useCallback(() => {
    if (currentUserId) {
      let state = apiRef.current.exportState();

      // Hack to add density into state
      const density = gridDensitySelector(
        apiRef.current.state,
        // @ts-ignore
        apiRef.current.instanceId
      );

      // @ts-ignore
      state.density = density;
      if (window.localStorage) {
        const localStorageKey = getLocalStorageKey(currentUserId);
        localStorage.setItem(localStorageKey, JSON.stringify(state));
      }
    }
  }, [apiRef, currentUserId, getLocalStorageKey]);

  /**
   * Restores state from local storage
   * - ignores filters as they override the initial filters
   */
  useEffect(() => {
    if (currentUserId) {
      console.debug("Restoring state");
      const localStorageKey = getLocalStorageKey(currentUserId);
      try {
        const jsonState = localStorage.getItem(localStorageKey);
        if (jsonState) {
          const { density, filter, sorting, ...state } = JSON.parse(jsonState);

          if (pageQueryParam) {
            // state.pagination.page = pageQueryParam;
            state.pagination.paginationModel.page = pageQueryParam;
          }

          if (pageSizeQueryParam) {
            // state.pagination.pageSize = pageSizeQueryParam;
            state.pagination.paginationModel.pageSize = pageSizeQueryParam;

          }

          console.debug("State: ");
          console.dir(state);
          apiRef.current.restoreState(state);

          // Hack to restore density as it's not supported by restoreState
          if (density) {
            apiRef.current.setDensity(density.value as GridDensity);
          }
        }
      } catch (error) {}
    } else {
      console.debug("Restoring state - currentUserId not set");
    }
  }, [
    apiRef,
    currentUserId,
    getLocalStorageKey,
    pageSizeQueryParam,
    pageQueryParam,
  ]);

  const defaultFilters = useMemo(() => {
    let filter: GridFilterModel = {
      items: [],
    };

    if (defaultItems && defaultItems.length > 0) {
      // deep copy defaultItems so filters can be restored when using back button
      filter.items = defaultItems.map((obj) => ({ ...obj }));
    }
    return filter;
  }, [defaultItems]);

  /**
   * Sets the text in the quick search from the 'q' query parameter
   */
  useEffect(() => {
    console.debug("Quick search effect");
    setSearchText(quickSearchFilter ?? "");

    let filter: GridFilterModel = JSON.parse(JSON.stringify(defaultFilters));
    
    if (filter.items.length === 0 && defaultItems){
      filter.items = defaultItems.map((obj) => ({ ...obj }));
    }

    if (quickSearchFilter) {
      filter.items.push({
        field: "q",
        operator: "==",
        value: quickSearchFilter,
      });
    }
    if (filter.items.length > 0) {
      apiRef.current.setFilterModel(filter, "restoreState");
    }
  }, [apiRef, defaultFilters, quickSearchFilter, defaultItems]);

  /**
   * Sets the filter model from:
   * - advancedSearchProps.items
   * - 'filter' query parameters
   */
  useEffect(() => {
    console.debug("Filter model effect");
    let filter: GridFilterModel = JSON.parse(JSON.stringify(defaultFilters));
    
    if (filter.items.length === 0 && defaultItems){
      filter.items = defaultItems.map((obj) => ({ ...obj }));
    }
    if (searchFilters && searchFilters.length > 0) {
      for (const f of searchFilters) {
        if (f) {
          const searchFilter = JSON.parse(f);
          for (const fi of filter.items) {
            if (fi.field === searchFilter.field) {
              fi.operator = searchFilter.operator;
              fi.value = searchFilter.value;
              break;
            }
          }
        }
      }
    }
    console.debug("Filters: ");
    console.dir(filter);
    if (filter.items.length > 0) {
      // we use restoreState here so in onFilterChange we don't do another view reload
      apiRef.current.setFilterModel(filter, "restoreState");
    }
  }, [apiRef, defaultFilters,defaultItems, quickSearchItems, searchFilters]);

  /**
   * Sets the page from the page query parameter or resets it to the first page
   */
  useEffect(() => {
    console.debug("Page effect");
    if (pageQueryParam) {
      setPage(pageQueryParam);
    } else {
      setPage(0);
    }
  }, [pageQueryParam]);

  /**
   * Sets the page size from the pageSize query parameter
   */
  useEffect(() => {
    console.debug("Page size effect");
    if (pageSizeQueryParam) {
      setPageSize(pageSizeQueryParam);
    } else {
      setPageSize(15);
    }
  }, [pageSizeQueryParam]);

  /**
   * Sets the sort model from
   * - the sort query parameter
   * - the initialSort prop
   */
  useEffect(() => {
    console.debug("Sort effect");

    if (sortQueryParams && sortQueryParams.length > 0) {
      const sortModel: GridSortModel = [];
      for (const s of sortQueryParams) {
        if (s) {
          sortModel.push(JSON.parse(s));
        }
      }
      setSortModel(sortModel);
    } else {
      if (initialSort) {
        setSortModel(initialSort);
        // apiRef.current.setSortModel(initialSort);
      }
    }
  }, [apiRef, initialSort, sortQueryParams]);

  /**
   * Builds the filters and calls the data callback setting the rows and rowcount from the result
   */
  useEffect(() => {
    const controller = new AbortController();

    async function getData() {
      console.debug("Get data effect: ");
      console.debug(data);
      console.debug(page);
      console.debug(pageSize);
      console.debug("filter:");
      console.debug(filter);
      console.debug("sort:");
      console.debug(sortModel);
      setLoading(true);

      const params = { page: page + 1, size: pageSize };
      const filters: string[] = [];

      if (filter) {
        for (const f of filter.items) {
          if (f.field) {
            // special case for quick search
            if (f.field === "q") {
              params["q"] = f.value;
              continue;
            }

            if ((f.value != null && f.value !== "") || f.operator === "!=") {
              let op = f.operator || "contains";
              let value = f.value;
              if (op === "contains") {
                op = "ilike";
                value = "%" + f.value + "%";
              } else if (op === "startsWith") {
                op = "ilike";
                value = f.value + "%";
              } else if (op === "endsWith") {
                op = "ilike";
                value = "%" + f.value;
              }
              if (f.field.includes(".")) {
                const parts = f.field.split(".", -2);
                let [model, field] = parts.slice(-2);
                model = model[0].toUpperCase() + model.slice(1);
                filters.push(
                  JSON.stringify({
                    model: model,
                    field: field,
                    op: op,
                    value: value,
                  })
                );
              } else {
                filters.push(
                  JSON.stringify({
                    field: f.field,
                    op: op,
                    value: value,
                  })
                );
              }
            }
          }
        }
      }
      if (filters.length > 0) {
        params["filter"] = filters;
      }

      const sortFields: string[] = [];
      if (sortModel) {
        for (const sortItem of sortModel) {
          let field = sortItem.field;
          if (sortItem.sort && sortItem.sort === "desc") {
            field = "-" + field;
          }
          sortFields.push(field);
        }
      }
      if (sortFields.length > 0) {
        params["sort"] = sortFields.toString();
      }

      try {
        const results: QueryResults<T> = await data(
          params,
          {
            signal: controller.signal,
            cache: false,
          },
          apiRef
        );
        setRowCount(results.total);
        setRows(results.rows);
        setLoading(false);
      } catch (e) {
        setRowCount(0);
        setLoading(false);
      }
    }

    if (currentUserId) {
      getData();
    }

    return () => {
      controller.abort();
    };
  }, [apiRef, data, currentUserId, page, pageSize, filter, sortModel]);
 


useEffect(() => {
  const handleClick = (event) => {
    console.log('Click event detected. Target:', event.target.id);
    if (event.target.id==="action") {
      setButtonText("action")
    }
    else{
      setButtonText("")
    }
  };

  window.addEventListener('click', handleClick); // Log all clicks

  return () => {
    window.removeEventListener('click', handleClick);
  };
}, []);

  useEffect(() => {
    const localStorageKey = getLocalStorageKey(currentUserId);
    const storedFilter = localStorage.getItem(localStorageKey);
    if (storedFilter) {
      const parsedFilter = JSON.parse(storedFilter);
    if(parsedFilter.filter){
      setFilter(parsedFilter.filter);
      
      apiRef.current.setFilterModel(parsedFilter.filter, "restoreState");
    }
    }
  }, [apiRef, currentUserId, getLocalStorageKey]);
  
  const onFilterChange = useCallback(
    (filterModel: GridFilterModel, details: GridCallbackDetails<"filter">) => {
      console.debug("onFilterChange");
      // we're hijacking the restoreState reason to also mean loading filter from query params
      // in this case we only want to set the filter we don't want to reset the query params and
      // navigate again
      filterModel.items = filterModel.items.filter((obj) => obj.value !== null && obj.value !== "").map((obj) => ({ ...obj }));
      
      if (details.reason === "restoreState") {
        console.debug("Filters: ");
        console.dir(filterModel);
        setFilter(filterModel);
      }
      else if (buttonText.length>0) {
        console.log("here action is triggered");
 
      } 
      else {
        console.debug("navigating with filter");
        const filter: string[] = [];
        let q: string | undefined = undefined;
        filterModel.items.forEach((item) => {
          if (item.value) {
            // get all the fields except for id
            const { id, ...f } = item;
            if (item.field === "q") {
              q = item.value;
            } else {
              filter.push(JSON.stringify(f));
            }
          }
        });

        setQuery({ filter, q, sort: undefined, page: undefined });
      }
      if(filterModel.items.length>0){
        const filter: string[] = [];
        let q: string | undefined = undefined;
        filterModel.items.forEach((item) => {
          if (item.value) {
            // get all the fields except for id
            const { id, ...f } = item;
            if (item.field === "q") {
              q = item.value;
            } else {
              filter.push(JSON.stringify(f));
            }
          }
        });

        setQuery({ filter, q, sort: undefined, page: undefined });
        if(currentUserId){
          const localStorageKey = getLocalStorageKey(currentUserId);
        localStorage.setItem(localStorageKey, JSON.stringify({filter:filterModel,sort:undefined,page:pageQueryParam,pageSize:pageSizeQueryParam}));
        }
      }
      if(!details.reason){
        const localStorageKey = getLocalStorageKey(currentUserId);
        localStorage.setItem(localStorageKey, JSON.stringify({
          filter: undefined,
          q: undefined,
          sort: undefined,
          page: undefined,
        }));
      }
    },
    [setQuery,buttonText,currentUserId,getLocalStorageKey,pageQueryParam,pageSizeQueryParam]
  );

  const onSelectionChange = useCallback(
    (newSelectionModel: GridRowSelectionModel) =>
      setSelectionModel(newSelectionModel),
    []
  );

  const onSortChange = useCallback(
    (sortModel: GridSortModel, details: GridCallbackDetails<any>) => {
      console.debug("onSortChange");
      console.debug(details);

      const sort: string[] = sortModel.map((sortItem) => {
        return JSON.stringify(sortItem);
      });

      // set the sort and reset the page
      setQuery({ sort, page: undefined });
    },
    [setQuery]
  );

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
      console.debug("handlePaginationModelChange");
      const localStorageKey = getLocalStorageKey(currentUserId);
      if (model.page === 0) {
        setQuery({ pageSize: model.pageSize, page: undefined });
        localStorage.setItem(localStorageKey, JSON.stringify({
          pagination:{
            paginationModel:{
              page:undefined,
              pageSize:model.pageSize
            }
          }
        }));
      } else {

        localStorage.setItem(localStorageKey, JSON.stringify({
          pagination:{
            paginationModel:{
              page:model.page,
              pageSize:model.pageSize
            }
          }
        }));

        setQuery(model);
      }
    },
    [setQuery,getLocalStorageKey,currentUserId]
  );

  const onClose = useCallback(() => {
    // close the action
    setAction(Actions.None);

    // remove the selection
    setSelectionModel([]);
    if (setSelected) {
      setSelected(undefined);
    }

    // trigger the data to refresh
    setPage(0);
  }, [setSelected]);

  /**
   * Clear the quick search field and the query parameters
   */
  const onClearSearch = useCallback(() => {
    setSearchText("");
    const localStorageKey = getLocalStorageKey(currentUserId);
    localStorage.setItem(localStorageKey, JSON.stringify({
      filter: undefined,
      q: undefined,
      sort: undefined,
      page: undefined,
    }));
    setQuery({
      filter: undefined,
      q: undefined,
      sort: undefined,
      page: undefined,
    });
  }, [setQuery,getLocalStorageKey,currentUserId]);

  const onClearAdvancedSearch = useCallback(() => {
    apiRef.current.setFilterModel(defaultFilters);
    setQuery({
      filter: undefined,
      q: undefined,
      sort: undefined,
      page: undefined,
    });
    setAdvanceSearch(false)
    const localStorageKey = getLocalStorageKey(currentUserId);
    localStorage.setItem(localStorageKey, JSON.stringify({
      filter: undefined,
      q: undefined,
      sort: undefined,
      page: undefined,
    }));
  }, [apiRef, defaultFilters, setQuery,getLocalStorageKey,currentUserId]);

  const onCloseAdvancedSearch = useCallback(() => {
    setAction(Actions.None);
    setAdvanceSearch(false)
  }, []);

  return (
    <Box display="flex" height="100%" maxWidth="100%">
      <Box flexGrow={1} maxWidth="100%">
        {/* @ts-ignore */}
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          checkboxSelection={checkboxSelection}
          slots={ components }
          slotProps={{
            toolbar: {
              action: action,
              actions: actions,
              clearSearch: onClearSearch,
              defaultItems: defaultItems,
              enableAdvancedSearch: enableAdvancedSearch,
              enableCreate: enableCreate,
              enableDelete: enableDelete,
              enableFilter: searchText || advanceSearch ?false:enableFilter,
              exportState: exportState,
              // @ts-ignore
              onChange: (event) => setSearchText(event.target.value),
              setAction: setAction,
              setSelected: setSelected,
              setSelectionModel: onSelectionChange,
              value: searchText,
              selectionModel: selectionModel,
              onDelete: onDelete,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
                fileName: exportFileName,
              },
              csvOptions: {
                fileName: exportFileName,
              },
              quickSearchItems: quickSearchItems,
              setFilterButtonEl,
            },
            panel: {
              anchorEl: filterButtonEl,
            },
            filterPanel: {
              
              logicOperators: [GridLogicOperator.And],
              
              columnsSort: 'asc',
              filterFormProps: {
              
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                },
              
              },
              sx: {
                "& .MuiDataGrid-columnHeader": { color: grey[900] },
                "& .MuiDataGrid-columnHeaders": {
                  borderTop: "2px solid #e0e0e0", // Add top border above headers
                  borderTopColor: 'rgba(224, 224, 224, 1)',
                },          
                // Customize inputs using css selectors
                '& .MuiDataGrid-filterForm': { p: "10px 45px" ,
                  backgroundColor: "#eeeeee",
                 
                },
              "& .MuiDataGrid-filterForm button.MuiButtonBase-root.MuiButton-root" :{
                 marginLeft:"45px"
                },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                '& .MuiFormControl-root': {
                
                  '&.MuiFormControl-fullWidth': {
                    width: 'auto', // Adjust to 'auto' to handle responsive width better
                  },
                },
               
                '& .MuiDataGrid-panelFooter':{
                  backgroundColor: "#eeeeee",
                },
                "& .MuiButtonBase-root.MuiButton-root":{
                  marginLeft:"45px",
                  marginRight:"45px",
                  marginBottom:"15px"
                }
              }
              ,}
           
            
          }}
          disableColumnFilter={disableColumnFilter}
          disableColumnSelector={true}
          disableRowSelectionOnClick
          filterMode="server"
          loading={loading}
          onFilterModelChange={onFilterChange}
          onRowSelectionModelChange={onSelectionChange}
          onSortModelChange={onSortChange}
          pagination
          paginationModel={{ page, pageSize }}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          rowCount={rowCount}
          rows={rows}
          pageSizeOptions={[15, 50, 100]}
          rowSelectionModel={selectionModel}
          sortModel={sortModel}
          sx={[
            {
              borderStyle:"none",
              "& .MuiDataGrid-columnHeader": { color: grey[900] },
              "& .MuiDataGrid-columnHeaders": isHeaderBorder
              ? {
                  borderTop: "3px solid rgba(224, 224, 224, 1) !important",
                  borderRadius: "0px !important",
                }
              : {},
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-main":{
                margin:"0px 50px",
                borderBottom:"3px solid",
                borderColor:"rgba(224, 224, 224, 1)"
              },
              "& .MuiDataGrid-withBorderColor":!isHeaderBorder
              ? {
                  borderTop: "0px !important",
                }
              : {},
              "& .MuiDataGrid-footerContainer":{
              margin:"0px 50px",
              borderBottom:"3px solid rgba(224, 224, 224, 1)",
              marginBottom:"10px"
              
              }
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...dataGridProps}
        />
        <Suspense fallback={<LoadingSpinner />}>
          {enableAdvancedSearch &&
          advancedSearchForm &&
          filter && defaultItems &&
          action === Actions.AdvancedSearch ? (
            <AdvancedSearch2
              open={true}
              defaultItems = {defaultItems}
              setAdvanceSearch = {setAdvanceSearch}
              onClear={onClearAdvancedSearch}
              onClose={onCloseAdvancedSearch}
              apiRef={apiRef}
              form={advancedSearchForm}
              {...advancedSearchProps}
            />
          ) : (
            <></>
          )}
          {children ? children({ onClose, action, Actions }) : <></>}
        </Suspense>
      </Box>
    </Box>
  );
}

export default AeronetDataGrid;
