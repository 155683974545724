import { Fragment, useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  Link as MaterialLink,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

import type { NavProps } from "./types";

interface MenuDrawerProps extends NavProps {}

const MenuDrawer: React.FC<Partial<MenuDrawerProps>> = (props) => {
  const { nav } = props;
  const [open, setOpen] = useState(false);
  const [nestedState, setNestedState] = useState<{ [key: string]: any }>();

  useEffect(() => {
    if (nav) {
      const navInitialState = {};

      for (let navItem of nav) {
        navInitialState[navItem.id] = false;
      }
      setNestedState(navInitialState);
    }
  }, [nav]);

  const toggleDrawer = (open: boolean) => (event) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const handleClick = (sectionId) => (event) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setNestedState({ ...nestedState, [sectionId]: !nestedState![sectionId] });
  };

  const list = () => (
    <div
      style={{ width: 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      {nestedState && nav && nav.length ? (
        <List>
          {nav.map((navItem, index) => (
            <div key={navItem.id}>
              <ListItemButton onClick={handleClick(navItem.id)}>
                <ListItemText
                  disableTypography={true}
                  primary={
                    <Fragment>
                      <Typography sx={{ color: "white", fontWeight: "medium" }}>
                        {navItem.name}
                      </Typography>
                    </Fragment>
                  }
                />
                {nestedState[navItem.id] ? (
                  <ExpandLess sx={{ color: "white" }} />
                ) : (
                  <ExpandMore sx={{ color: "white" }} />
                )}
              </ListItemButton>
              <Collapse
                in={nestedState[navItem.id]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {navItem.children.map((menuItem, index) => (
                    <div key={index}>
                      {menuItem.to ? (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          component={Link}
                          to={menuItem.to}
                          onClick={toggleDrawer(false)}
                        >
                          <ListItemText disableTypography={true}>
                            <Typography variant="body2" sx={{ color: "white" }}>
                              {menuItem.name}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      ) : (
                        <ListItemButton
                          key={index}
                          sx={{ pl: 4 }}
                          component={MaterialLink}
                          href={menuItem.url}
                        >
                          <ListItemText disableTypography={true}>
                            <Typography variant="body2" sx={{ color: "white" }}>
                              {menuItem.name}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      )}
                    </div>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <Box display={{ md: "none" }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { backgroundColor: grey[400] },
        }}
        ModalProps={{
          BackdropProps: { invisible: true },
        }}
      >
        {list()}
      </Drawer>
    </Box>
  );
};

export default MenuDrawer;
